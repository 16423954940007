/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import {
  Button,
  Col,
  Container,
  FloatingLabel,
  Form,
  Modal,
  OverlayTrigger,
  Row,
  Tab,
  Table,
  Tabs,
  Tooltip,
} from "react-bootstrap";
import { PatternFormat } from "react-number-format";
import { useSelector } from "react-redux";
// import { ReactSearchAutocomplete } from "react-search-autocomplete";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { toast } from "react-toastify";
import SquarePlus from "../Asset/Images/Icons/square-plus.svg";
import PageLoader from "../Component/Loader/PageLoader";
import {
  CommonService,
  GenerateGUID,
  ToasterError,
  ToasterSuccess,
  fnCheckValidationOfObject,
  handeFloatValueToFixed,
} from "../Services/CommonServices";
import { getProjectTermByCategory } from "../Services/Setup&Config/AdditionalSettingService";
import {
  CheckPropertiesLimit,
  CompSetHotelGetList,
  CompSetHotelsDelete,
  EditPropertiesByID,
  GetAssociationRooms,
  GetAvEquipmentsAndMISCList,
  GetFoodAndBeveragesList,
  InsertORUpdateProperties,
} from "../Services/Setup&Config/HotelService";
import { getUserPortfolioList } from "../Services/Setup&Config/UserSetupService";
import RoomTypeTaxDetailModal from "./RoomTypeTaxDetailModal";
import TaxInformationModal from "./TaxInformationModal";
import { useNavigate } from "react-router-dom";
import TableView from "../Component/Table/Index";
import AddEditCompetitorModal from "./AddEditCompetitorModal";
import Swal from "sweetalert2";
import AddPortFolio from "./AddPortFolio";
import { getEmailAssignToDropdownList } from "../Services/LeftMenuServices";

const AddHotelsModal = (props) => {
  const [tags, setTags] = useState([]);
  const handleChange = (value) => {
    setTags(value);
  };

  let navigate = useNavigate();

  const [ShowPortfolioModel, setShowPortfolioModel] = React.useState(false);

  const [ProjectTermData, setProjectTermData]: any = React.useState([]);
  const dataSelector: any = useSelector((state: any) => state.session);
  const clientID: any = dataSelector.auth_UserDetail.C_ClientID;
  const Token = dataSelector.access_Token;
  const [SourceDropdownList, setSourceDropdownList] = useState([]);
  const [ValidMeetingRoom, setValidMeetingRoom]: any = useState(false);
  const [ValidTaxRoomTab, setValidTaxRoomTab]: any = useState(false);
  const [ValidRoomTypes, setValidRoomTypes]: any = useState(true);
  const [RoomTypeDropdownList, setRoomTypeDropdownList] = useState([]);
  const [AssociationDropdown, setAssociationDropdown]: any = useState([]);
  const [AddPropertyActiveKey, setAddPropertyActiveKey] = useState(1);
  let [TaxRowNo, setTaxRowNo]: any = React.useState(1);
  let [TaxRoomTypeRowNo, setTaxRoomTypeRowNo]: any = React.useState(1);
  let [RoomNamePass, setRoomNamePass]: any = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [hotelChangeLoading, sethotelChangeLoading] = React.useState(false); // Hotel Selection Loader State.

  const [StoreEditRoomMeetingTaxdata, setStoreEditRoomMeetingTaxdata] = useState([]);
  const [StoreEditRoomTypeTaxdata, setStoreEditRoomTypeTaxdata] = useState([]);
  const [ChainItems, setChainItems] = useState([]);
  const [PortfolioDropdown, setPortfolioDropdown]: any = React.useState({});
  let [PropertySave, setPropertySave]: any = useState({
    //HOTEL TAB START
    HChainFullName: "",
    HChain: "",
    HPropertyName: "",
    HTARGETBAR: 0,
    HSecondaryName: "",
    HTotalRoom: "",
    HPropertyCode: "",
    HLeadCatcher: "00000000-0000-0000-0000-000000000000",
    HPropertyID: "00000000-0000-0000-0000-000000000000",
    HHotelCode: "",
    HPortFolio: "",
    HPortFolioName: "",
    HClientID: "00000000-0000-0000-0000-000000000000",
    HTARGETLNR: "",
    HDUNSNumber: "",
    HFEMANumber: "",
    HWebsiteLogIn: "",
    HLogin: "",
    HLoginPass: "",
    HCVENTLogin: "",
    HCVENTPassword: "",
    HBrandWebsite: "",
    HBrandLogin: "",
    HBrandPassword: "",
    HPlanLogin: "",
    HPlanPasword: "",
    HStatus: "Active",
    HAssignUser: false,
    HLogo: "",
    HRFPSystem: "",
    HpaymentInfoID: "00000000-0000-0000-0000-000000000000",
    //ORGANIZATION TAB START

    OOWName: "",
    OOWCountryCode: "",
    OOWPhone: "",
    OOWEmail: "",
    OOWExt: "",

    OGMName: "",
    OGMPhone: "",
    OGMCountryCode: "",
    OGMEmail: "",
    OGMExt: "",

    ODSName: "",
    ODSPhone: "",
    ODSCountryCode: "",
    ODSEmail: "",
    ODSExt: "",

    OCMName: "",
    OCMPhone: "",
    OCMCountryCode: "",
    OCMEmail: "",
    OCMExt: "",

    OSMName: "",
    OSMPhone: "",
    OSMCountryCode: "",
    OSMEmail: "",
    OSMExt: "",

    ORMName: "",
    ORMPhone: "",
    ORMCountryCode: "",
    ORMEmail: "",
    ORMExt: "",

    OREName: "",
    OREEmail: "",
    ORECountryCode: "",
    OREPhone: "",
    OREExt: "",

    ORSName: "",
    ORSEmail: "",
    ORSCountryCode: "",
    ORSPhone: "",
    ORSExt: "",

    AStreet: "",
    AState: "",
    ACity: "",
    ACountry: "",
    ACountryCode: "",
    AExt: "",
    AZipCode: "",
    APhoneno: "",
    AReportEmail: "",
    MServiceCharge: "",
    MGratuity: "",
    MTaxRate: "",
    IsTaxChange: false,

    EBR_Tier1: 0,
    EBR_Tier2: 0,
    EBR_Tier3: 0,
    EBR_Tier4: 0,

    errors: {
      HChain: "",
      HPropertyName: "",
      HTotalRoom: "",
      HPropertyCode: "",
      HPortFolio: "",
      // HTARGETBAR: "",
      HTARGETLNR: "",

      OOWName: "",
      OOWPhone: "",
      OOWEmail: "",

      OGMName: "",
      OGMPhone: "",
      OGMEmail: "",

      ODSName: "",
      ODSPhone: "",
      ODSEmail: "",

      OCMName: "",
      OCMPhone: "",
      OCMEmail: "",

      OSMName: "",
      OSMPhone: "",
      OSMEmail: "",

      ORMName: "",
      ORMPhone: "",
      ORMEmail: "",

      OREName: "",
      OREEmail: "",
      OREPhone: "",

      ORSName: "",
      ORSEmail: "",
      ORSPhone: "",

      // Fourth Tab :
      MServiceCharge: "",
      MGratuity: "",
      MTaxRate: "",
      ValidationRules: [
        //#region // Tab One Start :
        {
          FieldName: "HChain",
          ValidationType: "required",
          ValidationMessage: "This field is required.",
        },
        {
          FieldName: "HPropertyName",
          ValidationType: "required",
          ValidationMessage: "This field is required.",
        },
        {
          FieldName: "HTotalRoom",
          ValidationType: "required",
          ValidationMessage: "Please enter a valid number.",
        },
        {
          FieldName: "HTotalRoom",
          ValidationType: "minmax",
          ValidationMessage: "Enter Valid Between {0} And {1}",
          Min: 0,
          Max: 999,
        },
        {
          FieldName: "HPropertyCode",
          ValidationType: "required",
          ValidationMessage: "This field is required.",
        },
        {
          FieldName: "HPortFolio",
          ValidationType: "required",
          ValidationMessage: "This field is required.",
        },
        // {
        //   FieldName: "HTARGETBAR",
        //   ValidationType: "range",
        //   ValidationMessage: "Enter Valid Between {0} And {15} character",
        // },
        {
          FieldName: "HTARGETLNR",
          ValidationType: "range",
          ValidationMessage: "Enter Valid Between {0} And {15} character",
        },
        //#endregion // Tab One End :

        //#region // Tab Two Start :
        // Owner :
        {
          FieldName: "OOWPhone",
          ValidationType: "mobile-mask",
          ValidationMessage: "Enter Valid Contact No.",
        },
        {
          FieldName: "OOWEmail",
          ValidationType: "email",
          ValidationMessage: "Please enter a valid email address.",
        },
        // GM :
        {
          FieldName: "OGMPhone",
          ValidationType: "mobile-mask",
          ValidationMessage: "Enter Valid Contact No.",
        },
        {
          FieldName: "OGMEmail",
          ValidationType: "email",
          ValidationMessage: "Please enter a valid email address.",
        },
        // Director of Sales
        {
          FieldName: "ODSPhone",
          ValidationType: "mobile-mask",
          ValidationMessage: "Enter Valid Contact No.",
        },
        {
          FieldName: "ODSEmail",
          ValidationType: "email",
          ValidationMessage: "Please enter a valid email address.",
        },
        // Catering Manager
        {
          FieldName: "OCMPhone",
          ValidationType: "mobile-mask",
          ValidationMessage: "Enter Valid Contact No.",
        },
        {
          FieldName: "OCMEmail",
          ValidationType: "email",
          ValidationMessage: "Please enter a valid email address.",
        },
        // Sales Manager
        {
          FieldName: "OSMPhone",
          ValidationType: "mobile-mask",
          ValidationMessage: "Enter Valid Contact No.",
        },
        {
          FieldName: "OSMEmail",
          ValidationType: "email",
          ValidationMessage: "Please enter a valid email address.",
        },
        // Revenue Manager
        {
          FieldName: "ORMPhone",
          ValidationType: "mobile-mask",
          ValidationMessage: "Enter Valid Contact No.",
        },
        {
          FieldName: "ORMEmail",
          ValidationType: "email",
          ValidationMessage: "Please enter a valid email address.",
        },
        // Regional
        {
          FieldName: "OREPhone",
          ValidationType: "mobile-mask",
          ValidationMessage: "Enter Valid Contact No.",
        },
        {
          FieldName: "OREEmail",
          ValidationType: "email",
          ValidationMessage: "Please enter a valid email address.",
        },
        // Regional Sales
        {
          FieldName: "ORSPhone",
          ValidationType: "mobile-mask",
          ValidationMessage: "Enter Valid Contact No.",
        },
        {
          FieldName: "ORSEmail",
          ValidationType: "email",
          ValidationMessage: "Please enter a valid email address.",
        },
        //#endregion   // Tab Two End :

        //#region  // Tab Four Start :
        {
          FieldName: "MServiceCharge",
          ValidationType: "number",
          ValidationMessage: "Please enter a valid number.",
        },

        {
          FieldName: "MGratuity",
          ValidationType: "number",
          ValidationMessage: "Please enter a valid number.",
        },

        {
          FieldName: "MTaxRate",
          ValidationType: "number",
          ValidationMessage: "Please enter a valid number.",
        },

        //#endregion // Tab Four End :
      ],
    },
  });
  const [OnProcessHide, setOnProcessHide] = React.useState(false);

  const [MeetingRoomTable, setMeetingRoomTable]: any = useState([
    {
      Col0: "Meeting Room Name",
      Col1: "Association Room",
      Col2: "Square Feet",
      Col3: "Tax",
      Col4: "",
    },
  ]);

  const userTypeId: any = dataSelector.auth_UserDetail.U_UserTypeID;
  const [modalShowTaxInformation, setModalShowTaxInformation] = React.useState(false);
  const [modalShowRoomTypeTaxInformation, setmodalShowRoomTypeTaxInformation] =
    React.useState(false);

  const [inputList, setInputList]: any = useState([]);
  const [InputTaxList, setInputTaxList]: any = useState({ lstRoomTypeTaxes: [] });

  // let RoomTypeTaxList: any = [];
  let itemsEditNewValue: any = [];
  let StateDropdown: any = [];
  ProjectTermData !== undefined &&
    ProjectTermData !== null &&
    ProjectTermData.length > 0 &&
    ProjectTermData.map((item: any) => {
      if (item.Category === "State") {
        StateDropdown.push({
          value: item.Term,
          label: item.TermCode + " - " + item.Term,
        });
      }
    });
  let optionsAssociation: any = [];
  let UpdatedoptionsAssociation: any = [];
  AssociationDropdown !== undefined &&
    AssociationDropdown !== null &&
    AssociationDropdown.length > 0 &&
    AssociationDropdown.map((item: any) => {
      optionsAssociation.push({
        value: item.RoomName,
        label: item.RoomName,
      });
    });
  let CountryDropdown: any = [];
  ProjectTermData !== undefined &&
    ProjectTermData !== null &&
    ProjectTermData.length > 0 &&
    ProjectTermData.map((item: any) => {
      if (item.Category === "Country") {
        CountryDropdown.push({
          value: item.Term,
          label: item.TermCode + " - " + item.Term,
        });
      }
    });
  let itemsNew: any = [];
  ProjectTermData !== undefined &&
    ProjectTermData !== null &&
    ProjectTermData.length > 0 &&
    ProjectTermData.map((item: any) => {
      if (item.Category === "RoomSetup") {
        itemsNew.push({
          label: item.Term,
          value: item.TermCode,
        });
      }
    });

  let aveTypesList: any = [];
  ProjectTermData !== undefined &&
    ProjectTermData !== null &&
    ProjectTermData.length > 0 &&
    ProjectTermData.map((item: any) => {
      if (item.Category === "AVEType") {
        aveTypesList.push({
          label: item.TermCode,
          value: item.TermID,
        });
      }
    });

  let RoomTypesDropdown: any = [];
  RoomTypeDropdownList !== undefined &&
    RoomTypeDropdownList !== null &&
    RoomTypeDropdownList.length > 0 &&
    RoomTypeDropdownList.map((item: any) => {
      RoomTypesDropdown.push({
        value: item.Term,
        label: item.Term,
      });
    });

  //#region Room Type Row Add Start
  const handleInputRoomChange = (event: any, index) => {
    if (event !== null) {
      const list = [...inputList];
      list[index]["quantity"] = parseInt(event.target.value);
      setInputList(list);
    }
  };
  const handleInputRoomCreatable = (event: any, index) => {
    if (event !== null) {
      const list = [...inputList];

      list[index].roomType = event;
      setInputList(list);
    } else {
      const list = [...inputList];

      list[index].roomType = event;
      setInputList(list);
    }
  };
  const handleInputRoomSelect = (event: any, index) => {
    if (event !== null) {
      const list = [...inputList];

      list[index].roomType = event.value;
      setInputList(list);
    } else {
      const list = [...inputList];

      list[index].roomType = event;
      setInputList(list);
    }
  };

  const handleAddROOMRowClick = () => {
    setInputList([
      ...inputList,
      {
        roomType: "",
        quantity: 0,
        hotelID: "00000000-0000-0000-0000-000000000000",
        orderNo: 0,
        lstRoomTypeTaxes: [],
      },
    ]);
  };
  //#endregion
  //#region MeetingRoom Type Column Add Start

  //#endregion

  let validationJson = {
    TaxTitle: "",
    RateOrPercentage: "",
  };
  const [inputTaxHotelListEr, setInputTaxHotelListEr]: any = useState([validationJson]);

  const [inputTaxHotelList, setInputTaxHotelList]: any = useState([]);
  const [inputMeetingRoomTaxList111, setInputMeetingRoomTaxList111]: any = useState([]);
  const [inputGuestRoomTaxList, setInputGuestRoomTaxList]: any = useState([]);
  const [inputAVEquipmentTaxList, setInputAVEquipmentTaxList]: any = useState([]);
  const [inputFoodTaxList, setInputFoodTaxList]: any = useState([]);
  const [inputBeverageTaxList, setInputBeverageTaxList]: any = useState([]);

  const [LogoUpload, setLogoUpload]: any = React.useState(null);
  const [error, setError]: any = useState(null);
  const [Logo, setLogo]: any = React.useState({
    name: "",
    link: [],
    errors: "",
  });

  // Image Upload Validations :

  const validateImageDimensions = (file: any) => {
    const img = new Image();
    const objectUrl: any = URL.createObjectURL(file);
    img.onload = function () {
      const width: any = img.width;
      const height: any = img.height;

      // Check the width and height
      if (width >= 200 && width <= 250 && height >= 60 && height <= 70) {
        setError(null);
        setLogo({ ...Logo, ["link"]: file });
        setLogoUpload(objectUrl); // Set the image if valid
      } else {
        setError("Logo dimensions should be between 200px-250px in width and 60px-70px in height.");
        ToasterError(
          "Logo dimensions should be between 200px-250px in width and 60px-70px in height."
        );
        setLogo({ ...Logo, ["link"]: [] });
        setLogoUpload(null); // Clear the image if invalid
      }

      // Release memory once done
      URL.revokeObjectURL(objectUrl);
    };

    img.src = objectUrl;
  };

  // Additional things added in Resiser function
  // const handleImageUpload = (selectedFile: any) => {
  //   const file: any = selectedFile;
  //   const reader: any = new FileReader();

  //   reader.onload = (e: any) => {
  //     const img: any = new Image();
  //     img.src = e.target.result;

  //     img.onload = () => {
  //       const canvas: any = document.createElement("canvas");
  //       const ctx: any = canvas.getContext("2d");

  //       const targetWidth = 250;
  //       const targetHeight = 70;

  //       // Calculate the aspect ratio of the image
  //       const aspectRatio = img.width / img.height;

  //       // Set canvas size to target dimensions
  //       canvas.width = targetWidth;
  //       canvas.height = targetHeight;

  //       let newWidth = targetWidth;
  //       let newHeight = targetHeight;

  //       // If the image is smaller than the target size, calculate its new dimensions
  //       if (img.width < targetWidth && img.height < targetHeight) {
  //         if (aspectRatio > 1) {
  //           // Landscape image
  //           newWidth = targetWidth;
  //           newHeight = targetWidth / aspectRatio;
  //         } else {
  //           // Portrait or square image
  //           newHeight = targetHeight;
  //           newWidth = targetHeight * aspectRatio;
  //         }
  //       }

  //       // Calculate the position to center the image
  //       const offsetX = (targetWidth - newWidth) / 2;
  //       const offsetY = (targetHeight - newHeight) / 2;

  //       // Fill the canvas with a background color (optional, you can set the color)
  //       ctx.fillStyle = "white"; // Set the background color
  //       ctx.fillRect(0, 0, targetWidth, targetHeight);

  //       // Draw the image onto the canvas
  //       ctx.drawImage(img, offsetX, offsetY, newWidth, newHeight);

  //       // Convert the canvas to a blob and handle file resizing
  //       canvas.toBlob((blob: Blob) => {
  //         const resizedFile = new File([blob], file.name, { type: file.type });
  //         setLogo({ ...Logo, ["link"]: resizedFile });
  //         const dataUrl = canvas.toDataURL("image/jpeg");
  //         setLogoUpload(dataUrl);
  //       }, file.type);
  //     };
  //   };

  //   reader.readAsDataURL(file);
  // };

  const handleImageUpload = (selectedFile: any) => {
    const file: any = selectedFile;
    const reader: any = new FileReader();

    reader.onload = (e: any) => {
      const img: any = new Image();
      img.src = e.target.result;

      img.onload = () => {
        const canvas: any = document.createElement("canvas");
        const ctx: any = canvas.getContext("2d");

        const targetWidth = 250;
        const targetHeight = 70;

        // Calculate the aspect ratio of the image
        const aspectRatio = img.width / img.height;

        let newWidth, newHeight;

        // Scale the image to fit within the target width and height, maintaining aspect ratio
        if (img.width > img.height) {
          // Landscape or square
          newWidth = targetWidth;
          newHeight = targetWidth / aspectRatio;
          if (newHeight > targetHeight) {
            newHeight = targetHeight;
            newWidth = targetHeight * aspectRatio;
          }
        } else {
          // Portrait
          newHeight = targetHeight;
          newWidth = targetHeight * aspectRatio;
          if (newWidth > targetWidth) {
            newWidth = targetWidth;
            newHeight = targetWidth / aspectRatio;
          }
        }

        // Set canvas size to target dimensions
        canvas.width = targetWidth;
        canvas.height = targetHeight;

        // Calculate the position to center the image on the canvas
        const offsetX = (targetWidth - newWidth) / 2;
        const offsetY = (targetHeight - newHeight) / 2;

        // Fill the canvas with a background color (optional, can be set to transparent)
        ctx.fillStyle = "white"; // Set the background color
        ctx.fillRect(0, 0, targetWidth, targetHeight);

        // Draw the resized image onto the canvas
        ctx.drawImage(img, offsetX, offsetY, newWidth, newHeight);

        // Convert the canvas to a blob and handle file resizing
        canvas.toBlob(
          (blob: Blob) => {
            const resizedFile = new File([blob], file.name, { type: file.type });
            setLogo({ ...Logo, ["link"]: resizedFile });
            const dataUrl = canvas.toDataURL("image/jpeg");
            setLogoUpload(dataUrl); // Update the state with the resized image URL
          },
          file.type,
          1 // Quality of the image (0 to 1), 1 being highest quality
        );
      };
    };

    reader.readAsDataURL(file);
  }; // Resize image by aspected ration wise.

  function SelectLogo(e: any) {
    if (e?.target?.files[0]) {
      if (e?.target?.files[0]?.name?.match(/\.(jpg|jpeg|png|webp|jfif)$/)) {
        // Previous Code
        // setLogo({ ...Logo, ["link"]: e.target.files[0] });
        // const fileLoaded: any = URL.createObjectURL(e.target.files[0]);
        // setLogoUpload(fileLoaded);

        const file = e.target.files[0];
        if (file) {
          // Image Resize Direcctly to 250*70 dimention : Function -------------------> :
          handleImageUpload(file);

          // Image Validation Function -------------------> :
          // validateImageDimensions(file);
        }
      } else {
        ToasterError("Please select .jpg|.jpeg|.png|.webp|.jfif");
      }
    }
  }

  // let BBB: any = "BW - Best Western International";
  let optionsPortFolio: any = [
    {
      value: null,
      label: "Select Portfolio",
    },
  ];

  let optionsRFP: any = [
    {
      value: null,
      label: "Select RFP List",
    },
  ];
  SourceDropdownList.map((item: any) => {
    optionsRFP.push({
      label: item.Term,
      value: item.Term,
    });
  });

  const fetchData = async () => {
    // setLoading(true);
    sethotelChangeLoading(true);
    let resChainData = await getStatusAndPriorityByProjectTerm();
    getRoomSetupListDropdown(resChainData);
  };

  React.useEffect(() => {
    fetchData();
    getPortFolioDropdown();
  }, []);

  React.useEffect(() => {
    let x: any = "";
    for (let index = 1; index < MeetingRoomTable.length; index++) {
      x = document.getElementById("MeetingRoom_" + index + "_Col0")?.value;

      if (x !== "" && x !== undefined && x !== null) {
        if (optionsAssociation.find((i) => i.value === x) === undefined) {
          optionsAssociation.push({
            value: x,
            label: x,
          });
        }
      } else {
        optionsAssociation.splice(index, 1);
      }
      // }
    }
  }, [UpdatedoptionsAssociation]);

  const [leadCatcherOptionList, setLeadCatcherOptionList]: any = useState([]);

  async function getLeadCatchers(selectedHotelId: any) {
    let inputs = {
      clientID: clientID,
      propertyIDs: selectedHotelId,
    };

    const res: any = await getEmailAssignToDropdownList(inputs, Token);
    if (res.data.success) {
      if (res.data.data !== null && res.data.data !== undefined) {
        let Array_LeadCatcherList: any = [];
        res.data.data.dataList1 !== undefined &&
          res.data.data.dataList1 !== null &&
          res.data.data.dataList1.length > 0 &&
          res.data.data.dataList1.map((item: any) => {
            Array_LeadCatcherList.push({
              label: item.UserName,
              value: item.UserID,
            });
          });

        setLeadCatcherOptionList(Array_LeadCatcherList);
      }
    }
    sethotelChangeLoading(false);
  }

  async function AddTime() {
    let inputs = {
      category: "RoomSetup,State,Country,AVEType",
      clientID: clientID,
    };

    const resDropdown: any = await getProjectTermByCategory(inputs, Token);
    if (resDropdown.data.success) {
      if (resDropdown.data !== null && resDropdown.data !== undefined) {
        setProjectTermData(resDropdown.data.data.dataList1);
      }
    }
  }
  function RemoveRoomTypesRow(Id: any) {
    const list = [...inputList];

    if (Id !== null && Id !== "" && Id !== undefined) {
      list.splice(Id, 1);
    }
    if (list.length === 0) {
      setValidRoomTypes(true);
    }

    setInputList(list);
  }

  PortfolioDropdown !== undefined &&
    PortfolioDropdown !== null &&
    PortfolioDropdown.length > 0 &&
    PortfolioDropdown.map((item: any) => {
      if (item.portfolioName !== "All") {
        optionsPortFolio.push({
          value: item.PortfolioID,
          label: item.PortfolioName,
        });
      }
    });
  async function getPortFolioDropdown() {
    let input = {
      portfolioName: null,
      clientID: clientID,
    };
    let res: any = await getUserPortfolioList(input, Token);
    if (res.data.success) {
      if (res.data !== null && res.data !== undefined) {
        setPortfolioDropdown(res.data.data.dataList1);
      }
    }
  }
  async function getRoomSetupListDropdown(resChainData = []) {
    if (
      props.EditPropertiesID !== undefined &&
      props.EditPropertiesID !== null &&
      props.EditPropertiesID !== ""
    ) {
      getLeadCatchers(props.EditPropertiesID);

      // Competitor Data :
      fetchCompetitorListData();

      // Get Food, Beverage, AvEquipment Tab.
      fetchAvData(props.EditPropertiesID);
      fetchFoodData(props.EditPropertiesID);
      fetchFoodBeverage(props.EditPropertiesID);
      // Food, Beverage, AvEquipment Tab.

      EditProperties(props.EditPropertiesID, resChainData);
      getEditAssociationRoomsDropdown(props.EditPropertiesID);
    } else {
      AddTime();
      // setLoading(false);
      sethotelChangeLoading(false);
    }
  }

  function getData(id, Name: any) {
    if (Name === "") {
      let MYName = MeetingRoomTable[id]["Col0"];
      setRoomNamePass(MYName);
    } else {
      setRoomNamePass(Name);
    }

    let Data = [];

    let val = MeetingRoomTable[id]["Col3"];

    if (val !== null && val !== "") {
      Data = val;
    }

    setTaxRowNo(id);

    setStoreEditRoomMeetingTaxdata(Data);
    setModalShowTaxInformation(true);
  }
  function storeRoomMeetingTax(RowNo: any, RowData: any) {
    let lst: any = [...MeetingRoomTable];

    lst[RowNo]["Col3"] = RowData;
    setMeetingRoomTable(lst);

    if (document.getElementById("lstRoomMeetingTaxes_" + RowNo) !== null) {
      document
        .getElementById("lstRoomMeetingTaxes_" + RowNo)
        ?.setAttribute("data-lstroommeetingtaxes", JSON.stringify(RowData));
    }
  }
  function storeRoomTypeTax(RowNo: any, RowData: any) {
    if (document.getElementById("lstRoomTypeTaxes" + RowNo) !== null) {
      document
        .getElementById("lstRoomTypeTaxes" + RowNo)
        ?.setAttribute("data-lstRoomTypeTaxes", JSON.stringify(RowData));
    }
  }
  function OpenRoomTypeTax(rowId, Name: any) {
    let Data = [];
    if (document.getElementById("lstRoomTypeTaxes" + rowId) !== null) {
      let val = document
        .getElementById("lstRoomTypeTaxes" + rowId)
        .getAttribute("data-lstRoomTypeTaxes");
      if (val !== null && val !== "") {
        Data = JSON.parse(val);
      }
    }

    setTaxRoomTypeRowNo(rowId);
    setRoomNamePass(Name);
    setStoreEditRoomTypeTaxdata(Data);
    setmodalShowRoomTypeTaxInformation(true);
  }

  function SelectedAssociationRoom(event: any, RowIndex: any, Col: any) {
    let listAssociationPattern: any = [];
    for (let index = 0; index < event.length; index++) {
      listAssociationPattern.push(event[index].value);
    }

    let lst: any = [...MeetingRoomTable];
    lst[RowIndex][Col] = listAssociationPattern.toString();
    setMeetingRoomTable(lst);
  }
  async function SaveData() {
    //#region remove all the class/id of : addValidBorder
    var elements = document.querySelectorAll(".addValidBorder");
    elements.forEach(function (element) {
      element.classList.remove("addValidBorder");
    });
    var elementById = document.getElementById("addValidBorder");
    if (elementById !== null) {
      elementById.classList.remove("addValidBorder");
    }

    setValidRoomTypes(true);
    setIsAvValid(true);
    setIsFoodValid(true);
    setIsBeverageValid(true);
    setIsEstBarRate(true);
    setValidateTaxFees(true);
    //#endregion

    let roomNameNotBlank = true;
    let roomTypeNameNotBlank = true;

    let IsMeetingRoomNamesAndAssociationRoomValid = true;

    let _MeetingRoomNames: any = [];
    for (let index = 1; index < MeetingRoomTable.length; index++) {
      _MeetingRoomNames.push(MeetingRoomTable[index]["Col0"]);
    }

    for (let index = 1; index < MeetingRoomTable.length; index++) {
      if (
        MeetingRoomTable[index]["Col1"] !== null &&
        MeetingRoomTable[index]["Col1"] !== undefined &&
        MeetingRoomTable[index]["Col1"] !== ""
      ) {
        let _AssociationRoom: any = MeetingRoomTable[index]["Col1"].split(",");
        for (let ar = 0; ar < _AssociationRoom.length; ar++) {
          if (!_MeetingRoomNames.includes(_AssociationRoom[ar])) {
            IsMeetingRoomNamesAndAssociationRoomValid = false;
          }
        }
      }
    }

    if (!IsMeetingRoomNamesAndAssociationRoomValid) {
      ToasterError(
        "Selected association rooms are not available in meeting rooms. Please select association rooms again"
      );
      return;
    }

    let obj = fnCheckValidationOfObject(PropertySave);
    setPropertySave({
      ...obj.obj,
    });

    for (let index = 1; index < MeetingRoomTable.length; index++) {
      if (MeetingRoomTable.length !== 1) {
        if (
          MeetingRoomTable[index]["Col0"] === "" ||
          MeetingRoomTable[index]["Col0"] === null ||
          MeetingRoomTable[index]["Col0"] === undefined
        ) {
          let element: any = document.getElementById("MeetingRoom_" + index + "_Col0");
          element !== null && element.classList.add("addValidBorder");
          roomNameNotBlank = false;
          ToasterError("Please Enter Room Name");
          setValidMeetingRoom(true);
          return;
        }
      } else {
        let element: any = document.getElementById("MeetingRoom_" + index + "_Col0");
        element !== null && element.classList.remove("addValidBorder");
        roomNameNotBlank = true;
        setValidMeetingRoom(false);
      }
    }
    for (let x = 0; x < inputList.length; x++) {
      if (
        inputList[x]["roomType"] === "" ||
        inputList[x]["roomType"] === null ||
        inputList[x]["roomType"] === undefined
      ) {
        let element: any = document.getElementById("RoomType_" + x);

        element !== null && element.classList.add("addValidBorder");
        roomTypeNameNotBlank = false;
        ToasterError("Please Select RoomType");
        setValidRoomTypes(false);
        return;
      } else {
        let element: any = document.getElementById("RoomType_" + x);
        element !== null && element.classList.remove("addValidBorder");
        roomTypeNameNotBlank = true;
        setValidRoomTypes(true);
      }
    }

    //  Av, Food , Beverage Tab DAta
    let tempBeverage: any = [];
    let tempFood: any = [];
    let tempAv: any = [];

    Object.keys(dataResBeverage).map((itm: any) => {
      tempBeverage.push({
        itemName: dataResBeverage[itm].itemName,
        itemType_Term: dataResBeverage[itm].itemType_Term,
        itemCostPerUnit: dataResBeverage[itm].itemCostPerUnit,
        taxRatePerc: dataResBeverage[itm].taxRatePerc,
        orderNo: dataResBeverage[itm].orderNo,
        description: dataResBeverage[itm].description,
      });
    });

    Object.keys(dataResFood).map((itm: any) => {
      tempFood.push({
        description: dataResFood[itm].description,
        itemCostPerUnit: dataResFood[itm].itemCostPerUnit,
        itemName: dataResFood[itm].itemName,
        itemType_Term: dataResFood[itm].itemType_Term,
        orderNo: dataResFood[itm].orderNo,
        taxRatePerc: dataResFood[itm].taxRatePerc,
      });
    });

    Object.keys(avDataRes).map((itm: any) => {
      tempAv.push({
        avEquipName: avDataRes[itm].avEquipName,
        avEquipType_Term: avDataRes[itm].avEquipType_Term,
        avEquipCost: avDataRes[itm].avEquipCost,
        taxRatePerc: avDataRes[itm].taxRatePerc,
        orderNO: avDataRes[itm].orderNO,
      });
    });

    let tempValidFood = validateFoodData();
    let tempValidBeverage = validateBeverageData();
    let tempValidAv = validAvEquipmentData();

    if (validateForm() && tempValidFood && tempValidBeverage && tempValidAv && obj.isValid) {
      setInputTaxHotelListEr([...inputTaxHotelListEr, { TaxTitle: "", rateOrPercentage: "" }]);
      if (roomNameNotBlank === true || roomTypeNameNotBlank === true) {
        //#region  FormData

        let formData: any = new FormData();

        inputTaxHotelList.forEach((itm, i) => {
          formData.append(`lstProperyTaxes[${i}].TaxTitle`, itm.TaxTitle);
          formData.append(`lstProperyTaxes[${i}].IsPercentage`, itm.IsPercentage);
          formData.append(`lstProperyTaxes[${i}].RateOrPercentage`, itm.RateOrPercentage);
          formData.append(`lstProperyTaxes[${i}].OrderNo`, i);
        });

        inputMeetingRoomTaxList111.forEach((itm, i) => {
          formData.append(`lstProperyRoomMeetingTaxes[${i}].TaxTitle`, itm.TaxTitle);
          formData.append(`lstProperyRoomMeetingTaxes[${i}].IsPercentage`, itm.IsPercentage);

          formData.append(
            `lstProperyRoomMeetingTaxes[${i}].RateOrPercentage`,
            itm.RateOrPercentage
          );
          formData.append(`lstProperyRoomMeetingTaxes[${i}].OrderNo`, itm.OrderNo);
          formData.append(
            `lstProperyRoomMeetingTaxes[${i}].IsApply`,
            itm.IsApply === undefined ? false : itm.IsApply
          );
        });

        inputGuestRoomTaxList.forEach((itm, i) => {
          formData.append(`lstProperyRoomTypeTaxes[${i}].TaxTitle`, itm.TaxTitle);
          formData.append(`lstProperyRoomTypeTaxes[${i}].IsPercentage`, itm.IsPercentage);
          formData.append(`lstProperyRoomTypeTaxes[${i}].RateOrPercentage`, itm.RateOrPercentage);
          formData.append(
            `lstProperyRoomTypeTaxes[${i}].IsApply`,
            itm.IsApply === undefined ? false : itm.IsApply
          );
          formData.append(`lstProperyRoomTypeTaxes[${i}].OrderNo`, itm.OrderNo);
        });

        inputAVEquipmentTaxList.forEach((itm, i) => {
          formData.append(`lstProperyAvEquipmentTaxes[${i}].TaxTitle`, itm.TaxTitle);
          formData.append(`lstProperyAvEquipmentTaxes[${i}].IsPercentage`, itm.IsPercentage);
          formData.append(
            `lstProperyAvEquipmentTaxes[${i}].RateOrPercentage`,
            itm.RateOrPercentage
          );
          formData.append(
            `lstProperyAvEquipmentTaxes[${i}].IsApply`,
            itm.IsApply === undefined ? false : itm.IsApply
          );
          formData.append(`lstProperyAvEquipmentTaxes[${i}].OrderNo`, itm.OrderNo);
        });
        inputFoodTaxList.forEach((itm, i) => {
          formData.append(`lstProperyFoodTaxes[${i}].TaxTitle`, itm.TaxTitle);
          formData.append(`lstProperyFoodTaxes[${i}].IsPercentage`, itm.IsPercentage);
          formData.append(`lstProperyFoodTaxes[${i}].RateOrPercentage`, itm.RateOrPercentage);
          formData.append(
            `lstProperyFoodTaxes[${i}].IsApply`,
            itm.IsApply === undefined ? false : itm.IsApply
          );
          formData.append(`lstProperyFoodTaxes[${i}].OrderNo`, itm.OrderNo);
        });
        inputBeverageTaxList.forEach((itm, i) => {
          formData.append(`lstProperyBeverageTaxes[${i}].TaxTitle`, itm.TaxTitle);
          formData.append(`lstProperyBeverageTaxes[${i}].IsPercentage`, itm.IsPercentage);
          formData.append(`lstProperyBeverageTaxes[${i}].RateOrPercentage`, itm.RateOrPercentage);
          formData.append(
            `lstProperyBeverageTaxes[${i}].IsApply`,
            itm.IsApply === undefined ? false : itm.IsApply
          );
          formData.append(`lstProperyBeverageTaxes[${i}].OrderNo`, itm.OrderNo);
        });

        formData.append("propertyID", PropertySave.HPropertyID);
        formData.append("propertyName", PropertySave.HPropertyName);
        formData.append("BrandName", PropertySave.HChain);
        formData.append("totalRooms", PropertySave.HTotalRoom);
        formData.append("secondaryFirstName", PropertySave.HSecondaryName);
        formData.append("propertyCode", PropertySave.HPropertyCode);
        formData.append("LeadCatcher", PropertySave.HLeadCatcher);

        formData.append(
          "hotelCode",
          document.getElementById("HotelCode_Value")?.value !== "" &&
            document.getElementById("HotelCode_Value")?.value
        );

        formData.append("portfolioID", PropertySave.HPortFolio);
        formData.append("portfolio", PropertySave.HPortFolioName);
        formData.append("propertyStatus_Term", PropertySave.HStatus);
        formData.append("rfpSystem", PropertySave.HRFPSystem);
        formData.append("hotelLoginUrl", PropertySave.HWebsiteLogIn);
        formData.append("hotelLoginUserName", PropertySave.HLogin);
        formData.append("hotelLoginPassword", PropertySave.HLoginPass);
        formData.append("brandPortalAccessUrl", PropertySave.HBrandWebsite);
        formData.append("brandPortalAccessUserName", PropertySave.HBrandLogin);
        formData.append("brandPortalAccessPassword", PropertySave.HBrandPassword);
        formData.append("dunsNumber", PropertySave.HDUNSNumber);
        formData.append("femaNumber", PropertySave.HFEMANumber);
        formData.append("cventUserName", PropertySave.HCVENTLogin);
        formData.append("cventPassword", PropertySave.HCVENTPassword);
        formData.append("hotelPlannerUserName", PropertySave.HPlanLogin);
        formData.append("hotelPlannerPassword", PropertySave.HPlanPasword);
        formData.append(
          "targetBARWeekdayRate",
          0
          // PropertySave.HTARGETBAR === undefined ? 0 : PropertySave.HTARGETBAR
        );
        formData.append(
          "targetlnrrfprate",
          PropertySave.HTARGETLNR === undefined ? 0 : PropertySave.HTARGETLNR
        );
        formData.append("AutoActivities", PropertySave.HAssignUser);
        formData.append("paymentInfoID", PropertySave.HpaymentInfoID);
        formData.append("clientID", clientID);
        formData.append("propertyLogo", "");
        formData.append("FormFiles", Logo.link); // LogoUpload,

        //tab 2
        formData.append("ownerName", PropertySave.OOWName);

        formData.append(
          "primaryContactNo",
          PropertySave.OOWCountryCode + " " + PropertySave.OOWPhone
        );
        formData.append("primaryEmailID", PropertySave.OOWEmail);
        formData.append("OwnerExt", PropertySave.OOWExt);

        formData.append("gmName", PropertySave.OGMName);
        formData.append(
          "secondaryContactNo",
          PropertySave.OGMCountryCode + " " + PropertySave.OGMPhone
        );

        formData.append("secondaryEmailID", PropertySave.OGMEmail);
        formData.append("GmExt", PropertySave.OGMExt);

        formData.append("DirectorOfSales", PropertySave.ODSName);
        formData.append(
          "DirectorContact",
          PropertySave.ODSCountryCode + " " + PropertySave.ODSPhone
        );

        formData.append("DirectorEmail", PropertySave.ODSEmail);
        formData.append("DirectorExt", PropertySave.ODSExt);

        formData.append("CateringManger", PropertySave.OCMName);
        formData.append(
          "CateringMangerContact",
          PropertySave.OCMCountryCode + " " + PropertySave.OCMPhone
        );

        formData.append("CateringMangerEmail", PropertySave.OCMEmail);
        formData.append("CateringMangerExt", PropertySave.OCMExt);

        formData.append("SalesaManager", PropertySave.OSMName);
        formData.append(
          "SalesaManagerContact",
          PropertySave.OSMCountryCode + " " + PropertySave.OSMPhone
        );

        formData.append("SalesaManagerEmail", PropertySave.OSMEmail);
        formData.append("SalesaManagerExt", PropertySave.OSMExt);

        formData.append("RevenueManager", PropertySave.ORMName);
        formData.append(
          "RevenueManagerContact",
          PropertySave.ORMCountryCode + " " + PropertySave.ORMPhone
        );

        formData.append("RevenueManagerEmail", PropertySave.ORMEmail);
        formData.append("RevenueManagerExt", PropertySave.ORMExt);

        formData.append("regionalOperations", PropertySave.OREName);
        formData.append(
          "regionalOperationsPhone",
          PropertySave.ORECountryCode + " " + PropertySave.OREPhone
        );

        formData.append("regionalOperationsEmail", PropertySave.OREEmail);
        formData.append("RegOpeExt", PropertySave.OREExt);

        formData.append("regionalSales", PropertySave.ORSName);
        formData.append(
          "regionalSalesPhone",
          PropertySave.ORSCountryCode + " " + PropertySave.ORSPhone
        );

        formData.append("regionalSalesEmail", PropertySave.ORSEmail);
        formData.append("RegSlsExt", PropertySave.ORSExt);

        //tab 3
        formData.append("propertyAddress", PropertySave.AStreet);
        formData.append("state", PropertySave.AState);
        formData.append("city", PropertySave.ACity);
        formData.append("country", PropertySave.ACountry);
        formData.append("zipCode", PropertySave.AZipCode);
        formData.append("hotelPhone", PropertySave.ACountryCode + " " + PropertySave.APhoneno);

        formData.append("hotelExt", PropertySave.AExt);
        formData.append("reportEmailTo", tags.toString());
        //tab 4
        formData.append(
          "serviceCharge",
          PropertySave.MServiceCharge === null ? 0 : PropertySave.MServiceCharge
        );
        formData.append("taxRate", PropertySave.MTaxRate === null ? 0 : PropertySave.MTaxRate);
        formData.append("gratuity", PropertySave.MGratuity === null ? 0 : PropertySave.MGratuity);
        let indexPosition = 0;
        MeetingRoomTable.forEach((itm, i) => {
          if (i > 0) {
            Object.keys(itm).map((col, ii) => {
              if (col !== "Col0" && col !== "Col1" && col !== "Col2" && col !== "Col3") {
                formData.append(`lstPropertiesRoomMeeting[${indexPosition}].roomName`, itm.Col0);
                formData.append(`lstPropertiesRoomMeeting[${indexPosition}].squareFeet`, itm.Col2);
                formData.append(
                  `lstPropertiesRoomMeeting[${indexPosition}].orderNo`,
                  indexPosition + 1
                );
                formData.append(`lstPropertiesRoomMeeting[${indexPosition}].rowNo`, i);
                formData.append(`lstPropertiesRoomMeeting[${indexPosition}].columnNo`, ii);
                formData.append(
                  `lstPropertiesRoomMeeting[${indexPosition}].roomSetup`,
                  itm[col] ? itm[col] : 0
                );
                formData.append(
                  `lstPropertiesRoomMeeting[${indexPosition}].roomSetupTitle`,
                  MeetingRoomTable[0][col]
                );
                formData.append(
                  `lstPropertiesRoomMeeting[${indexPosition}].parentmeetingroom`,
                  itm.Col1
                );

                itm.Col3 &&
                  itm.Col3.forEach((subitem, ii) => {
                    formData.append(
                      `lstPropertiesRoomMeeting[${indexPosition}].lstRoomMeetingTaxes[${ii}].taxTitle`,
                      subitem.taxTitle === undefined ? 0 : subitem.taxTitle
                    );
                    formData.append(
                      `lstPropertiesRoomMeeting[${indexPosition}].lstRoomMeetingTaxes[${ii}].isPercentage`,
                      subitem.isPercentage === undefined ? false : subitem.isPercentage
                    );
                    formData.append(
                      `lstPropertiesRoomMeeting[${indexPosition}].lstRoomMeetingTaxes[${ii}].rateOrPercentage`,
                      subitem.rateOrPercentage === undefined
                        ? 0
                        : subitem.rateOrPercentage === ""
                        ? "0"
                        : subitem.rateOrPercentage
                    );
                    formData.append(
                      `lstPropertiesRoomMeeting[${indexPosition}].lstRoomMeetingTaxes[${ii}].orderNo`,
                      subitem.orderNo === undefined ? 0 : subitem.orderNo
                    );
                  });
                indexPosition++;
              }
            });
          }
        });

        let xyz = 0;
        inputList.forEach((itma, i) => {
          formData.append(`lstPropertiesRoomTypes[${i}].roomType`, itma.roomType);
          formData.append(
            `lstPropertiesRoomTypes[${i}].quantity`,
            itma.quantity === undefined ? 0 : itma.quantity
          );
          formData.append(
            `lstPropertiesRoomTypes[${i}].orderNo`,
            itma.orderNo === undefined ? 0 : xyz
          );
          xyz = xyz + 1;
        });

        formData.append("region", "");
        formData.append("area", "");
        formData.append("primaryWebURL", "");
        formData.append("secondaryWebURL", "");
        formData.append("aboutProperty", "");
        formData.append("userInputGDUrl", "");
        formData.append("companySetRateGDUrl", "");
        formData.append("territeryID", "00000000-0000-0000-0000-000000000000");
        formData.append("noOfRooms", 0);
        formData.append("isOverBookedAllowed", true);
        formData.append("overBookedPercentage", 0);
        formData.append("isEnable", true);
        formData.append("isActive", true);
        formData.append("isBlock", false);
        formData.append("updateBy", null);
        formData.append("bankName", "");
        formData.append("bankAccountNo", "");
        formData.append("bankContact", "");
        formData.append("bankCity", "");
        formData.append("bankState", "");
        formData.append("bankFax", "");
        formData.append("bankZipCode", "");
        formData.append("sortCode", "");
        formData.append("primaryLastName", "");

        formData.append("primaryFirstName", "");
        formData.append("secondaryLastName", "");
        formData.append("salesLevel", "");
        formData.append("ownerExt", "");
        formData.append("gmExt", "");
        formData.append("regOpeExt", "");
        formData.append("regSlsExt", "");
        formData.append("autoActivities", true);
        formData.append("DirectorExt", "");
        formData.append("SalesaManagerExt", "");
        formData.append("CateringMangerExt", "");
        formData.append("IsTaxChange", PropertySave.IsTaxChange);

        tempAv.forEach((itma, i) => {
          formData.append(`listAvEquipmentANDMISC[${i}].avEquipName`, itma.avEquipName);
          formData.append(`listAvEquipmentANDMISC[${i}].avEquipType_Term`, itma.avEquipType_Term);
          formData.append(`listAvEquipmentANDMISC[${i}].avEquipCost`, itma.avEquipCost);
          formData.append(`listAvEquipmentANDMISC[${i}].taxRatePerc`, itma.taxRatePerc);
          formData.append(`listAvEquipmentANDMISC[${i}].orderNO`, i);
        });

        // tab Food

        tempFood.forEach((itma, i) => {
          formData.append(`listFood[${i}].description`, itma.description);
          formData.append(`listFood[${i}].itemCostPerUnit`, itma.itemCostPerUnit);
          formData.append(`listFood[${i}].itemName`, itma.itemName);
          formData.append(`listFood[${i}].itemType_Term`, itma.itemType_Term);
          formData.append(`listFood[${i}].orderNO`, itma.orderNo);
          formData.append(`listFood[${i}].taxRatePerc`, itma.taxRatePerc);
        });

        // tab Beverage

        tempBeverage.forEach((itma, i) => {
          formData.append(`listBeverages[${i}].itemName`, itma.itemName);
          formData.append(`listBeverages[${i}].itemType_Term`, itma.itemType_Term);
          formData.append(`listBeverages[${i}].itemCostPerUnit`, itma.itemCostPerUnit);
          formData.append(`listBeverages[${i}].taxRatePerc`, itma.taxRatePerc);
          formData.append(`listBeverages[${i}].orderNo`, itma.orderNo);
          formData.append(`listBeverages[${i}].description`, itma.description);
        });

        // Competitor Hotel_Tab Data :
        tempMap.forEach((itma, i) => {
          formData.append(
            `lstPropertyCompSetHotels[${i}].hotelID`,
            itma.HotelID !== "" ? itma.HotelID : "00000000-0000-0000-0000-000000000000"
          );
          formData.append(
            `lstPropertyCompSetHotels[${i}].compHotelID`,
            itma.CompHotelID !== "00000000-0000-0000-0000-000000000000"
              ? itma.CompHotelID
              : "00000000-0000-0000-0000-000000000000"
          );
          formData.append(
            `lstPropertyCompSetHotels[${i}].hotelName`,
            itma.HotelName !== null ? itma.HotelName : ""
          );
          formData.append(
            `lstPropertyCompSetHotels[${i}].hotelCode`,
            itma.HotelCode !== null ? itma.HotelCode : ""
          );
          formData.append(
            `lstPropertyCompSetHotels[${i}].brandName`,
            itma.BrandName !== null ? itma.BrandName : ""
          );
          formData.append(
            `lstPropertyCompSetHotels[${i}].address`,
            itma.Address !== null ? itma.Address : ""
          );
          formData.append(
            `lstPropertyCompSetHotels[${i}].rateShopURL`,
            itma.RateShopURL !== null ? itma.RateShopURL : ""
          );
          formData.append(
            `lstPropertyCompSetHotels[${i}].websiteURL`,
            itma.WebsiteURL !== null ? itma.WebsiteURL : ""
          );
          formData.append(
            `lstPropertyCompSetHotels[${i}].hotelLogoURL`,
            itma.HotelLogoURL !== null ? itma.HotelLogoURL : ""
          );
          formData.append(
            `lstPropertyCompSetHotels[${i}].hotelLogo`,
            itma.HotelLogo !== null ? itma.HotelLogo : ""
          );
          formData.append(
            `lstPropertyCompSetHotels[${i}].orderNo`,
            itma.OrderNo !== null ? itma.OrderNo : ""
          );
        });

        // tab Estimated Bar Rate
        let EBR_JSON: any = [
          {
            estimatedBarRate: PropertySave.EBR_Tier1 ? PropertySave.EBR_Tier1 : 0,
            tierNo: 1,
          },
          {
            estimatedBarRate: PropertySave.EBR_Tier2 ? PropertySave.EBR_Tier2 : 0,
            tierNo: 2,
          },
          {
            estimatedBarRate: PropertySave.EBR_Tier3 ? PropertySave.EBR_Tier3 : 0,
            tierNo: 3,
          },
          {
            estimatedBarRate: PropertySave.EBR_Tier4 ? PropertySave.EBR_Tier4 : 0,
            tierNo: 4,
          },
        ];
        EBR_JSON.forEach((item, i) => {
          formData.append(`lstPropertyEstimatedRate[${i}].estimatedBarRate`, item.estimatedBarRate);
          formData.append(`lstPropertyEstimatedRate[${i}].tierNo`, item.tierNo);
        });

        //#endregion
        let res: any = [];

        if (
          PropertySave.HPropertyID !== null &&
          PropertySave.HPropertyID !== undefined &&
          PropertySave.HPropertyID !== "" &&
          PropertySave.HPropertyID !== "00000000-0000-0000-0000-000000000000"
        ) {
          setOnProcessHide(true);
          res = await InsertORUpdateProperties(formData, Token);
          setOnProcessHide(false);
          if (res.data.success) {
            if (res.data !== undefined) {
              if (res.data.statusCode === 200) {
                localStorage.setItem("IsHotelUpdate", new Date().getTime().toString());
                ToasterSuccess("Properties Updated SuccessFully");
                if (props) {
                  props.getHotelsList();
                  props.onHide(false);
                  setPropertySave({
                    //HOTEL TAB START
                    HChain: "",
                    HPropertyName: "",
                    HTARGETBAR: 0,
                    HSecondaryName: "",
                    HTotalRoom: "",
                    HPropertyCode: "",
                    HPropertyID: "00000000-0000-0000-0000-000000000000",
                    HHotelCode: "",
                    HPortFolio: "",
                    HPortFolioName: "",
                    HClientID: "00000000-0000-0000-0000-000000000000",
                    HTARGETLNR: "",
                    HDUNSNumber: "",
                    HFEMANumber: "",
                    HWebsiteLogIn: "",
                    HLogin: "",
                    HLoginPass: "",
                    HCVENTLogin: "",
                    HCVENTPassword: "",
                    HBrandWebsite: "",
                    HBrandLogin: "",
                    HBrandPassword: "",
                    HPlanLogin: "",
                    HPlanPasword: "",
                    HStatus: "Active",
                    HAssignUser: false,
                    HLogo: "",
                    HRFPSystem: "",
                    HpaymentInfoID: "00000000-0000-0000-0000-000000000000",
                    //ORGANIZATION TAB START

                    OOWName: "",
                    OOWCountryCode: "",
                    OOWPhone: "",
                    OOWEmail: "",
                    OOWExt: "",

                    OGMName: "",
                    OGMPhone: "",
                    OGMCountryCode: "",
                    OGMEmail: "",
                    OGMExt: "",

                    ODSName: "",
                    ODSPhone: "",
                    ODSCountryCode: "",
                    ODSEmail: "",
                    ODSExt: "",

                    OCMName: "",
                    OCMPhone: "",
                    OCMCountryCode: "",
                    OCMEmail: "",
                    OCMExt: "",

                    OSMName: "",
                    OSMPhone: "",
                    OSMCountryCode: "",
                    OSMEmail: "",
                    OSMExt: "",

                    ORMName: "",
                    ORMPhone: "",
                    ORMCountryCode: "",
                    ORMEmail: "",
                    ORMExt: "",

                    OREName: "",
                    OREEmail: "",
                    ORECountryCode: "",
                    OREPhone: "",
                    OREExt: "",

                    ORSName: "",
                    ORSEmail: "",
                    ORSCountryCode: "",
                    ORSPhone: "",
                    ORSExt: "",

                    //Address TAB START
                    AStreet: "",
                    AState: "",
                    ACity: "",
                    ACountry: "",
                    ACountryCode: "",
                    AExt: "",
                    AZipCode: "",
                    APhoneno: "",
                    AReportEmail: "",
                    //Meeting Rooms TAB START
                    MServiceCharge: "",
                    MGratuity: "",
                    MTaxRate: "",
                    errors: {
                      //HOTEL TAB START
                      HChain: "",
                      HPropertyName: "",
                      HTotalRoom: "",
                      HPropertyCode: "",
                      HPortFolio: "",
                      OOWName: "",
                      OOWPhone: "",
                      OOWEmail: "",

                      OGMName: "",
                      OGMPhone: "",
                      OGMEmail: "",

                      ODSName: "",
                      ODSPhone: "",
                      ODSEmail: "",

                      OCMName: "",
                      OCMPhone: "",
                      OCMEmail: "",

                      OSMName: "",
                      OSMPhone: "",
                      OSMEmail: "",

                      ORMName: "",
                      ORMPhone: "",
                      ORMEmail: "",

                      OREName: "",
                      OREEmail: "",
                      OREPhone: "",

                      ORSName: "",
                      ORSEmail: "",
                      ORSPhone: "",

                      // Fourth Tab :
                      MServiceCharge: "",
                      MGratuity: "",
                      MTaxRate: "",
                      ValidationRules: [
                        //#region // Tab One Start :
                        {
                          FieldName: "HChain",
                          ValidationType: "required",
                          ValidationMessage: "This field is required.",
                        },
                        {
                          FieldName: "HPropertyName",
                          ValidationType: "required",
                          ValidationMessage: "This field is required.",
                        },
                        {
                          FieldName: "HTotalRoom",
                          ValidationType: "required",
                          ValidationMessage: "Please enter a valid number.",
                        },
                        {
                          FieldName: "HPropertyCode",
                          ValidationType: "required",
                          ValidationMessage: "This field is required.",
                        },
                        {
                          FieldName: "HPortFolio",
                          ValidationType: "required",
                          ValidationMessage: "This field is required.",
                        },
                        //#endregion // Tab One End :

                        //#region // Tab Two Start :
                        // Owner :
                        {
                          FieldName: "OOWPhone",
                          ValidationType: "mobile-mask",
                          ValidationMessage: "Enter Valid Contact No.",
                        },
                        {
                          FieldName: "OOWEmail",
                          ValidationType: "email",
                          ValidationMessage: "Please enter a valid email address.",
                        },
                        // GM :
                        {
                          FieldName: "OGMPhone",
                          ValidationType: "mobile-mask",
                          ValidationMessage: "Enter Valid Contact No.",
                        },
                        {
                          FieldName: "OGMEmail",
                          ValidationType: "email",
                          ValidationMessage: "Please enter a valid email address.",
                        },
                        // Director of Sales
                        {
                          FieldName: "ODSPhone",
                          ValidationType: "mobile-mask",
                          ValidationMessage: "Enter Valid Contact No.",
                        },
                        {
                          FieldName: "ODSEmail",
                          ValidationType: "email",
                          ValidationMessage: "Please enter a valid email address.",
                        },
                        // Catering Manager
                        {
                          FieldName: "OCMPhone",
                          ValidationType: "mobile-mask",
                          ValidationMessage: "Enter Valid Contact No.",
                        },
                        {
                          FieldName: "OCMEmail",
                          ValidationType: "email",
                          ValidationMessage: "Please enter a valid email address.",
                        },
                        // Sales Manager
                        {
                          FieldName: "OSMPhone",
                          ValidationType: "mobile-mask",
                          ValidationMessage: "Enter Valid Contact No.",
                        },
                        {
                          FieldName: "OSMEmail",
                          ValidationType: "email",
                          ValidationMessage: "Please enter a valid email address.",
                        },
                        // Revenue Manager
                        {
                          FieldName: "ORMPhone",
                          ValidationType: "mobile-mask",
                          ValidationMessage: "Enter Valid Contact No.",
                        },
                        {
                          FieldName: "ORMEmail",
                          ValidationType: "email",
                          ValidationMessage: "Please enter a valid email address.",
                        },
                        // Regional
                        {
                          FieldName: "OREPhone",
                          ValidationType: "mobile-mask",
                          ValidationMessage: "Enter Valid Contact No.",
                        },
                        {
                          FieldName: "OREEmail",
                          ValidationType: "email",
                          ValidationMessage: "Please enter a valid email address.",
                        },
                        // Regional Sales
                        {
                          FieldName: "ORSPhone",
                          ValidationType: "mobile-mask",
                          ValidationMessage: "Enter Valid Contact No.",
                        },
                        {
                          FieldName: "ORSEmail",
                          ValidationType: "email",
                          ValidationMessage: "Please enter a valid email address.",
                        },
                        //#endregion   // Tab Two End :

                        //#region  // Tab Four Start :
                        {
                          FieldName: "MServiceCharge",
                          ValidationType: "number",
                          ValidationMessage: "Please enter a valid number.",
                        },

                        {
                          FieldName: "MGratuity",
                          ValidationType: "number",
                          ValidationMessage: "Please enter a valid number.",
                        },

                        {
                          FieldName: "MTaxRate",
                          ValidationType: "number",
                          ValidationMessage: "Please enter a valid number.",
                        },

                        //#endregion // Tab Four End :
                      ],
                    },
                  });
                }
                // handleClose();
              } else if (res.data.statusCode === 208) {
                ToasterError(res.data.message);
              } else {
                ToasterError(res.data.message);
              }
            }
          } else {
            Object.keys(res?.data?.errData)?.forEach((itm: any) => {
              var e = document.getElementsByClassName(itm);
              if (e !== null && e.length) {
                e[0]?.classList?.add("addValidBorder");
              }

              if (itm.indexOf("lstPropertiesRoomTypes") >= 0) {
                setValidRoomTypes(false);
              } else if (itm.indexOf("listAvEquipmentANDMISC") >= 0) {
                setIsAvValid(false);
              } else if (itm.indexOf("listFood") >= 0) {
                setIsFoodValid(false);
              } else if (itm.indexOf("listBeverages") >= 0) {
                setIsBeverageValid(false);
              } else if (itm.indexOf("lstPropertyEstimatedRate") >= 0) {
                setIsEstBarRate(false);
              } else if (itm.indexOf("lstProperyTaxes") >= 0) {
                setValidateTaxFees(false);
              }
            });
            // ToasterError(res.data.message);
          }
        } else {
          setOnProcessHide(true);
          res = await InsertORUpdateProperties(formData, Token);
          setOnProcessHide(false);
          if (res.data.success) {
            if (res.data !== undefined) {
              if (res.data.statusCode === 200) {
                localStorage.setItem("IsHotelUpdate", new Date().getTime().toString());

                ToasterSuccess("Properties Inserted SuccessFully");
                if (props) {
                  props.getHotelsList();
                  props.onHide(false);
                  if (res.data.data) {
                    navigate(
                      `/SetupAndConfigurations/hotels/hotelDetails/${res.data.data.propertyID}`
                    );
                  }
                }
              } else {
                ToasterError(res.message);
              }
            }
          } else if (res.data.statusCode === 208) {
            ToasterError(res.data.message);
          } else {
            Object.keys(res?.data?.errData)?.forEach((itm: any) => {
              var e = document.getElementsByClassName(itm);
              if (e !== null && e.length) {
                e[0]?.classList?.add("addValidBorder");
              }

              if (itm.indexOf("lstPropertiesRoomTypes") >= 0) {
                setValidRoomTypes(false);
              } else if (itm.indexOf("listAvEquipmentANDMISC") >= 0) {
                setIsAvValid(false);
              } else if (itm.indexOf("listFood") >= 0) {
                setIsFoodValid(false);
              } else if (itm.indexOf("listBeverages") >= 0) {
                setIsBeverageValid(false);
              } else if (itm.indexOf("lstPropertyEstimatedRate") >= 0) {
                setIsEstBarRate(false);
              } else if (itm.indexOf("lstProperyTaxes") >= 0) {
                setValidateTaxFees(false);
              }
            });
            // ToasterError(res.data.message);
          }
        }
      } else {
        var result = Object.keys(obj.obj.errors).find(
          (key) => obj.obj.errors[key] !== "" || obj.obj.errors[key] === null
        );

        if (result?.startsWith("H")) {
          setAddPropertyActiveKey(1);
        } else if (result?.startsWith("O")) {
          setAddPropertyActiveKey(2);
        } else if (result?.startsWith("A")) {
          setAddPropertyActiveKey(3);
        } else if (result?.startsWith("M")) {
          setAddPropertyActiveKey(4);
        }
      }
    }
  }

  const [StatusData, setStatusData]: any = useState([]);

  async function getStatusAndPriorityByProjectTerm() {
    let input: any = {
      clientID: "00000000-0000-0000-0000-000000000000",
      category: "Hotel Status,Hotel Brand,RFP System,RoomTypes",
    };
    let res: any = await getProjectTermByCategory(input, Token);

    if (res.data.success) {
      if (res.data !== undefined && res.data !== null) {
        if (res.data.data !== undefined && res.data.data !== null) {
          let tempStatus =
            res.data.data.dataList1.length > 0 &&
            res.data.data.dataList1.filter((item) => item.Category === "Hotel Status");
          setStatusData(tempStatus);
          let tempBrand =
            res.data.data.dataList1.length > 0 &&
            res.data.data.dataList1.filter((item) => item.Category === "Hotel Brand");
          setChainItems(tempBrand);
          let tempRFP =
            res.data.data.dataList1.length > 0 &&
            res.data.data.dataList1.filter((item) => item.Category === "RFP System");
          setSourceDropdownList(tempRFP);
          let tempRoomType =
            res.data.data.dataList1.length > 0 &&
            res.data.data.dataList1.filter((item) => item.Category === "RoomTypes");
          setRoomTypeDropdownList(tempRoomType);
          return tempBrand;
        }
      }
    }
    return [];
  }
  async function getEditAssociationRoomsDropdown(item: any) {
    let iX = {
      keyID: item,
    };
    const resAssociationDropdown: any = await GetAssociationRooms(iX, Token);
    if (resAssociationDropdown.data.success) {
      if (resAssociationDropdown.data !== undefined) {
        if (
          resAssociationDropdown.data.data !== undefined &&
          resAssociationDropdown.data.data !== null
        ) {
          if (
            resAssociationDropdown.data.data.dataList1 !== undefined &&
            resAssociationDropdown.data.data.dataList1 !== null
          ) {
            setAssociationDropdown(resAssociationDropdown.data.data.dataList1);
          }
        }
      }
    }
  }
  async function EditProperties(item, resChainData = []) {
    let input = {
      keyID: item,
    };

    // setLoading(true);
    let res: any = await EditPropertiesByID(input, Token);

    if (res.data.success) {
      if (res.data !== undefined && res.data !== null) {
        if (res.data.data !== undefined && res.data.data !== null) {
          // tab Estimated Bar Rate
          let EBR_GET_JSON: any = {
            EBR_Tier1: 0,
            EBR_Tier2: 0,
            EBR_Tier3: 0,
            EBR_Tier4: 0,
          };
          if (res.data.data.propertyEstimatedRate) {
            res.data.data.propertyEstimatedRate.forEach((item, i) => {
              if (item.RateNo === 1) {
                EBR_GET_JSON.EBR_Tier1 = item.Rate;
              }
              if (item.RateNo === 2) {
                EBR_GET_JSON.EBR_Tier2 = item.Rate;
              }
              if (item.RateNo === 3) {
                EBR_GET_JSON.EBR_Tier3 = item.Rate;
              }
              if (item.RateNo === 4) {
                EBR_GET_JSON.EBR_Tier4 = item.Rate;
              }
            });
          }

          if (res.data.data.properties !== undefined && res.data.data.properties !== null) {
            setPropertySave({
              ...PropertySave,
              OOWExt:
                res.data.data.properties && res.data.data.properties[0].OwnerExt === null
                  ? ""
                  : res.data.data.properties[0].OwnerExt,
              OGMExt:
                res.data.data.properties && res.data.data.properties[0].GmExt === null
                  ? ""
                  : res.data.data.properties[0].GmExt,
              ODSExt:
                res.data.data.properties && res.data.data.properties[0].DirectorExt === null
                  ? ""
                  : res.data.data.properties[0].DirectorExt,
              OCMExt:
                res.data.data.properties && res.data.data.properties[0].CateringMangerExt === null
                  ? ""
                  : res.data.data.properties[0].CateringMangerExt,
              OSMExt:
                res.data.data.properties && res.data.data.properties[0].SalesaManagerExt === null
                  ? ""
                  : res.data.data.properties[0].SalesaManagerExt,
              ORMExt:
                res.data.data.properties && res.data.data.properties[0].RevenueManagerExt === null
                  ? ""
                  : res.data.data.properties[0].RevenueManagerExt,
              OREExt:
                res.data.data.properties && res.data.data.properties[0].RegOpeExt === null
                  ? ""
                  : res.data.data.properties[0].RegOpeExt,
              ORSExt:
                res.data.data.properties && res.data.data.properties[0].RegSlsExt === null
                  ? ""
                  : res.data.data.properties[0].RegSlsExt,

              HChain:
                res.data.data.properties && res.data.data.properties[0].BrandName === null
                  ? ""
                  : res.data.data.properties[0].BrandName,
              HPropertyName:
                res.data.data.properties && res.data.data.properties[0].PropertyName === null
                  ? ""
                  : res.data.data.properties[0].PropertyName,
              HTARGETBAR: 0,
              // res.data.data.properties &&
              // res.data.data.properties[0].TargetBARWeekdayRate === null
              //   ? 0
              //   : res.data.data.properties[0].TargetBARWeekdayRate,
              HSecondaryName:
                res.data.data.properties && res.data.data.properties[0].SecondaryFirstName === null
                  ? ""
                  : res.data.data.properties[0].SecondaryFirstName,
              HTotalRoom:
                res.data.data.properties && res.data.data.properties[0].TotalRooms === null
                  ? ""
                  : res.data.data.properties[0].TotalRooms,
              HPropertyCode:
                res.data.data.properties && res.data.data.properties[0].PropertyCode === null
                  ? ""
                  : res.data.data.properties[0].PropertyCode,
              HPropertyID:
                res.data.data.properties && res.data.data.properties[0].PropertyID === null
                  ? ""
                  : res.data.data.properties[0].PropertyID,
              HHotelCode:
                res.data.data.properties && res.data.data.properties[0].HotelCode === null
                  ? ""
                  : res.data.data.properties[0].HotelCode,
              HPortFolio:
                res.data.data.properties && res.data.data.properties[0].PortfolioID === null
                  ? ""
                  : res.data.data.properties[0].PortfolioID,
              HPortFolioName:
                res.data.data.properties && res.data.data.properties[0].Portfolio === null
                  ? ""
                  : res.data.data.properties[0].Portfolio,
              HClientID:
                res.data.data.properties && res.data.data.properties[0].ClientID === null
                  ? ""
                  : res.data.data.properties[0].ClientID,
              HTARGETLNR:
                res.data.data.properties && res.data.data.properties[0].TARGETLNRRFPRATE === null
                  ? 0
                  : res.data.data.properties[0].TARGETLNRRFPRATE,
              HDUNSNumber:
                res.data.data.properties && res.data.data.properties[0].DUNSNumber === null
                  ? ""
                  : res.data.data.properties[0].DUNSNumber,
              HFEMANumber:
                res.data.data.properties && res.data.data.properties[0].FEMANumber === null
                  ? ""
                  : res.data.data.properties[0].FEMANumber,
              HWebsiteLogIn:
                res.data.data.properties && res.data.data.properties[0].HotelLoginUrl === null
                  ? ""
                  : res.data.data.properties[0].HotelLoginUrl,
              HLogin:
                res.data.data.properties && res.data.data.properties[0].HotelLoginUserName === null
                  ? ""
                  : res.data.data.properties[0].HotelLoginUserName,
              HLoginPass:
                res.data.data.properties && res.data.data.properties[0].HotelLoginPassword === null
                  ? ""
                  : res.data.data.properties[0].HotelLoginPassword,
              HCVENTLogin:
                res.data.data.properties && res.data.data.properties[0].CVENTUserName === null
                  ? ""
                  : res.data.data.properties[0].CVENTUserName,
              HCVENTPassword:
                res.data.data.properties && res.data.data.properties[0].CVENTPassword === null
                  ? ""
                  : res.data.data.properties[0].CVENTPassword,
              HBrandWebsite:
                res.data.data.properties &&
                res.data.data.properties[0].BrandPortalAccessUrl === null
                  ? ""
                  : res.data.data.properties[0].BrandPortalAccessUrl,
              HBrandLogin:
                res.data.data.properties &&
                res.data.data.properties[0].BrandPortalAccessUserName === null
                  ? ""
                  : res.data.data.properties[0].BrandPortalAccessUserName,
              HBrandPassword:
                res.data.data.properties &&
                res.data.data.properties[0].BrandPortalAccessPassword === null
                  ? ""
                  : res.data.data.properties[0].BrandPortalAccessPassword,
              HPlanLogin:
                res.data.data.properties &&
                res.data.data.properties[0].HotelPlannerUserName === null
                  ? ""
                  : res.data.data.properties[0].HotelPlannerUserName,
              HPlanPasword:
                res.data.data.properties &&
                res.data.data.properties[0].HotelPlannerPassword === null
                  ? ""
                  : res.data.data.properties[0].HotelPlannerPassword,
              HStatus:
                res.data.data.properties && res.data.data.properties[0].PropertyStatus_Term === null
                  ? ""
                  : res.data.data.properties[0].PropertyStatus_Term,
              HAssignUser: res.data.data.properties[0].AutoActivities
                ? res.data.data.properties[0].AutoActivities
                : false,
              HRFPSystem:
                res.data.data.properties && res.data.data.properties[0].RFPSystem === null
                  ? ""
                  : res.data.data.properties[0].RFPSystem,
              HRFPSystemID:
                res.data.data.properties && res.data.data.properties[0].RFPSystem === null
                  ? ""
                  : res.data.data.properties[0].RFPSystem,
              HpaymentInfoID:
                res.data.data.properties && res.data.data.properties[0].PaymentInfoID === null
                  ? "00000000-0000-0000-0000-000000000000"
                  : res.data.data.properties[0].PaymentInfoID === null
                  ? ""
                  : res.data.data.properties[0].PaymentInfoID,
              HLeadCatcher:
                res.data.data.properties && res.data.data.properties[0].LeadCatcher === null
                  ? "00000000-0000-0000-0000-000000000000"
                  : res.data.data.properties[0].LeadCatcher,
              OOWName:
                res.data.data.properties && res.data.data.properties[0].OwnerName === null
                  ? ""
                  : res.data.data.properties[0].OwnerName,
              OOWPhone:
                res.data.data.properties[0].PrimaryContactNo &&
                res.data.data.properties[0].PrimaryContactNo.includes(" ")
                  ? res.data.data.properties[0].PrimaryContactNo.split(" ")[1]
                  : "",
              OOWCountryCode:
                res.data.data.properties[0].PrimaryContactNo &&
                res.data.data.properties[0].PrimaryContactNo.includes(" ")
                  ? res.data.data.properties[0].PrimaryContactNo.split(" ")[0]
                  : "",

              OOWEmail:
                res.data.data.properties && res.data.data.properties[0].PrimaryEmailID === null
                  ? ""
                  : res.data.data.properties[0].PrimaryEmailID,
              OGMName:
                res.data.data.properties && res.data.data.properties[0].GmName === null
                  ? ""
                  : res.data.data.properties[0].GmName,
              OGMPhone:
                res.data.data.properties[0].SecondaryContactNo &&
                res.data.data.properties[0].SecondaryContactNo.includes(" ")
                  ? res.data.data.properties[0].SecondaryContactNo.split(" ")[1]
                  : "",
              OGMCountryCode:
                res.data.data.properties[0].SecondaryContactNo &&
                res.data.data.properties[0].SecondaryContactNo.includes(" ")
                  ? res.data.data.properties[0].SecondaryContactNo.split(" ")[0]
                  : "",
              OGMEmail:
                res.data.data.properties && res.data.data.properties[0].SecondaryEmailID === null
                  ? ""
                  : res.data.data.properties[0].SecondaryEmailID,
              ODSName:
                res.data.data.properties && res.data.data.properties[0].DirectorOfSales === null
                  ? ""
                  : res.data.data.properties[0].DirectorOfSales,
              ODSPhone:
                res.data.data.properties[0].DirectorContact &&
                res.data.data.properties[0].DirectorContact.includes(" ")
                  ? res.data.data.properties[0].DirectorContact.split(" ")[1]
                  : "",
              ODSCountryCode:
                res.data.data.properties[0].DirectorContact &&
                res.data.data.properties[0].DirectorContact.includes(" ")
                  ? res.data.data.properties[0].DirectorContact.split(" ")[0]
                  : "",
              ODSEmail:
                res.data.data.properties && res.data.data.properties[0].DirectorEmail === null
                  ? ""
                  : res.data.data.properties[0].DirectorEmail,
              OCMName:
                res.data.data.properties && res.data.data.properties[0].CateringManger === null
                  ? ""
                  : res.data.data.properties[0].CateringManger,
              OCMPhone:
                res.data.data.properties[0].CateringMangerContact &&
                res.data.data.properties[0].CateringMangerContact.includes(" ")
                  ? res.data.data.properties[0].CateringMangerContact.split(" ")[1]
                  : "",
              OCMCountryCode:
                res.data.data.properties[0].CateringMangerContact &&
                res.data.data.properties[0].CateringMangerContact.includes(" ")
                  ? res.data.data.properties[0].CateringMangerContact.split(" ")[0]
                  : "",
              OCMEmail:
                res.data.data.properties && res.data.data.properties[0].CateringMangerEmail === null
                  ? ""
                  : res.data.data.properties[0].CateringMangerEmail,
              OSMName:
                res.data.data.properties && res.data.data.properties[0].SalesaManager === null
                  ? ""
                  : res.data.data.properties[0].SalesaManager,
              OSMPhone:
                res.data.data.properties[0].SalesaManagerContact &&
                res.data.data.properties[0].SalesaManagerContact.includes(" ")
                  ? res.data.data.properties[0].SalesaManagerContact.split(" ")[1]
                  : "",
              OSMCountryCode:
                res.data.data.properties[0].SalesaManagerContact &&
                res.data.data.properties[0].SalesaManagerContact.includes(" ")
                  ? res.data.data.properties[0].SalesaManagerContact.split(" ")[0]
                  : "",
              OSMEmail:
                res.data.data.properties && res.data.data.properties[0].SalesaManagerEmail === null
                  ? ""
                  : res.data.data.properties[0].SalesaManagerEmail,
              ORMName:
                res.data.data.properties && res.data.data.properties[0].RevenueManager === null
                  ? ""
                  : res.data.data.properties[0].RevenueManager,
              ORMPhone:
                res.data.data.properties[0].SalesaManagerContact &&
                res.data.data.properties[0].SalesaManagerContact.includes(" ")
                  ? res.data.data.properties[0].SalesaManagerContact.split(" ")[1]
                  : "",
              ORMCountryCode:
                res.data.data.properties[0].SalesaManagerContact &&
                res.data.data.properties[0].SalesaManagerContact.includes(" ")
                  ? res.data.data.properties[0].SalesaManagerContact.split(" ")[0]
                  : "",

              ORMEmail:
                res.data.data.properties && res.data.data.properties[0].RevenueManagerEmail === null
                  ? ""
                  : res.data.data.properties[0].RevenueManagerEmail,
              OREName:
                res.data.data.properties && res.data.data.properties[0].RegionalOperations === null
                  ? ""
                  : res.data.data.properties[0].RegionalOperations,
              OREEmail:
                res.data.data.properties &&
                res.data.data.properties[0].RegionalOperationsEmail === null
                  ? ""
                  : res.data.data.properties[0].RegionalOperationsEmail,

              OREPhone:
                res.data.data.properties[0].RegionalOperationsPhone &&
                res.data.data.properties[0].RegionalOperationsPhone.includes(" ")
                  ? res.data.data.properties[0].RegionalOperationsPhone.split(" ")[1]
                  : "",
              ORECountryCode:
                res.data.data.properties[0].RegionalOperationsPhone &&
                res.data.data.properties[0].RegionalOperationsPhone.includes(" ")
                  ? res.data.data.properties[0].RegionalOperationsPhone.split(" ")[0]
                  : "",

              ORSName:
                res.data.data.properties && res.data.data.properties[0].RegionalSales === null
                  ? ""
                  : res.data.data.properties[0].RegionalSales,
              ORSEmail:
                res.data.data.properties && res.data.data.properties[0].RegionalSalesEmail === null
                  ? ""
                  : res.data.data.properties[0].RegionalSalesEmail,

              ORSPhone:
                res.data.data.properties[0].RegionalSalesPhone &&
                res.data.data.properties[0].RegionalSalesPhone.includes(" ")
                  ? res.data.data.properties[0].RegionalSalesPhone.split(" ")[1]
                  : "",
              ORSCountryCode:
                res.data.data.properties[0].RegionalSalesPhone &&
                res.data.data.properties[0].RegionalSalesPhone.includes(" ")
                  ? res.data.data.properties[0].RegionalSalesPhone.split(" ")[0]
                  : "",

              AStreet:
                res.data.data.properties && res.data.data.properties[0].PropertyAddress === null
                  ? ""
                  : res.data.data.properties[0].PropertyAddress,
              AState:
                res.data.data.properties && res.data.data.properties[0].State === null
                  ? ""
                  : res.data.data.properties[0].State,
              ACity:
                res.data.data.properties && res.data.data.properties[0].City === null
                  ? ""
                  : res.data.data.properties[0].City,
              ACountry:
                res.data.data.properties && res.data.data.properties[0].Country === null
                  ? ""
                  : res.data.data.properties[0].Country,
              AZipCode:
                res.data.data.properties && res.data.data.properties[0].ZipCode === null
                  ? ""
                  : res.data.data.properties[0].ZipCode,
              AExt:
                res.data.data.properties && res.data.data.properties[0].HotelExt === null
                  ? ""
                  : res.data.data.properties[0].HotelExt,

              ACountryCode:
                res.data.data.properties[0].HotelPhone &&
                res.data.data.properties[0].HotelPhone.includes(" ")
                  ? res.data.data.properties[0].HotelPhone.split(" ")[0]
                  : "",
              APhoneno:
                res.data.data.properties[0].HotelPhone &&
                res.data.data.properties[0].HotelPhone.includes(" ")
                  ? res.data.data.properties[0].HotelPhone.split(" ")[1]
                  : "",

              MServiceCharge:
                res.data.data.properties && res.data.data.properties[0].ServiceCharge === null
                  ? 0
                  : res.data.data.properties[0].ServiceCharge,
              MGratuity:
                res.data.data.properties && res.data.data.properties[0].Gratuity === null
                  ? 0
                  : res.data.data.properties[0].Gratuity,
              MTaxRate:
                res.data.data.properties && res.data.data.properties[0].TaxRate === null
                  ? 0
                  : res.data.data.properties[0].TaxRate,
              EBR_Tier1: EBR_GET_JSON.EBR_Tier1,
              EBR_Tier2: EBR_GET_JSON.EBR_Tier2,
              EBR_Tier3: EBR_GET_JSON.EBR_Tier3,
              EBR_Tier4: EBR_GET_JSON.EBR_Tier4,
            });
            setLogoUpload(
              res.data.data.properties && res.data.data.properties[0].PropertyLogo === null
                ? ""
                : res.data.data.properties[0].PropertyLogo
            );
            if (res.data.data.properties[0].ReportEmailTo) {
              setTags(res.data.data.properties[0].ReportEmailTo.split(","));
            }
          }
        }

        let inputs = {
          category: "RoomSetup,State,Country,AVEType",
          clientID: clientID,
        };

        const resDropdown: any = await getProjectTermByCategory(inputs, Token);

        if (resDropdown !== undefined && resDropdown !== null) {
          if (resDropdown.data !== undefined && resDropdown.data !== null) {
            if (resDropdown.data.data !== undefined && resDropdown.data.data !== null) {
              setProjectTermData(resDropdown.data.data.dataList1);
              resDropdown.data.data.dataList1.map((item: any) => {
                if (item.Category === "RoomSetup") {
                  itemsEditNewValue.push({
                    label: item.Term,
                    value: item.TermCode,
                  });
                }
              });
              if (
                resDropdown.data.data.dataList1 !== undefined &&
                resDropdown.data.data.dataList1 !== null
              ) {
                if (res.data.data.roomMeeting !== null && res.data.data.roomMeeting.length !== 0) {
                  let RoomMeeting: any = res.data.data.roomMeeting;

                  let NoOfCol = RoomMeeting[0].length;

                  let list: any = [];
                  let NewObjHeader = {};

                  let countCols = 0;
                  let countColsNew = 4;
                  // Edit Start

                  setMeetingRoomTable([]);

                  for (let c = 0; c < NoOfCol + 4; c++) {
                    if (c === 0) {
                      NewObjHeader["Col" + c] = "Meeting Room Name";
                    } else if (c === 1) {
                      NewObjHeader["Col" + c] = "Association Room";
                    } else if (c === 2) {
                      NewObjHeader["Col" + c] = "Square Feet";
                    } else if (c === 3) {
                      NewObjHeader["Col" + c] = "Tax";
                    } else if (c >= 4) {
                      let term = RoomMeeting[0][countCols]["roomSetupTitle"];
                      if (term !== "undefined") {
                        let xyz = itemsEditNewValue.filter(
                          (obj) =>
                            RoomMeeting[0][countCols]["roomSetupTitle"] &&
                            RoomMeeting[0][countCols]["roomSetupTitle"].includes(obj.label)
                        );
                        if (xyz === undefined) {
                          itemsEditNewValue.push({ label: term, value: term });
                        }
                      }
                      NewObjHeader["Col" + c] = RoomMeeting[0][countCols]["roomSetupTitle"];
                    }

                    if (c > 3) {
                      countCols = countCols + 1;
                      countColsNew = countColsNew + 1;
                    }
                  }

                  list.push(NewObjHeader);
                  for (let r = 1; r <= RoomMeeting.length; r++) {
                    let NewObj = {};
                    for (let x = 0; x < NoOfCol + 4; x++) {
                      let HTMl = "";
                      if (x === 0) {
                        HTMl =
                          RoomMeeting &&
                          RoomMeeting[r - 1] &&
                          RoomMeeting[r - 1][x] &&
                          RoomMeeting[r - 1][x]["roomName"] &&
                          RoomMeeting[r - 1][x]["roomName"];
                      } else if (x === 1) {
                        HTMl =
                          RoomMeeting &&
                          RoomMeeting[r - 1] &&
                          RoomMeeting[r - 1][x - 1] &&
                          RoomMeeting[r - 1][x - 1]["parentmeetingroom"] &&
                          RoomMeeting[r - 1][x - 1]["parentmeetingroom"];
                      } else if (x === 2) {
                        HTMl =
                          RoomMeeting &&
                          RoomMeeting[r - 1] &&
                          RoomMeeting[r - 1][x - 2] &&
                          RoomMeeting[r - 1][x - 2]["squareFeet"] &&
                          RoomMeeting[r - 1][x - 2]["squareFeet"];
                      } else if (x === 3) {
                        HTMl = res.data.data.roomMeetingTaxes.filter((a) => a.rowNo === r);
                      } else if (x >= 4) {
                        HTMl =
                          RoomMeeting &&
                          RoomMeeting[r - 1] &&
                          RoomMeeting[r - 1][x - 4] &&
                          RoomMeeting[r - 1][x - 4]["roomSetup"] &&
                          RoomMeeting[r - 1][x - 4]["roomSetup"];
                      }
                      NewObj["Col" + x] = HTMl;
                    }
                    list.push(NewObj);
                  }

                  if (list.length !== 0) {
                    setMeetingRoomTable((oldData) => [...oldData, ...list]);
                  }
                }
              }
            }
          }
        }

        if (res.data.data.roomTypes.length > 0) {
          setInputList(res.data.data.roomTypes);
        }

        setInputTaxList(res.data.data.roomTypeTaxes);
        setInputMeetingRoomTaxList111(res.data.data.propertiesRoomMeetingTaxes);
        if (res.data.data.propertiesRoomTypeTaxes.length > 0) {
          setInputGuestRoomTaxList(res.data.data.propertiesRoomTypeTaxes);
        }
        if (res.data.data.avEquipmentTaxes.length > 0) {
          setInputAVEquipmentTaxList(res.data.data.avEquipmentTaxes);
        }
        if (res.data.data.foodTaxes.length > 0) {
          setInputFoodTaxList(res.data.data.foodTaxes);
        }
        if (res.data.data.beverageTaxes.length > 0) {
          setInputBeverageTaxList(res.data.data.beverageTaxes);
        }
        if (res.data.data.propertiesTaxes.length > 0) {
          setInputTaxHotelList(res.data.data.propertiesTaxes);
        }

        let JsonToImEr: any = [];
        let JsonObjEr: any = {};

        res.data.data.propertiesTaxes.map(() => {
          JsonObjEr = {
            ...JsonObjEr,
            TaxTitle: "",
            RateOrPercentage: "",
          };
          JsonToImEr.push(JsonObjEr);
        });
        if (JsonToImEr.length > 0) {
          setInputTaxHotelListEr(JsonToImEr);
        }

        // setLoading(false);
        sethotelChangeLoading(false);
      }
    }
  }

  function TabNextPrevious(Type) {
    if (Type.toUpperCase() === "PREVIOUS") {
      if (AddPropertyActiveKey > 1) {
        setAddPropertyActiveKey(AddPropertyActiveKey - 1);
      }
    } else if (Type.toUpperCase() === "NEXT") {
      if (AddPropertyActiveKey < 11) {
        setAddPropertyActiveKey(AddPropertyActiveKey + 1);
      }
    }
  }

  let items: any = [];
  ChainItems !== undefined &&
    ChainItems !== null &&
    ChainItems.length > 0 &&
    ChainItems.map((item: any) => {
      items.push({
        label: item.TermCode + " - " + item.Term,
        value: item.TermCode + " - " + item.Term,
      });
    });

  function handleOnSelect(event) {
    if (event !== null) {
      setPropertySave({
        ...PropertySave,
        ["HChain"]: event.value,
      });
    }
  }

  function ColumnExistORNot(obj, NextColNo) {
    if (obj.hasOwnProperty("Col" + NextColNo)) {
      NextColNo = NextColNo + 1;
      NextColNo = ColumnExistORNot(obj, NextColNo);
    }
    return NextColNo;
  }

  async function MeetingRoomTable_AddNewCol() {
    let lst = [...MeetingRoomTable];
    let NextColNo = Object.keys(lst[0]).length;
    NextColNo = ColumnExistORNot(lst[0], NextColNo);
    for (let i = 0; i < lst.length; i++) {
      if (i === 0) {
        lst[i]["Col" + NextColNo] = "";
      } else {
        lst[i]["Col" + NextColNo] = 0;
      }
    }
    setMeetingRoomTable(lst);
  }

  function MeetingRoomTable_AddNewRow() {
    let lst = [...MeetingRoomTable];
    let obj: any = {};

    Object.keys(lst[0]).map((item: any) => {
      if (item === "Col0" || item === "Col1") {
        obj[item] = "";
      } else {
        obj[item] = 0;
      }
    });
    lst.push(obj);
    setMeetingRoomTable(lst);
  }

  function MeetingRoomTable_RemoveRow(Row, RowIndex) {
    let lst = [...MeetingRoomTable];

    lst.splice(RowIndex, 1);
    if (lst.length === 1) {
      setValidMeetingRoom(false);
    }
    setMeetingRoomTable(lst);
  }
  function MeetingRoomTable_RemoveColumn(Row) {
    let lst = [...MeetingRoomTable];

    for (let index = 0; index < lst.length; index++) {
      delete lst[index][Row];
    }
    setMeetingRoomTable(lst);
  }
  function RemoveOrCheckAssociation(value: any, e: any, RowIndex, Col) {
    let lst: any = [...MeetingRoomTable];

    if (e.target.value === "") {
      e.target.classList.add("addValidBorder");
    } else {
      e.target.classList.remove("addValidBorder");
      setValidMeetingRoom(false);
    }
    lst[RowIndex][Col] = e.target.value;
    setMeetingRoomTable(lst);
  }

  async function handlePropChackLimit(item) {
    if (item.TermCode === "Active") {
      let res: any = await CheckPropertiesLimit(Token);
      if (res.data.success) {
        if (res.data !== undefined && res.data !== null) {
          if (res.data.data === true) {
            setPropertySave({
              ...PropertySave,
              ["HStatus"]: item.TermCode,
            });
          } else {
            ToasterError("Contracted Properties Limit Over");
          }
        }
      }
    } else {
      setPropertySave({
        ...PropertySave,
        ["HStatus"]: item.TermCode,
      });
    }
  }
  //#region Tax Information

  const [HotelTaxDropdownList, setHotelTaxDropdownList] = useState([]);

  const [IsvalidState, setIsvalidState] = React.useState({ status: false, Value: "" });

  const [meetingOrderNo, setNeetingOrderNo] = useState(1);
  const [guestOrderNo, setGuestOrderNo] = useState(1);
  const [avOrderNo, setAVOrderNo] = useState(1);
  const [foodOrderNo, setFoodOrderNo] = useState(1);
  const [beverageOrderNo, setBeverageOrderNo] = useState(1);

  React.useEffect(() => {
    getHotelTaxDropdown();
  }, []);

  let optionsTax: any = [
    {
      value: null,
      label: "Select Tax",
    },
  ];
  HotelTaxDropdownList &&
    HotelTaxDropdownList.map((item: any) => {
      optionsTax.push({
        label: item.Term,
        value: item.Term,
        node: "Creatable",
      });
    });
  const handleAddROOMRowClickTab = () => {
    if (
      props.EditPropertiesID !== undefined &&
      props.EditPropertiesID !== null &&
      props.EditPropertiesID !== ""
    ) {
      setPropertySave({
        ...PropertySave,
        ["IsTaxChange"]: true,
      });
    }

    let rowId = GenerateGUID();

    setInputTaxHotelList([
      ...inputTaxHotelList,
      {
        TaxTitle: "",
        RateOrPercentage: "",
        OrderNo: inputTaxHotelList.length,
        IsPercentage: true,
        taxRowNo: rowId,
      },
    ]);
    setInputMeetingRoomTaxList111([
      ...inputMeetingRoomTaxList111,
      {
        TaxTitle: "",
        RateOrPercentage: "",
        OrderNo: 0,
        IsPercentage: true,
        taxRowNo: rowId,
      },
    ]);

    setInputGuestRoomTaxList([
      ...inputGuestRoomTaxList,
      {
        TaxTitle: "",
        RateOrPercentage: "",
        OrderNo: 0,
        IsPercentage: true,
        taxRowNo: rowId,
      },
    ]);
    setInputAVEquipmentTaxList([
      ...inputAVEquipmentTaxList,
      {
        TaxTitle: "",
        RateOrPercentage: "",
        OrderNo: 0,
        IsPercentage: true,
        taxRowNo: rowId,
      },
    ]);
    setInputFoodTaxList([
      ...inputFoodTaxList,
      {
        TaxTitle: "",
        RateOrPercentage: "",
        OrderNo: 0,
        IsPercentage: true,
        taxRowNo: rowId,
      },
    ]);
    setInputBeverageTaxList([
      ...inputBeverageTaxList,
      {
        TaxTitle: "",
        RateOrPercentage: "",
        OrderNo: 0,
        IsPercentage: true,
        taxRowNo: rowId,
      },
    ]);

    setInputTaxHotelListEr([...inputTaxHotelListEr, { TaxTitle: "", RateOrPercentage: "" }]);
  };

  function handleInputRoomChangeTab(event, index, Obj) {
    const list: any = [...inputTaxHotelList];

    const list1: any = [...inputMeetingRoomTaxList111];
    const list2: any = [...inputGuestRoomTaxList];
    const list3: any = [...inputAVEquipmentTaxList];
    const list4: any = [...inputFoodTaxList];
    const list5: any = [...inputBeverageTaxList];

    for (let i = 0; i < list.length; i++) {
      if (event !== null) {
        if (list[i].TaxTitle === event.label) {
          ToasterError("Selected value already exist...!");
          return;
        }
      }
    }

    let idxOrg = list.findIndex((tax) =>
      Obj?.taxRowNo ? tax.taxRowNo === Obj?.taxRowNo : tax.TaxTitle === Obj?.TaxTitle
    );
    let idxOrg1 = list1.findIndex((tax) =>
      Obj?.taxRowNo ? tax.taxRowNo === Obj?.taxRowNo : tax.TaxTitle === Obj?.TaxTitle
    );
    let idxOrg2 = list2.findIndex((tax) =>
      Obj?.taxRowNo ? tax.taxRowNo === Obj?.taxRowNo : tax.TaxTitle === Obj?.TaxTitle
    );
    let idxOrg3 = list3.findIndex((tax) =>
      Obj?.taxRowNo ? tax.taxRowNo === Obj?.taxRowNo : tax.TaxTitle === Obj?.TaxTitle
    );
    let idxOrg4 = list4.findIndex((tax) =>
      Obj?.taxRowNo ? tax.taxRowNo === Obj?.taxRowNo : tax.TaxTitle === Obj?.TaxTitle
    );
    let idxOrg5 = list5.findIndex((tax) =>
      Obj?.taxRowNo ? tax.taxRowNo === Obj?.taxRowNo : tax.TaxTitle === Obj?.TaxTitle
    );

    if (
      props.EditPropertiesID !== undefined &&
      props.EditPropertiesID !== null &&
      props.EditPropertiesID !== ""
    ) {
      setPropertySave({
        ...PropertySave,
        ["IsTaxChange"]: true,
      });
    }

    if (event === null) {
      list[idxOrg]["TaxTitle"] = "";
      list1[idxOrg1]["TaxTitle"] = "";
      list2[idxOrg2]["TaxTitle"] = "";
      list3[idxOrg3]["TaxTitle"] = "";
      list4[idxOrg4]["TaxTitle"] = "";
      list5[idxOrg5]["TaxTitle"] = "";
    } else if (event.node === "Creatable") {
      let value = event.value.trim();
      list[idxOrg]["TaxTitle"] = value;
      list1[idxOrg1]["TaxTitle"] = value;
      list2[idxOrg2]["TaxTitle"] = value;
      list3[idxOrg3]["TaxTitle"] = value;
      list4[idxOrg4]["TaxTitle"] = value;
      list5[idxOrg5]["TaxTitle"] = value;
    } else if (event.__isNew__) {
      let value = event.value.trim();
      list[idxOrg]["TaxTitle"] = value;
      list1[idxOrg1]["TaxTitle"] = value;
      list2[idxOrg2]["TaxTitle"] = value;
      list3[idxOrg3]["TaxTitle"] = value;
      list4[idxOrg4]["TaxTitle"] = value;
      list5[idxOrg5]["TaxTitle"] = value;
    } else if (
      event.target &&
      event.target.nodeName !== undefined &&
      event.target.nodeName === "SELECT"
    ) {
      if (event.target.value === "Yes") {
        list[idxOrg]["IsPercentage"] = true;
        list1[idxOrg1]["IsPercentage"] = true;
        list2[idxOrg2]["IsPercentage"] = true;
        list3[idxOrg3]["IsPercentage"] = true;
        list4[idxOrg4]["IsPercentage"] = true;
        list5[idxOrg5]["IsPercentage"] = true;
      } else {
        list[idxOrg]["IsPercentage"] = false;
        list1[idxOrg1]["IsPercentage"] = false;
        list2[idxOrg2]["IsPercentage"] = false;
        list3[idxOrg3]["IsPercentage"] = false;
        list4[idxOrg4]["IsPercentage"] = false;
        list5[idxOrg5]["IsPercentage"] = false;
      }
    } else {
      if (event.value !== null) {
        let { name, value }: any = event.target;
        value = value.trim();
        list[idxOrg][name] = value;
        list1[idxOrg1][name] = value;
        list2[idxOrg2][name] = value;
        list3[idxOrg3][name] = value;
        list4[idxOrg4][name] = value;
        list5[idxOrg5][name] = value;
        if (value) {
          let pattern: any = /^\d+(\.)?\d*%?$/;
          if (pattern.test(value)) {
            if (value !== null && value !== "" && value !== undefined) {
              if (value.includes("%")) {
                list[idxOrg]["IsPercentage"] = true;
                list[idxOrg]["RateOrPercentage"] = value ? value : "";

                list1[idxOrg1]["IsPercentage"] = true;
                list1[idxOrg1]["RateOrPercentage"] = value ? value : "";

                list2[idxOrg2]["IsPercentage"] = true;
                list2[idxOrg2]["RateOrPercentage"] = value ? value : "";

                list3[idxOrg3]["IsPercentage"] = true;
                list3[idxOrg3]["RateOrPercentage"] = value ? value : "";

                list4[idxOrg4]["IsPercentage"] = true;
                list4[idxOrg4]["RateOrPercentage"] = value ? value : "";

                list5[idxOrg5]["IsPercentage"] = true;
                list5[idxOrg5]["RateOrPercentage"] = value ? value : "";
              }
            }
            // list[index]["taxRowNo"] = props.TaxRowNo;
            // list[index]["OrderNo"] = index;
            // list1[index]["taxRowNo"] = props.TaxRowNo;
            // list1[index]["OrderNo"] = index;
            // list2[index]["taxRowNo"] = props.TaxRowNo;
            // list2[index]["OrderNo"] = index;
            // list3[index]["taxRowNo"] = props.TaxRowNo;
            // list3[index]["OrderNo"] = index;
            // list4[index]["taxRowNo"] = props.TaxRowNo;
            // list4[index]["OrderNo"] = index;
            // list5[index]["taxRowNo"] = props.TaxRowNo;
            // list5[index]["OrderNo"] = index;
            setIsvalidState({ status: false, Value: "" });
          } else {
            setIsvalidState({ status: true, Value: value });
            list[idxOrg][name] = "";
            list1[idxOrg1][name] = "";
            list2[idxOrg2][name] = "";
            list3[idxOrg3][name] = "";
            list4[idxOrg4][name] = "";
            list5[idxOrg5][name] = "";
          }
        } else {
          list[idxOrg][name] = value;
          list1[idxOrg1][name] = value;
          list2[idxOrg2][name] = value;
          list3[idxOrg3][name] = value;
          list4[idxOrg4][name] = value;
          list5[idxOrg5][name] = value;
        }
      }
    }

    setInputTaxHotelList(list);
    setInputMeetingRoomTaxList111(list1);
    setInputGuestRoomTaxList(list2);
    setInputAVEquipmentTaxList(list3);
    setInputFoodTaxList(list4);
    setInputBeverageTaxList(list5);
  }

  function CheckKeyEvent(event) {
    if (
      (event.charCode >= 48 && event.charCode <= 57) ||
      event.charCode === 46 ||
      event.charCode === 37
    ) {
      if (event.charCode === 46) {
        if (event.target.value.indexOf(".") >= 0) {
          event.preventDefault();
        }
      }

      if (event.charCode === 37) {
        if (event.target.value.indexOf("%") >= 0) {
          event.preventDefault();
        }
      }
    } else {
      event.preventDefault();
    }
  }

  const [validateTaxFees, setValidateTaxFees] = useState(true);

  const validateForm = () => {
    let is_validate = true;
    let newInputEr: any = [...inputTaxHotelListEr];
    let errosJson: any = {};

    Object.keys(newInputEr).forEach((er_key) => {
      errosJson = {
        ...errosJson,
        [er_key]: {
          RateOrPercentage: "",
          TaxTitle: "",
        },
      };
    });
    inputTaxHotelList.forEach((itm, i) => {
      Object.keys(validationJson).forEach((key) => {
        if ((!itm[key] && parseInt(itm[key]) !== 0) || itm[key] === "") {
          is_validate = false;
          newInputEr[i][key] = "This field is required.";
          setValidTaxRoomTab(true);
        } else {
          newInputEr[i][key] = "";
          setValidTaxRoomTab(false);
        }
      });
    });
    setInputTaxHotelListEr(newInputEr);
    setValidateTaxFees(is_validate);
    return is_validate;
  };

  function RemoveTaxRow(id: any) {
    const list = [...inputTaxHotelList];
    const list1 = [...inputMeetingRoomTaxList111];
    const list2 = [...inputGuestRoomTaxList];
    const list3 = [...inputAVEquipmentTaxList];
    const list4 = [...inputFoodTaxList];
    const list5 = [...inputBeverageTaxList];

    const listER = [...inputTaxHotelListEr];

    if (
      props.EditPropertiesID !== undefined &&
      props.EditPropertiesID !== null &&
      props.EditPropertiesID !== ""
    ) {
      setPropertySave({
        ...PropertySave,
        ["IsTaxChange"]: true,
      });
    }

    if (id !== null && id !== "" && id !== undefined) {
      list.splice(id, 1);
      list1.splice(id, 1);
      list2.splice(id, 1);
      list3.splice(id, 1);
      list4.splice(id, 1);
      list5.splice(id, 1);
      listER.splice(id, 1);
    }

    setInputTaxHotelList(list);
    setInputMeetingRoomTaxList111(list1);
    setInputGuestRoomTaxList(list2);
    setInputAVEquipmentTaxList(list3);
    setInputFoodTaxList(list4);
    setInputBeverageTaxList(list5);
    setInputTaxHotelListEr(listER);
  }

  async function getHotelTaxDropdown() {
    let inputs = {
      category: "Hotel Tax",
      clientID: clientID,
    };
    const resDropdown: any = await getProjectTermByCategory(inputs, Token);
    if (resDropdown.data.success) {
      if (resDropdown.data !== null && resDropdown.data !== undefined) {
        if (resDropdown.data.data !== undefined && resDropdown.data.data !== null) {
          setHotelTaxDropdownList(resDropdown.data.data.dataList1);
        }
      }
    }
  }

  function MeetingRoomTaxCheckboxOnchange(event, item, index) {
    if (event.target.checked === true) {
      const listMeeting: any = [...inputMeetingRoomTaxList111];
      listMeeting[index].IsApply = true;
      setInputMeetingRoomTaxList111(listMeeting);
      if (
        props.EditPropertiesID !== undefined &&
        props.EditPropertiesID !== null &&
        props.EditPropertiesID !== ""
      ) {
        setPropertySave({
          ...PropertySave,
          ["IsTaxChange"]: true,
        });
      }
    } else {
      const Meetinglist: any = [...inputMeetingRoomTaxList111];
      Meetinglist[index].IsApply = false;
      setInputMeetingRoomTaxList111(Meetinglist);
      if (
        props.EditPropertiesID !== undefined &&
        props.EditPropertiesID !== null &&
        props.EditPropertiesID !== ""
      ) {
        setPropertySave({
          ...PropertySave,
          ["IsTaxChange"]: true,
        });
      }
    }
  }
  function GuestRoomTaxCheckboxOnchange(e, item, index) {
    if (e.target.checked === true) {
      const list1: any = [...inputGuestRoomTaxList];
      list1[index].IsApply = true;
      setInputGuestRoomTaxList(list1);
      if (
        props.EditPropertiesID !== undefined &&
        props.EditPropertiesID !== null &&
        props.EditPropertiesID !== ""
      ) {
        setPropertySave({
          ...PropertySave,
          ["IsTaxChange"]: true,
        });
      }
    } else {
      const list: any = [...inputGuestRoomTaxList];
      list[index].IsApply = false;
      setInputGuestRoomTaxList(list);
      if (
        props.EditPropertiesID !== undefined &&
        props.EditPropertiesID !== null &&
        props.EditPropertiesID !== ""
      ) {
        setPropertySave({
          ...PropertySave,
          ["IsTaxChange"]: true,
        });
      }
    }
  }
  function AVEquipmentTaxCheckboxOnchange(e, item, index) {
    if (e.target.checked === true) {
      const list2: any = [...inputAVEquipmentTaxList];
      list2[index].IsApply = true;
      setInputAVEquipmentTaxList(list2);
      if (
        props.EditPropertiesID !== undefined &&
        props.EditPropertiesID !== null &&
        props.EditPropertiesID !== ""
      ) {
        setPropertySave({
          ...PropertySave,
          ["IsTaxChange"]: true,
        });
      }
    } else {
      const lis21: any = [...inputAVEquipmentTaxList];
      lis21[index].IsApply = false;
      setInputAVEquipmentTaxList(lis21);
      if (
        props.EditPropertiesID !== undefined &&
        props.EditPropertiesID !== null &&
        props.EditPropertiesID !== ""
      ) {
        setPropertySave({
          ...PropertySave,
          ["IsTaxChange"]: true,
        });
      }
    }
  }
  function FoodTaxCheckboxOnchange(e, item, index) {
    if (e.target.checked === true) {
      const list11: any = [...inputFoodTaxList];
      list11[index].IsApply = true;
      setInputFoodTaxList(list11);
      if (
        props.EditPropertiesID !== undefined &&
        props.EditPropertiesID !== null &&
        props.EditPropertiesID !== ""
      ) {
        setPropertySave({
          ...PropertySave,
          ["IsTaxChange"]: true,
        });
      }
    } else {
      const list12: any = [...inputFoodTaxList];
      list12[index].IsApply = false;
      setInputFoodTaxList(list12);
      if (
        props.EditPropertiesID !== undefined &&
        props.EditPropertiesID !== null &&
        props.EditPropertiesID !== ""
      ) {
        setPropertySave({
          ...PropertySave,
          ["IsTaxChange"]: true,
        });
      }
    }
  }
  function BeverageRoomTaxCheckboxOnchange(e, item, index) {
    if (e.target.checked === true) {
      const list31: any = [...inputBeverageTaxList];
      list31[index].IsApply = true;
      setInputBeverageTaxList(list31);
      if (
        props.EditPropertiesID !== undefined &&
        props.EditPropertiesID !== null &&
        props.EditPropertiesID !== ""
      ) {
        setPropertySave({
          ...PropertySave,
          ["IsTaxChange"]: true,
        });
      }
    } else {
      const list32: any = [...inputBeverageTaxList];
      list32[index].IsApply = false;
      setInputBeverageTaxList(list32);
      if (
        props.EditPropertiesID !== undefined &&
        props.EditPropertiesID !== null &&
        props.EditPropertiesID !== ""
      ) {
        setPropertySave({
          ...PropertySave,
          ["IsTaxChange"]: true,
        });
      }
    }
  }

  function GuestRoomTaxOnchange(e, item, index) {
    const NEwlist: any = [...inputGuestRoomTaxList];
    NEwlist[index].RateOrPercentage = e.target.value;

    if (
      props.EditPropertiesID !== undefined &&
      props.EditPropertiesID !== null &&
      props.EditPropertiesID !== ""
    ) {
      NEwlist[index].IsTaxChange = true;
    }
    setInputGuestRoomTaxList(NEwlist);
  }
  function AVEquipmentRoomTaxOnchange(e, item, index) {
    const NEwlist1: any = [...inputAVEquipmentTaxList];
    NEwlist1[index].RateOrPercentage = e.target.value;

    if (
      props.EditPropertiesID !== undefined &&
      props.EditPropertiesID !== null &&
      props.EditPropertiesID !== ""
    ) {
      NEwlist1[index].IsTaxChange = true;
    }
    setInputAVEquipmentTaxList(NEwlist1);
  }
  function FoodRoomTaxOnchange(e, item, index) {
    const NEwlist2: any = [...inputFoodTaxList];
    NEwlist2[index].RateOrPercentage = e.target.value;

    if (
      props.EditPropertiesID !== undefined &&
      props.EditPropertiesID !== null &&
      props.EditPropertiesID !== ""
    ) {
      NEwlist2[index].IsTaxChange = true;
    }
    setInputFoodTaxList(NEwlist2);
  }
  function BeverageRoomTaxOnchange(e, item, index) {
    const NEwlist3: any = [...inputBeverageTaxList];
    NEwlist3[index].RateOrPercentage = e.target.value;

    if (
      props.EditPropertiesID !== undefined &&
      props.EditPropertiesID !== null &&
      props.EditPropertiesID !== ""
    ) {
      NEwlist3[index].IsTaxChange = true;
    }
    setInputBeverageTaxList(NEwlist3);
  }
  //#endregion

  //#region  Av Equipment

  const [avCount, setAvCount] = useState(1);
  const [avDataRes, setAvDataRes] = useState({});
  const [avDataResEr, setAvDataResEr]: any = useState({}); // Av Equipment Error

  const fetchAvData = async (prop_Id) => {
    let data = {
      hotelID: prop_Id,
      isNew: true,
    };
    let res: any = await GetAvEquipmentsAndMISCList(data, Token);
    if (res.status === 200) {
      if (res.data.success) {
        if (res.data.data) {
          let jsonToSet: any = {};
          let JsonToError: any = {};
          res.data.data.dataList1.forEach((res_data_lst, i) => {
            jsonToSet = {
              ...jsonToSet,
              [i]: {
                avEquipName: res_data_lst.AVEquipName ? res_data_lst.AVEquipName : "",
                avEquipType_Term: res_data_lst.AVEquipType_Term
                  ? res_data_lst.AVEquipType_Term
                  : "",
                avEquipCost: res_data_lst.AVEquipCost ? res_data_lst.AVEquipCost : 0,
                taxRatePerc: res_data_lst.TaxRatePerc ? res_data_lst.TaxRatePerc : 0,
                orderNO: res_data_lst.OrderNO ? res_data_lst.OrderNO : 0,
              },
            };
            JsonToError = {
              ...JsonToError,
              [i]: {
                avEquipName: "",
                avEquipType_Term: "",
              },
            };
          });

          setAvCount(res.data.data.dataList1.length - 1);
          setAvDataRes(jsonToSet);
          setAvDataResEr(JsonToError);
        }
      }
    }
  };

  //#endregion

  //#region Food And Beverage :

  const [countFood, setCountFood] = useState(1);
  const [dataResFood, setDataResFood]: any = useState({});
  const [dataResFoodEr, setDataResFoodEr]: any = useState({}); // Food Error

  const [countBeverage, setCountBeverage] = useState(1);
  const [dataResBeverage, setDataResBeverage]: any = useState({});
  const [dataResBeverageEr, setDataResBeverageEr]: any = useState({}); // Beverage Error

  const fetchFoodData = async (property_id) => {
    let data = {
      hotelID: property_id,
      isNew: true,
      itemType_Term: "Food",
    };
    let res: any = await GetFoodAndBeveragesList(data, Token);
    if (res.status === 200) {
      if (res.data.success) {
        if (res.data.data) {
          let jsonToSet: any = {};
          let JsonToError: any = {};

          res.data.data.dataList1.forEach((res_data_lst, i) => {
            jsonToSet = {
              ...jsonToSet,
              [i]: {
                itemName: res_data_lst.ItemName ? res_data_lst.ItemName : "",
                itemType_Term: res_data_lst.ItemType_Term ? res_data_lst.ItemType_Term : "",
                itemCostPerUnit: res_data_lst.ItemCostPerUnit ? res_data_lst.ItemCostPerUnit : 0,
                taxRatePerc: res_data_lst.TaxRatePerc ? res_data_lst.TaxRatePerc : 0,
                orderNo: res_data_lst.OrderNo ? res_data_lst.OrderNo : 0,
                description: res_data_lst.Description ? res_data_lst.Description : "",
              },
            };
            JsonToError = {
              ...JsonToError,
              [i]: {
                itemName: "",
              },
            };
          });

          setCountFood(Object.keys(jsonToSet).length - 1);
          setDataResFood(jsonToSet);
          setDataResFoodEr(JsonToError);
        }
      }
    }
  };

  const fetchFoodBeverage = async (property_id) => {
    let data = {
      hotelID: property_id,
      isNew: true,
      itemType_Term: "Beverage",
    };
    let res: any = await GetFoodAndBeveragesList(data, Token);
    if (res.status === 200) {
      if (res.data.success) {
        if (res.data.data) {
          let jsonToSetBeverage: any = {};
          let JsonToError: any = {};
          res.data.data.dataList1.forEach((res_data_lst, i) => {
            jsonToSetBeverage = {
              ...jsonToSetBeverage,
              [i]: {
                itemName: res_data_lst.ItemName ? res_data_lst.ItemName : "",
                itemType_Term: res_data_lst.ItemType_Term ? res_data_lst.ItemType_Term : "",
                itemCostPerUnit: res_data_lst.ItemCostPerUnit ? res_data_lst.ItemCostPerUnit : 0,
                taxRatePerc: res_data_lst.TaxRatePerc ? res_data_lst.TaxRatePerc : 0,
                orderNo: res_data_lst.OrderNo ? res_data_lst.OrderNo : 0,
                description: res_data_lst.Description ? res_data_lst.Description : "",
              },
            };
            JsonToError = {
              ...JsonToError,
              [i]: {
                itemName: "",
              },
            };
          });

          setDataResBeverage(jsonToSetBeverage);
          setDataResBeverageEr(JsonToError);
          setCountBeverage(Object.keys(jsonToSetBeverage).length - 1);
        }
      }
    }
  };

  //#endregion

  //#region Validation Functions : Food, Av, Beverage ;

  const [isFoodValid, setIsFoodValid] = useState(true);
  const [isBeverageValid, setIsBeverageValid] = useState(true);
  const [isAvValid, setIsAvValid] = useState(true);
  const [isEstBarRate, setIsEstBarRate] = useState(true);

  const validateFoodData = () => {
    let isValidate = true;
    let errosJson = {};

    Object.keys(dataResFoodEr).forEach((er_key) => {
      errosJson = {
        ...errosJson,
        [er_key]: {
          itemName: "",
        },
      };
    });

    Object.keys(dataResFood).forEach((key_gr) => {
      if (dataResFood[key_gr].itemName === "") {
        errosJson = {
          ...errosJson,
          [key_gr]: {
            ...errosJson[key_gr],
            itemName: "This field is required.",
          },
        };
        isValidate = false;
      }
    });
    setDataResFoodEr(errosJson);
    setIsFoodValid(isValidate);
    return isValidate;
  };

  const validateBeverageData = () => {
    let isValidate = true;
    let errosJson = {};

    Object.keys(dataResBeverageEr).forEach((er_key) => {
      errosJson = {
        ...errosJson,
        [er_key]: {
          itemName: "",
        },
      };
    });

    Object.keys(dataResBeverage).forEach((key_gr) => {
      if (dataResBeverage[key_gr].itemName === "") {
        errosJson = {
          ...errosJson,
          [key_gr]: {
            ...errosJson[key_gr],
            itemName: "This field is required.",
          },
        };
        isValidate = false;
      }
    });
    setDataResBeverageEr(errosJson);
    setIsBeverageValid(isValidate);
    return isValidate;
  };

  const validAvEquipmentData = () => {
    let isValidate = true;
    let errosJson = {};

    Object.keys(avDataResEr).forEach((er_key) => {
      errosJson = {
        ...errosJson,
        [er_key]: {
          avEquipName: "",
          avEquipType_Term: "",
        },
      };
    });

    Object.keys(avDataRes).forEach((key_gr) => {
      if (avDataRes[key_gr].avEquipName === "") {
        errosJson = {
          ...errosJson,
          [key_gr]: {
            ...errosJson[key_gr],
            avEquipName: "This field is required.",
          },
        };
        isValidate = false;
      }
      if (avDataRes[key_gr].avEquipType_Term === "") {
        errosJson = {
          ...errosJson,
          [key_gr]: {
            ...errosJson[key_gr],
            avEquipType_Term: "This field is required.",
          },
        };
        isValidate = false;
      }
    });
    setAvDataResEr(errosJson);
    setIsAvValid(isValidate);

    return isValidate;
  };

  //#endregion End Validation Function Scope ;

  //#region Competitor Tab Code
  let page_rights_Hotel = CommonService.getPageRight("Hotels");

  let [CompetitorList, setCompetitorList]: any = React.useState({ IsData: true, data: [] });
  // let [CompetitorData, setCompetitorData]: any = React.useState([]);
  const [showModel, setShowModel] = React.useState(false);
  const [competitorID, setCompetitorID] = React.useState("");
  const [CompetitorEditData, setCompetitorEditData] = React.useState({});
  const [CompetitorLoader, setCompetitorLoader] = React.useState(false);

  const fetchCompetitorListData = async () => {
    let data = {
      keyID: props && props.EditPropertiesID,
    };

    setCompetitorLoader(true);
    setCompetitorList({ IsData: true, data: [] });
    let res: any = await CompSetHotelGetList(data, Token);
    if (res.status === 200) {
      if (res.data.success) {
        if (res.data !== undefined && res.data !== null) {
          if (res.data.data !== undefined && res.data.data !== null) {
            let tempData = [...res.data.data.dataList1];
            for (let index = 0; index < tempData.length; index++) {
              tempData[index]["id"] = Math.floor(Math.random() * 999999 + 1);
            }

            setTempMap(tempData);
            setCompetitorList({ IsData: false, data: res.data.data.dataList1 });
            setCompetitorLoader(false);
          } else {
            setCompetitorLoader(false);
            setCompetitorList({ IsData: false, data: [] });
          }
        } else {
          setCompetitorLoader(false);
          setCompetitorList({ IsData: false, data: [] });
        }
      } else {
        setCompetitorLoader(false);
        setCompetitorList({ IsData: false, data: [] });
      }
    } else {
      setCompetitorLoader(false);
      setCompetitorList({ IsData: false, data: [] });
    }
  };
  //#endregion Competitor End :

  const [tempMap, setTempMap]: any = useState([]);

  function DeleteCompetitorNew(itmIndex) {
    Swal.fire({
      title: "Are you sure want to delete ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "No",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let tempCompList: any = [...tempMap];
        let index = tempCompList.findIndex((item) => item.id === itmIndex);
        if (index !== null && index >= 0) {
          tempCompList.splice(index, 1);
        }
        setTempMap(tempCompList);

        ToasterSuccess("Competitor Hotel Deleted SuccessFully");
      }
    });
  }

  function DeleteCompetitorExist(itm: any) {
    Swal.fire({
      title: "Are you sure want to delete ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "No",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let input: any = {
          keyID: itm.CompHotelID,
        };

        let res: any = await CompSetHotelsDelete(input, Token);
        if (res.data.success) {
          if (res.data !== undefined && res.data !== null && res.data !== "") {
            if (res.data.statusCode === 200) {
              let tempCompList: any = [...tempMap];
              let index = tempCompList.findIndex((item) => item.id === itm.id);
              if (index !== null && index >= 0) {
                tempCompList.splice(index, 1);
              }
              setTempMap(tempCompList);

              ToasterSuccess("Competitor Hotel Deleted SuccessFully");
            }
          } else {
            ToasterError(res.data.message);
          }
        }
      }
    });
  }

  function fnReturnData(data) {
    let lstData = [...tempMap];
    if (data.id === 0) {
      data.id = Math.floor(Math.random() * 999999 + 1);
      lstData.push(data);
    } else {
      let index = lstData.findIndex((item) => item.id === data.id);
      if (index !== null && index >= 0) {
        lstData[index] = data;
      }
    }
    setTempMap(lstData);
  }

  const handeFloatValueToFixed = (value) => {
    const regexSpecialChars = /[!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/;
    if (!regexSpecialChars.test(value)) {
      if (!/[a-zA-Z]/.test(value)) {
        // Use a regular expression to validate the format
        const regex = /^\d*\.?\d{0,2}$/;
        if (regex.test(value) || value === "") {
          return value;
        } else {
          let splitedValue: any = value?.includes(".") ? value?.split(".")[1] : value;
          if (splitedValue?.length > 2) {
            return parseFloat(value)?.toFixed(2);
          } else {
            return value;
          }
        }
      }
    }
  };

  const calculateFloatValueToFixed = (value, prevValue) => {
    const regexSpecialChars = /[!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/;
    if (!regexSpecialChars.test(value)) {
      // Use a regular expression to validate the format
      const regex = /^\d*\.?\d{0,2}$/;
      if (regex.test(value) || value === "") {
        return value;
      } else {
        if (value?.includes(".")) {
          let arr = value?.split(".");
          if (arr?.length > 1) {
            return prevValue;
          } else {
            return value?.toFixed(2);
          }
        } else {
          return value?.toFixed(2);
        }
      }
    }
  };

  return (
    <>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="modal-50w remove-tabIndex"
        className="quickAdd-popup hotel-information-table-AddRoomSetupColumn"
        scrollable={true}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Hotel Information {PropertySave.HPropertyName && "(" + PropertySave.HPropertyName + ")"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs
            activeKey={"tab" + AddPropertyActiveKey}
            id="uncontrolled-tab-example"
            className="tab-component mb-3"
            onSelect={(key) =>
              setAddPropertyActiveKey(key === null ? 1 : parseInt(key.replace("tab", "")))
            }
          >
            <Tab
              tabClassName={`${
                PropertySave.errors.HChain
                  ? "validate-tab"
                  : PropertySave.errors.HPropertyName
                  ? "validate-tab"
                  : PropertySave.errors.HTotalRoom
                  ? "validate-tab"
                  : PropertySave.errors.HPropertyCode
                  ? "validate-tab"
                  : PropertySave.errors.HPortFolio
                  ? "validate-tab"
                  : ""
              }`}
              eventKey="tab1"
              title="Hotel"
            >
              {/* tabClassName={`${SaveRFP.errors.travelAgencyPhone ? "validate-tab" : ""}`}> */}
              {!loading ? (
                <Container fluid="true" className="p-0">
                  <Row className="m-0 mb-2">
                    <Col xs={12} className="mb-3 ps-0">
                      <Form.Group>
                        <Form.Label className="ps-0 ms-0">Property Name* </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Property Name"
                          maxLength={369}
                          value={PropertySave.HPropertyName}
                          onChange={(e) =>
                            setPropertySave({
                              ...PropertySave,
                              ["HPropertyName"]: e.target.value,
                            })
                          }
                          isInvalid={PropertySave.errors.HPropertyName}
                        />
                        {PropertySave.errors.HPropertyName && (
                          <Form.Control.Feedback type="invalid">
                            {PropertySave.errors.HPropertyName}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="m-0 mb-2">
                    {/* <Col xs={6} className="mb-3 pe-0">
                      <FloatingLabel controlId="floatingInput" label="TARGET BAR Weekday Rate">
                        <Form.Control
                          type="number"
                          onWheel={(event: any) => {
                            event.target.blur();
                          }}
                          onKeyDown={(e: any) => {
                            if (e.keyCode == "38" || e.keyCode == "40") {
                              e.stopPropagation();
                              e.preventDefault();
                            }
                          }}
                          placeholder="TARGET BAR Weekday Rate"
                          value={PropertySave.HTARGETBAR}
                          step="any"
                          onChange={(e) =>
                            setPropertySave({
                              ...PropertySave,
                              ["HTARGETBAR"]: e.target.value,
                            })
                          }
                          isInvalid={PropertySave.errors.HTARGETBAR}
                        />
                        {PropertySave.errors.HTARGETBAR && (
                          <Form.Control.Feedback type="invalid">
                            {PropertySave.errors.HTARGETBAR}
                          </Form.Control.Feedback>
                        )}
                      </FloatingLabel>
                    </Col> */}
                    <Col xs={6} className="mb-3 ps-0">
                      <FloatingLabel controlId="floatingInput" label="Secondary Name">
                        {/* <Form.Control type="text" placeholder="Secondary Name" /> */}
                        <Form.Control
                          type="text"
                          maxLength={364}
                          value={PropertySave.HSecondaryName}
                          //defaultValue={PropertySave.HSecondaryName}
                          placeholder="Secondary Name"
                          onChange={(e) =>
                            setPropertySave({
                              ...PropertySave,
                              ["HSecondaryName"]: e.target.value,
                            })
                          }
                        />
                      </FloatingLabel>
                    </Col>
                    <Col xs={6} className="mb-3 pe-0">
                      <FloatingLabel controlId="floatingInput" label="Total Rooms*">
                        {/* <Form.Control type="text" placeholder="Total Rooms" /> */}
                        <Form.Control
                          type="number"
                          onWheel={(event: any) => {
                            event.target.blur();
                          }}
                          onKeyDown={(e: any) => {
                            if (e.keyCode == "38" || e.keyCode == "40") {
                              e.stopPropagation();
                              e.preventDefault();
                            }
                          }}
                          placeholder="Total Rooms"
                          value={PropertySave.HTotalRoom}
                          onChange={(e) =>
                            setPropertySave({
                              ...PropertySave,
                              ["HTotalRoom"]: e.target.value,
                            })
                          }
                          isInvalid={PropertySave.errors.HTotalRoom}
                        />
                        {PropertySave.errors.HTotalRoom && (
                          <Form.Control.Feedback type="invalid">
                            {PropertySave.errors.HTotalRoom}
                          </Form.Control.Feedback>
                        )}
                      </FloatingLabel>
                    </Col>
                  </Row>
                  <Row className="m-0 mb-2">
                    <Col xs={6} className="mb-3 ps-0 fild-input">
                      <div className={`${PropertySave.errors.HChain && "red-border-masking"}`}>
                        <FloatingLabel controlId="floatingInput" label="">
                          {PropertySave.HChain !== "" ? (
                            <CreatableSelect
                              isClearable
                              options={items}
                              onChange={(event: any) => {
                                handleOnSelect(event);
                              }}
                              placeholder="Chain*"
                              value={{ label: PropertySave.HChain, value: PropertySave.HChain }}
                            />
                          ) : (
                            <CreatableSelect
                              isClearable
                              options={items}
                              onChange={(event: any) => {
                                handleOnSelect(event);
                              }}
                              placeholder="Chain*"
                            />
                          )}
                        </FloatingLabel>
                      </div>
                      <small style={{ color: "#dc3545" }}>
                        {PropertySave.errors.HChain && PropertySave.errors.HChain}{" "}
                      </small>
                    </Col>
                    <Col xs={6} className="mb-3 ps-0">
                      <FloatingLabel controlId="floatingInput" label="Property Code*">
                        {/* <Form.Control type="text" placeholder="Property Code" /> */}
                        <Form.Control
                          type="text"
                          maxLength={16}
                          defaultValue={PropertySave.HPropertyCode}
                          value={PropertySave.HPropertyCode}
                          placeholder="Property Code"
                          onChange={(e) =>
                            setPropertySave({
                              ...PropertySave,
                              ["HPropertyCode"]: e.target.value,
                            })
                          }
                          isInvalid={PropertySave.errors.HPropertyCode}
                        />
                        {PropertySave.errors.HPropertyCode && (
                          <Form.Control.Feedback type="invalid">
                            {PropertySave.errors.HPropertyCode}
                          </Form.Control.Feedback>
                        )}
                      </FloatingLabel>
                    </Col>
                  </Row>
                  <Row className="m-0">
                    <Col xs={6} className="mb-3 ps-0">
                      <FloatingLabel controlId="floatingInput" label="Hotel Code" className="mt-4">
                        <Form.Control
                          id="HotelCode_Value"
                          disabled
                          type="text"
                          maxLength={164}
                          placeholder="Hotel Code"
                          value={
                            PropertySave.HChain.split("-")[0].trim() + PropertySave.HPropertyCode
                          }
                          onChange={(e) =>
                            setPropertySave({
                              ...PropertySave,
                              ["HHotelCode"]: e.target.value,
                            })
                          }
                        />
                      </FloatingLabel>

                      <FloatingLabel controlId="floatingSelectGrid" label="" className="mt-4">
                        <Form.Group>
                          <Row>
                            <Col xs={11}>
                              <div
                                className={`${
                                  PropertySave.errors.HPortFolio && "red-border-masking"
                                }`}
                              >
                                <Select
                                  placeholder="Select Portfolio*"
                                  value={{
                                    label: PropertySave.HPortFolioName || "Select Portfolio*",
                                    value: PropertySave.HPortFolio,
                                  }}
                                  className={`multiselect ${
                                    PropertySave.errors.HPortFolio && "is-invalid"
                                  }`}
                                  options={optionsPortFolio}
                                  onChange={(e: any) =>
                                    setPropertySave({
                                      ...PropertySave,
                                      ["HPortFolio"]: e.value,
                                      ["HPortFolioName"]: e.label,
                                    })
                                  }
                                />
                              </div>
                              <small style={{ color: "#dc3545" }}>
                                {" "}
                                {PropertySave.errors.HPortFolio &&
                                  PropertySave.errors.HPortFolio}{" "}
                              </small>
                            </Col>
                            <Col xs={1}>
                              <div className="btn-icon-grp-table button.btn-icon.btn.btn-primary addFormIcon d-flex justify-content-end">
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={<Tooltip>Add New Portfolio</Tooltip>}
                                >
                                  <Button
                                    className="btn-icon addFormIcon"
                                    onClick={() => {
                                      setShowPortfolioModel(true);
                                    }}
                                  ></Button>
                                </OverlayTrigger>
                              </div>
                              {ShowPortfolioModel === true && (
                                <AddPortFolio
                                  hotelAddPortfolio={true}
                                  Token={Token}
                                  portfolioID={""}
                                  GetPortFolioList={getPortFolioDropdown}
                                  show={ShowPortfolioModel}
                                  onHide={() => setShowPortfolioModel(false)}
                                ></AddPortFolio>
                              )}
                            </Col>
                          </Row>
                        </Form.Group>
                      </FloatingLabel>

                      <FloatingLabel controlId="floatingSelectGrid" label="" className="mt-4">
                        <Form.Group>
                          <Row>
                            <Col xs={12}>
                              {props.EditPropertiesID !== undefined &&
                                props.EditPropertiesID !== null &&
                                props.EditPropertiesID !== "" && (
                                  <>
                                    <FloatingLabel controlId="floatingSelectGrid" label="">
                                      <Select
                                        placeholder="Select Lead Catcher"
                                        value={leadCatcherOptionList.filter(
                                          (obj: any) =>
                                            PropertySave.HLeadCatcher &&
                                            PropertySave.HLeadCatcher.includes(obj.value)
                                        )}
                                        options={leadCatcherOptionList}
                                        onChange={(e: any) =>
                                          setPropertySave({
                                            ...PropertySave,
                                            ["HLeadCatcher"]: e.value,
                                          })
                                        }
                                      />
                                    </FloatingLabel>
                                  </>
                                )}
                            </Col>
                          </Row>
                        </Form.Group>
                      </FloatingLabel>
                    </Col>
                    <Col xs={6} className="mb-3 pe-0">
                      <div className="custom-file comaninputfile">
                        {error && <p style={{ color: "red" }}>{error}</p>}
                        {LogoUpload && (
                          <img
                            src={LogoUpload}
                            style={{
                              display: "flex",
                              border: "2px solid tomato",
                              maxWidth: "300px",
                              maxHeight: "300px",
                            }}
                            alt=""
                          />
                        )}
                      </div>
                      <div className="comninputbtn">
                        <span>Select Logo</span>
                        <input
                          type="file"
                          accept="image/*"
                          id="inputGroupFile01"
                          className=""
                          onChange={SelectLogo}
                        />
                      </div>

                      {/* </div> */}
                    </Col>
                  </Row>
                  <Row className="m-0 mb-2">
                    <Col xs={6} className="mb-3 ps-0">
                      <Form.Group>
                        <Form.Label className="ps-0 ms-0">Status</Form.Label>
                        {["radio"].map((type: any) => (
                          <div key={`inline-${type}`} className="">
                            {StatusData.length > 0 &&
                              StatusData.map((item) => (
                                <>
                                  <Form.Check
                                    inline
                                    label={item.TermCode}
                                    name={item.TermCode}
                                    type={type}
                                    id={item.TermCode}
                                    checked={PropertySave.HStatus === item.TermCode ? true : false}
                                    value={PropertySave.HStatus}
                                    onChange={() => handlePropChackLimit(item)}
                                    disabled={
                                      item.Term === "Inactive" &&
                                      PropertySave.HPropertyID !== null &&
                                      PropertySave.HPropertyID !== undefined &&
                                      PropertySave.HPropertyID !== "" &&
                                      PropertySave.HPropertyID !==
                                        "00000000-0000-0000-0000-000000000000" &&
                                      userTypeId === "3d8522c8-ebff-470f-95dd-b8c2ad1b678f"
                                        ? true
                                        : false
                                    }
                                  />
                                </>
                              ))}
                          </div>
                        ))}
                      </Form.Group>
                    </Col>
                    <Col xs={6} className="mb-3 pe-0 mt-3 ">
                      <Form.Check
                        inline
                        label="Assign user on auto activities"
                        type="checkbox"
                        id="CustomFieldAllow"
                        onChange={(e: any) =>
                          setPropertySave({
                            ...PropertySave,
                            ["HAssignUser"]: e.target.checked,
                          })
                        }
                        checked={PropertySave.HAssignUser}
                      />
                    </Col>
                  </Row>
                  <Row className="m-0 mb-2">
                    <Col xs={6} className="mb-3 ps-0">
                      <FloatingLabel controlId="floatingSelectGrid" label="">
                        <Form.Group>
                          <Select
                            placeholder="Select RFP System"
                            className="multiselect"
                            options={optionsRFP}
                            value={{
                              label: PropertySave.HRFPSystem || "Select RFP System",
                              value: PropertySave.HRFPSystem || "Select RFP System",
                            }}
                            onChange={(e: any) =>
                              setPropertySave({
                                ...PropertySave,
                                ["HRFPSystem"]: e.value,
                              })
                            }
                          />
                        </Form.Group>
                      </FloatingLabel>
                    </Col>
                    <Col xs={6} className="mb-3 pe-0">
                      <FloatingLabel controlId="floatingInput" label="TARGET LNR & RFP RATE">
                        <Form.Control
                          type="number"
                          onWheel={(event: any) => {
                            event.target.blur();
                          }}
                          onKeyDown={(e: any) => {
                            if (e.keyCode == "38" || e.keyCode == "40") {
                              e.stopPropagation();
                              e.preventDefault();
                            }
                          }}
                          placeholder="TARGET LNR & RFP RATE"
                          step="any"
                          value={PropertySave.HTARGETLNR}
                          onChange={(e: any) =>
                            setPropertySave({
                              ...PropertySave,
                              ["HTARGETLNR"]: e.target.value,
                            })
                          }
                          isInvalid={PropertySave.errors.HTARGETLNR}
                        />
                        {PropertySave.errors.HTARGETLNR && (
                          <Form.Control.Feedback type="invalid">
                            {PropertySave.errors.HTARGETLNR}
                          </Form.Control.Feedback>
                        )}
                      </FloatingLabel>
                    </Col>
                  </Row>
                  <Row className="m-0 mb-2">
                    <Col xs={6} className="mb-3 ps-0">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Brand Portal Access website Link"
                      >
                        <Form.Control
                          type="text"
                          maxLength={254}
                          placeholder="Website Link"
                          onChange={(e: any) =>
                            setPropertySave({
                              ...PropertySave,
                              ["HBrandWebsite"]: e.target.value,
                            })
                          }
                          value={PropertySave.HBrandWebsite}
                        />
                      </FloatingLabel>
                    </Col>
                    <Col xs={6}>
                      {/* Brand User Password */}
                      <Row>
                        <Col xs={6} className="mb-3">
                          <FloatingLabel controlId="floatingInput" label="Brand Username">
                            <Form.Control
                              type="text"
                              maxLength={166}
                              placeholder="Username"
                              onChange={(e: any) =>
                                setPropertySave({
                                  ...PropertySave,
                                  ["HBrandLogin"]: e.target.value,
                                })
                              }
                              value={PropertySave.HBrandLogin}
                            />
                          </FloatingLabel>
                        </Col>
                        <Col xs={6} className="mb-3 pe-0">
                          <FloatingLabel controlId="floatingInput" label="Brand Password">
                            <Form.Control
                              type="text"
                              maxLength={166}
                              placeholder="Password"
                              onChange={(e: any) =>
                                setPropertySave({
                                  ...PropertySave,
                                  ["HBrandPassword"]: e.target.value,
                                })
                              }
                              value={PropertySave.HBrandPassword}
                            />
                          </FloatingLabel>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row className="m-0 mb-2">
                    <Col xs={6} className="mb-3 ps-0">
                      <FloatingLabel controlId="floatingInput" label="DUNS Number">
                        <Form.Control
                          type="text"
                          maxLength={66}
                          placeholder="DUNS Number"
                          onChange={(e: any) =>
                            setPropertySave({
                              ...PropertySave,
                              ["HDUNSNumber"]: e.target.value,
                            })
                          }
                          value={PropertySave.HDUNSNumber}
                        />
                      </FloatingLabel>
                    </Col>
                    <Col xs={6} className="mb-3 pe-0">
                      <FloatingLabel controlId="floatingInput" label="FEMA Number">
                        <Form.Control
                          type="text"
                          maxLength={66}
                          placeholder="FEMA Number"
                          onChange={(e: any) =>
                            setPropertySave({
                              ...PropertySave,
                              ["HFEMANumber"]: e.target.value,
                            })
                          }
                          value={PropertySave.HFEMANumber}
                        />
                      </FloatingLabel>
                    </Col>
                  </Row>
                </Container>
              ) : (
                <PageLoader />
              )}
            </Tab>
            <Tab eventKey="tab2" title="Competitor Hotels">
              <Container fluid="true" className="p-0">
                <h6>Competitor Hotel Details</h6>

                <div className="btn-icon-grp-table button.btn-icon.btn.btn-primary addFormIcon d-flex justify-content-end">
                  <OverlayTrigger placement="bottom" overlay={<Tooltip>Add Competitor</Tooltip>}>
                    <Button
                      className="btn-icon addFormIcon"
                      onClick={() => {
                        setShowModel(true);
                      }}
                    ></Button>
                  </OverlayTrigger>
                </div>

                {!loading ? (
                  <div className="tax-information table-responsive">
                    <Table className="table-without-space mb-0" responsive bordered>
                      <thead>
                        <tr>
                          <th style={{ width: "30%" }} className="settitle">
                            Brand Name
                          </th>
                          <th style={{ width: "50%" }} className="settitle">
                            Hotel Name
                          </th>

                          <th style={{ width: "20%", textAlign: "center" }} className="settitle">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {tempMap !== undefined && tempMap && tempMap.length > 0 ? (
                          tempMap.map((itm, idx) => (
                            <tr key={idx}>
                              <td style={{ width: "30%" }}>{itm.BrandName}</td>
                              <td style={{ width: "50%" }}>{itm.HotelName}</td>
                              <td style={{ width: "20%" }}>
                                <>
                                  <span className="btn-icon-grp-table justify-content-end  align-items-center">
                                    <Button
                                      className="btn-icon button btn btn-primary editIcon acteditbtn"
                                      onClick={() => {
                                        if (page_rights_Hotel.is_update) {
                                          setCompetitorID(itm.id);
                                          setCompetitorEditData(itm);
                                          setShowModel(true);
                                        } else {
                                          CommonService.unauthorizedAction();
                                        }
                                      }}
                                    ></Button>
                                    <Button
                                      className="btn-icon cancelIcon"
                                      onClick={() => {
                                        if (page_rights_Hotel.is_delete) {
                                          if (
                                            itm.CompHotelID !==
                                            "00000000-0000-0000-0000-000000000000"
                                          ) {
                                            DeleteCompetitorExist(itm);
                                          } else {
                                            DeleteCompetitorNew(itm.id);
                                          }
                                        } else {
                                          CommonService.unauthorizedAction();
                                        }
                                      }}
                                    ></Button>
                                  </span>
                                </>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td style={{ textAlign: "center" }} colSpan={5}>
                              No Data found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                ) : (
                  <PageLoader />
                )}
                {showModel && (
                  <AddEditCompetitorModal
                    setCompetitorData={fnReturnData}
                    property_id={props.EditPropertiesID}
                    competitorID={competitorID}
                    CompetitorEditData={CompetitorEditData}
                    show={showModel}
                    fetchCompetitorListData={fetchCompetitorListData}
                    onHide={() => {
                      setShowModel(false);
                      setCompetitorID("");
                    }}
                  />
                )}
              </Container>
            </Tab>

            <Tab
              tabClassName={`${
                PropertySave.errors.OOWPhone
                  ? "validate-tab"
                  : PropertySave.errors.OOWEmail
                  ? "validate-tab"
                  : PropertySave.errors.OGMPhone
                  ? "validate-tab"
                  : PropertySave.errors.OGMEmail
                  ? "validate-tab"
                  : PropertySave.errors.ODSPhone
                  ? "validate-tab"
                  : PropertySave.errors.ODSEmail
                  ? "validate-tab"
                  : PropertySave.errors.OCMPhone
                  ? "validate-tab"
                  : PropertySave.errors.OCMEmail
                  ? "validate-tab"
                  : PropertySave.errors.OSMPhone
                  ? "validate-tab"
                  : PropertySave.errors.OSMEmail
                  ? "validate-tab"
                  : PropertySave.errors.ORMPhone
                  ? "validate-tab"
                  : PropertySave.errors.ORMEmail
                  ? "validate-tab"
                  : PropertySave.errors.OREPhone
                  ? "validate-tab"
                  : PropertySave.errors.OREEmail
                  ? "validate-tab"
                  : PropertySave.errors.ORSPhone
                  ? "validate-tab"
                  : PropertySave.errors.ORSEmail
                  ? "validate-tab"
                  : ""
              }`}
              eventKey="tab3"
              title="Organization"
            >
              <Container fluid="true" className="p-0">
                <Row className="m-0">
                  <Col xs={6} className="mb-2 ps-0">
                    <Row className="m-0 mb-2">
                      <Col xs={12} className="mb-2 p-0">
                        <h6>Owner</h6>
                      </Col>
                    </Row>
                    <Row className="m-0 mb-2">
                      <Col xs={12} className="mb-3 p-0">
                        <FloatingLabel controlId="floatingInput" label="Name">
                          <Form.Control
                            type="text"
                            maxLength={365}
                            placeholder="Name"
                            value={PropertySave.OOWName}
                            onChange={(e) =>
                              setPropertySave({
                                ...PropertySave,
                                ["OOWName"]: e.target.value,
                              })
                            }
                          />
                        </FloatingLabel>
                      </Col>
                    </Row>
                    <Row className="m-0 mb-2">
                      <Col xs={3} className="mb-3 p-0">
                        <FloatingLabel controlId="floatingInput" label="C_Code">
                          <Form.Control
                            type="text"
                            placeholder="C_Code"
                            maxLength={66}
                            value={PropertySave.OOWCountryCode}
                            onChange={(e) =>
                              setPropertySave({
                                ...PropertySave,
                                ["OOWCountryCode"]: e.target.value,
                              })
                            }
                          />
                        </FloatingLabel>
                      </Col>
                      <Col xs={6} className="mb-3">
                        <FloatingLabel controlId="floatingInput" label="">
                          <div
                            className={`${PropertySave.errors.OOWPhone && "red-border-masking"}`}
                          >
                            <FloatingLabel controlId="floatingInput" label="Phone">
                              <PatternFormat
                                className={`form-control ${
                                  PropertySave.errors.OOWPhone && "is-invalid"
                                }`}
                                displayType="input"
                                placeholder="Phone"
                                value={PropertySave.OOWPhone}
                                format="###-###-####"
                                onChange={(e) =>
                                  setPropertySave({
                                    ...PropertySave,
                                    ["OOWPhone"]: e.target.value,
                                  })
                                }
                              />
                            </FloatingLabel>
                          </div>
                          {PropertySave.errors.OOWPhone && (
                            <>
                              <span className="feedbackss">{PropertySave.errors.OOWPhone}</span>
                              <br />
                            </>
                          )}
                        </FloatingLabel>
                      </Col>
                      <Col xs={3} className="mb-3 pe-0">
                        <FloatingLabel controlId="floatingInput" label="Ext">
                          <Form.Control
                            type="text"
                            placeholder="Ext"
                            maxLength={9}
                            value={PropertySave.OOWExt}
                            onChange={(e) =>
                              setPropertySave({
                                ...PropertySave,
                                ["OOWExt"]: e.target.value,
                              })
                            }
                          />
                        </FloatingLabel>
                      </Col>
                    </Row>
                    <Row className="m-0 mb-2">
                      <Col xs={12} className="mb-3 p-0">
                        <FloatingLabel controlId="floatingInput" label="Email">
                          <Form.Control
                            type="text"
                            maxLength={349}
                            value={PropertySave.OOWEmail}
                            placeholder="Email"
                            onChange={(e) =>
                              setPropertySave({
                                ...PropertySave,
                                ["OOWEmail"]: e.target.value,
                              })
                            }
                            isInvalid={PropertySave.errors.OOWEmail}
                          />
                          {PropertySave.errors.OOWEmail && (
                            <Form.Control.Feedback type="invalid">
                              {PropertySave.errors.OOWEmail}
                            </Form.Control.Feedback>
                          )}
                        </FloatingLabel>
                      </Col>
                    </Row>
                  </Col>
                  {/* Owner End */}

                  <Col xs={6} className="mb-2 pe-0">
                    <Row className="m-0 mb-2">
                      <Col xs={12} className="mb-2 p-0">
                        <h6>GM</h6>
                      </Col>
                    </Row>
                    <Row className="m-0 mb-2">
                      <Col xs={12} className="mb-3 p-0">
                        <FloatingLabel controlId="floatingInput" label="Name">
                          <Form.Control
                            type="text"
                            maxLength={364}
                            placeholder="Name"
                            defaultValue={PropertySave.OGMName}
                            onChange={(e) =>
                              setPropertySave({
                                ...PropertySave,
                                ["OGMName"]: e.target.value,
                              })
                            }
                          />
                        </FloatingLabel>
                      </Col>
                    </Row>
                    <Row className="m-0 mb-2">
                      <Col xs={3} className="mb-3 ps-0">
                        <FloatingLabel controlId="floatingInput" label="C_Code">
                          <Form.Control
                            type="text"
                            placeholder="C_Code"
                            maxLength={66}
                            value={PropertySave.OGMCountryCode}
                            onChange={(e) =>
                              setPropertySave({
                                ...PropertySave,
                                ["OGMCountryCode"]: e.target.value,
                              })
                            }
                          />
                        </FloatingLabel>
                      </Col>
                      <Col xs={6} className="mb-3">
                        <div className={`${PropertySave.errors.OGMPhone && "red-border-masking"}`}>
                          <FloatingLabel controlId="floatingInput" label="Phone">
                            <PatternFormat
                              className={`form-control ${
                                PropertySave.errors.OGMPhone && "is-invalid"
                              }`}
                              displayType="input"
                              placeholder="Phone"
                              value={PropertySave.OGMPhone}
                              format="###-###-####"
                              onChange={(e) =>
                                setPropertySave({
                                  ...PropertySave,
                                  ["OGMPhone"]: e.target.value,
                                })
                              }
                            />
                          </FloatingLabel>
                        </div>
                        {PropertySave.errors.OGMPhone && (
                          <>
                            <span className="feedbackss">{PropertySave.errors.OGMPhone}</span>
                            <br />
                          </>
                        )}
                      </Col>
                      <Col xs={3} className="mb-3 pe-0">
                        <FloatingLabel controlId="floatingInput" label="Ext">
                          <Form.Control
                            type="text"
                            placeholder="Ext"
                            maxLength={9}
                            value={PropertySave.OGMExt}
                            onChange={(e) =>
                              setPropertySave({
                                ...PropertySave,
                                ["OGMExt"]: e.target.value,
                              })
                            }
                          />
                        </FloatingLabel>
                      </Col>
                    </Row>
                    <Row className="m-0 mb-2">
                      <Col xs={12} className="mb-3 p-0">
                        <FloatingLabel controlId="floatingInput" label="Email">
                          <Form.Control
                            type="text"
                            maxLength={349}
                            placeholder="Email"
                            value={PropertySave.OGMEmail}
                            onChange={(e) =>
                              setPropertySave({
                                ...PropertySave,
                                ["OGMEmail"]: e.target.value,
                              })
                            }
                            isInvalid={PropertySave.errors.OGMEmail}
                          />
                          {PropertySave.errors.OGMEmail && (
                            <Form.Control.Feedback type="invalid">
                              {PropertySave.errors.OGMEmail}
                            </Form.Control.Feedback>
                          )}
                        </FloatingLabel>
                      </Col>
                    </Row>
                  </Col>
                  {/* GM End */}
                </Row>

                <Row className="m-0 ">
                  <Col xs={6} className="mb-2 ps-0">
                    <Row className="m-0 mb-2">
                      <Col xs={12} className="mb-2 p-0">
                        <h6>Director of Sales</h6>
                      </Col>
                    </Row>
                    <Row className="m-0 mb-2">
                      <Col xs={12} className="mb-3 p-0">
                        <FloatingLabel controlId="floatingInput" label="Name">
                          <Form.Control
                            type="text"
                            maxLength={364}
                            placeholder="Name"
                            value={PropertySave.ODSName}
                            onChange={(e) =>
                              setPropertySave({
                                ...PropertySave,
                                ["ODSName"]: e.target.value,
                              })
                            }
                          />
                        </FloatingLabel>
                      </Col>
                    </Row>
                    <Row className="m-0 mb-2">
                      <Col xs={3} className="mb-3 p-0">
                        <FloatingLabel controlId="floatingInput" label="C_Code">
                          <Form.Control
                            type="text"
                            maxLength={364}
                            placeholder="C_Code"
                            value={PropertySave.ODSCountryCode}
                            onChange={(e) =>
                              setPropertySave({
                                ...PropertySave,
                                ["ODSCountryCode"]: e.target.value,
                              })
                            }
                          />
                        </FloatingLabel>
                      </Col>
                      <Col xs={6} className="mb-3">
                        <div className={`${PropertySave.errors.ODSPhone && "red-border-masking"}`}>
                          <FloatingLabel controlId="floatingInput" label="Phone">
                            <PatternFormat
                              className={`form-control ${
                                PropertySave.errors.ODSPhone && "is-invalid"
                              }`}
                              displayType="input"
                              placeholder="Phone"
                              value={PropertySave.ODSPhone}
                              format="###-###-####"
                              onChange={(e) =>
                                setPropertySave({
                                  ...PropertySave,
                                  ["ODSPhone"]: e.target.value,
                                })
                              }
                            />
                          </FloatingLabel>
                        </div>
                        {PropertySave.errors.ODSPhone && (
                          <>
                            <span className="feedbackss">{PropertySave.errors.ODSPhone}</span>
                            <br />
                          </>
                        )}
                      </Col>
                      <Col xs={3} className="mb-3 pe-0">
                        <FloatingLabel controlId="floatingInput" label="Ext">
                          <Form.Control
                            type="text"
                            maxLength={9}
                            placeholder="C_Code"
                            value={PropertySave.ODSExt}
                            onChange={(e) =>
                              setPropertySave({
                                ...PropertySave,
                                ["ODSExt"]: e.target.value,
                              })
                            }
                          />
                        </FloatingLabel>
                      </Col>
                    </Row>
                    <Row className="m-0 mb-2">
                      <Col xs={12} className="mb-3 p-0">
                        <FloatingLabel controlId="floatingInput" label="Email">
                          <Form.Control
                            type="text"
                            maxLength={364}
                            placeholder="Email"
                            value={PropertySave.ODSEmail}
                            onChange={(e) =>
                              setPropertySave({
                                ...PropertySave,
                                ["ODSEmail"]: e.target.value,
                              })
                            }
                            isInvalid={PropertySave.errors.ODSEmail}
                          />
                          {PropertySave.errors.ODSEmail && (
                            <Form.Control.Feedback type="invalid">
                              {PropertySave.errors.ODSEmail}
                            </Form.Control.Feedback>
                          )}
                        </FloatingLabel>
                      </Col>
                    </Row>
                  </Col>
                  {/* Director of sales End */}

                  <Col xs={6} className="mb-2 pe-0">
                    <Row className="m-0 mb-2">
                      <Col xs={12} className="mb-2 p-0">
                        <h6>Catering Manager</h6>
                      </Col>
                    </Row>
                    <Row className="m-0 mb-2">
                      <Col xs={12} className="mb-3 p-0">
                        <FloatingLabel controlId="floatingInput" label="Name">
                          <Form.Control
                            type="text"
                            maxLength={364}
                            placeholder="Name"
                            value={PropertySave.OCMName}
                            onChange={(e) =>
                              setPropertySave({
                                ...PropertySave,
                                ["OCMName"]: e.target.value,
                              })
                            }
                          />
                        </FloatingLabel>
                      </Col>
                    </Row>
                    <Row className="m-0 mb-2">
                      <Col xs={3} className="mb-3 ps-0">
                        <FloatingLabel controlId="floatingInput" label="C_Code">
                          <Form.Control
                            type="text"
                            maxLength={364}
                            placeholder="C_Code"
                            value={PropertySave.OCMCountryCode}
                            onChange={(e) =>
                              setPropertySave({
                                ...PropertySave,
                                ["OCMCountryCode"]: e.target.value,
                              })
                            }
                          />
                        </FloatingLabel>
                      </Col>
                      <Col xs={6} className="mb-3">
                        <div className={`${PropertySave.errors.OCMPhone && "red-border-masking"}`}>
                          <FloatingLabel controlId="floatingInput" label="Phone">
                            <PatternFormat
                              className={`form-control ${
                                PropertySave.errors.OCMPhone && "is-invalid"
                              }`}
                              displayType="input"
                              placeholder="Phone"
                              value={PropertySave.OCMPhone}
                              format="###-###-####"
                              onChange={(e) =>
                                setPropertySave({
                                  ...PropertySave,
                                  ["OCMPhone"]: e.target.value,
                                })
                              }
                            />
                          </FloatingLabel>
                        </div>
                        {PropertySave.errors.OCMPhone && (
                          <>
                            <span className="feedbackss">{PropertySave.errors.OCMPhone}</span>
                            <br />
                          </>
                        )}
                      </Col>
                      <Col xs={3} className="mb-3 pe-0">
                        <FloatingLabel controlId="floatingInput" label="Ext">
                          <Form.Control
                            type="text"
                            maxLength={9}
                            placeholder="C_Code"
                            value={PropertySave.OCMExt}
                            onChange={(e) =>
                              setPropertySave({
                                ...PropertySave,
                                ["OCMExt"]: e.target.value,
                              })
                            }
                          />
                        </FloatingLabel>
                      </Col>
                    </Row>
                    <Row className="m-0 mb-2">
                      <Col xs={12} className="mb-3 p-0">
                        <FloatingLabel controlId="floatingInput" label="Email">
                          <Form.Control
                            type="text"
                            maxLength={364}
                            placeholder="Email"
                            value={PropertySave.OCMEmail}
                            onChange={(e) =>
                              setPropertySave({
                                ...PropertySave,
                                ["OCMEmail"]: e.target.value,
                              })
                            }
                            isInvalid={PropertySave.errors.OCMEmail}
                          />
                          {PropertySave.errors.OCMEmail && (
                            <Form.Control.Feedback type="invalid">
                              {PropertySave.errors.OCMEmail}
                            </Form.Control.Feedback>
                          )}
                        </FloatingLabel>
                      </Col>
                    </Row>
                  </Col>
                  {/* Catering manager End */}
                </Row>

                <Row className="m-0">
                  <Col xs={6} className="mb-2 ps-0">
                    <Row className="m-0 mb-2">
                      <Col xs={12} className="mb-2 p-0">
                        <h6>Sales Manager</h6>
                      </Col>
                    </Row>
                    <Row className="m-0 mb-2">
                      <Col xs={12} className="mb-3 p-0">
                        <FloatingLabel controlId="floatingInput" label="Name">
                          <Form.Control
                            type="text"
                            maxLength={364}
                            placeholder="Name"
                            value={PropertySave.OSMName}
                            onChange={(e) =>
                              setPropertySave({
                                ...PropertySave,
                                ["OSMName"]: e.target.value,
                              })
                            }
                          />
                        </FloatingLabel>
                      </Col>
                    </Row>
                    <Row className="m-0 mb-2">
                      <Col xs={3} className="mb-3 p-0">
                        <FloatingLabel controlId="floatingInput" label="C_Code">
                          <Form.Control
                            type="text"
                            maxLength={365}
                            placeholder="C_Code"
                            value={PropertySave.OSMCountryCode}
                            onChange={(e) =>
                              setPropertySave({
                                ...PropertySave,
                                ["OSMCountryCode"]: e.target.value,
                              })
                            }
                          />
                        </FloatingLabel>
                      </Col>
                      <Col xs={6} className="mb-3">
                        <div className={`${PropertySave.errors.OSMPhone && "red-border-masking"}`}>
                          <FloatingLabel controlId="floatingInput" label="Phone">
                            <PatternFormat
                              className={`form-control ${
                                PropertySave.errors.OSMPhone && "is-invalid"
                              }`}
                              displayType="input"
                              placeholder="Phone"
                              value={PropertySave.OSMPhone}
                              format="###-###-####"
                              onChange={(e) =>
                                setPropertySave({
                                  ...PropertySave,
                                  ["OSMPhone"]: e.target.value,
                                })
                              }
                            />
                          </FloatingLabel>
                        </div>
                        {PropertySave.errors.OSMPhone && (
                          <>
                            <span className="feedbackss">{PropertySave.errors.OSMPhone}</span>
                            <br />
                          </>
                        )}
                      </Col>
                      <Col xs={3} className="mb-3 pe-0">
                        <FloatingLabel controlId="floatingInput" label="Ext">
                          <Form.Control
                            type="text"
                            maxLength={9}
                            placeholder="Ext"
                            value={PropertySave.OSMExt}
                            onChange={(e) =>
                              setPropertySave({
                                ...PropertySave,
                                ["OSMExt"]: e.target.value,
                              })
                            }
                          />
                        </FloatingLabel>
                      </Col>
                    </Row>
                    <Row className="m-0 mb-2">
                      <Col xs={12} className="mb-3 p-0">
                        <FloatingLabel controlId="floatingInput" label="Email">
                          <Form.Control
                            type="text"
                            maxLength={364}
                            placeholder="Email"
                            value={PropertySave.OSMEmail}
                            onChange={(e) =>
                              setPropertySave({
                                ...PropertySave,
                                ["OSMEmail"]: e.target.value,
                              })
                            }
                            isInvalid={PropertySave.errors.OSMEmail}
                          />
                          {PropertySave.errors.OSMEmail && (
                            <Form.Control.Feedback type="invalid">
                              {PropertySave.errors.OSMEmail}
                            </Form.Control.Feedback>
                          )}
                        </FloatingLabel>
                      </Col>
                    </Row>
                  </Col>
                  {/* Sales manager End  */}

                  <Col xs={6} className="mb-2 pe-0">
                    <Row className="m-0 mb-2">
                      <Col xs={12} className="mb-2 p-0">
                        <h6>Revenue Manager</h6>
                      </Col>
                    </Row>
                    <Row className="m-0 mb-2">
                      <Col xs={12} className="mb-3 p-0">
                        <FloatingLabel controlId="floatingInput" label="Name">
                          <Form.Control
                            type="text"
                            maxLength={364}
                            placeholder="Name"
                            value={PropertySave.ORMName}
                            onChange={(e) =>
                              setPropertySave({
                                ...PropertySave,
                                ["ORMName"]: e.target.value,
                              })
                            }
                          />
                        </FloatingLabel>
                      </Col>
                    </Row>
                    <Row className="m-0 mb-2">
                      <Col xs={3} className="mb-3 ps-0">
                        <FloatingLabel controlId="floatingInput" label="C_Code">
                          <Form.Control
                            type="text"
                            maxLength={364}
                            placeholder="C_Code"
                            value={PropertySave.ORMCountryCode}
                            onChange={(e) =>
                              setPropertySave({
                                ...PropertySave,
                                ["ORMCountryCode"]: e.target.value,
                              })
                            }
                          />
                        </FloatingLabel>
                      </Col>
                      <Col xs={6} className="mb-3">
                        <div className={`${PropertySave.errors.ORMPhone && "red-border-masking"}`}>
                          <FloatingLabel controlId="floatingInput" label="Phone">
                            <PatternFormat
                              className={`form-control ${
                                PropertySave.errors.ORMPhone && "is-invalid"
                              }`}
                              displayType="input"
                              placeholder="Phone"
                              value={PropertySave.ORMPhone}
                              format="###-###-####"
                              onChange={(e) =>
                                setPropertySave({
                                  ...PropertySave,
                                  ["ORMPhone"]: e.target.value,
                                })
                              }
                            />
                          </FloatingLabel>
                        </div>
                        {PropertySave.errors.ORMPhone && (
                          <>
                            <span className="feedbackss">{PropertySave.errors.ORMPhone}</span>
                            <br />
                          </>
                        )}
                      </Col>
                      <Col xs={3} className="mb-3 pe-0">
                        <FloatingLabel controlId="floatingInput" label="Ext">
                          <Form.Control
                            type="text"
                            maxLength={9}
                            placeholder="Ext"
                            value={PropertySave.ORMExt}
                            onChange={(e) =>
                              setPropertySave({
                                ...PropertySave,
                                ["ORMExt"]: e.target.value,
                              })
                            }
                          />
                        </FloatingLabel>
                      </Col>
                    </Row>
                    <Row className="m-0 mb-2">
                      <Col xs={12} className="mb-3 p-0">
                        <FloatingLabel controlId="floatingInput" label="Email">
                          <Form.Control
                            type="text"
                            maxLength={364}
                            placeholder="Email"
                            value={PropertySave.ORMEmail}
                            onChange={(e) =>
                              setPropertySave({
                                ...PropertySave,
                                ["ORMEmail"]: e.target.value,
                              })
                            }
                            isInvalid={PropertySave.errors.ORMEmail}
                          />
                          {PropertySave.errors.ORMEmail && (
                            <Form.Control.Feedback type="invalid">
                              {PropertySave.errors.ORMEmail}
                            </Form.Control.Feedback>
                          )}
                        </FloatingLabel>
                      </Col>
                    </Row>
                  </Col>
                  {/* Revenue Manager End */}
                </Row>
                <Row className="m-0">
                  <Col xs={6} className="mb-2 ps-0">
                    <Row className="m-0 mb-2">
                      <Col xs={12} className="mb-2 p-0">
                        <h6>Regional</h6>
                      </Col>
                    </Row>
                    <Row className="m-0 mb-2">
                      <Col xs={12} className="mb-3 p-0">
                        <FloatingLabel controlId="floatingInput" label="Name">
                          <Form.Control
                            type="text"
                            maxLength={364}
                            placeholder="Name"
                            value={PropertySave.OREName}
                            onChange={(e) =>
                              setPropertySave({
                                ...PropertySave,
                                ["OREName"]: e.target.value,
                              })
                            }
                          />
                        </FloatingLabel>
                      </Col>
                    </Row>
                    <Row className="m-0 mb-2">
                      <Col xs={3} className="mb-3 p-0">
                        <FloatingLabel controlId="floatingInput" label="C_Code">
                          <Form.Control
                            type="text"
                            maxLength={364}
                            placeholder="C_Code"
                            value={PropertySave.ORECountryCode}
                            onChange={(e) =>
                              setPropertySave({
                                ...PropertySave,
                                ["ORECountryCode"]: e.target.value,
                              })
                            }
                          />
                        </FloatingLabel>
                      </Col>
                      <Col xs={6} className="mb-3">
                        <div className={`${PropertySave.errors.OREPhone && "red-border-masking"}`}>
                          <FloatingLabel controlId="floatingInput" label="Phone">
                            <PatternFormat
                              className={`form-control ${
                                PropertySave.errors.OREPhone && "is-invalid"
                              }`}
                              placeholder="Phone"
                              displayType="input"
                              value={PropertySave.OREPhone}
                              format="###-###-####"
                              onChange={(e) =>
                                setPropertySave({
                                  ...PropertySave,
                                  ["OREPhone"]: e.target.value,
                                })
                              }
                            />
                          </FloatingLabel>
                        </div>
                        {PropertySave.errors.OREPhone && (
                          <>
                            <span className="feedbackss">{PropertySave.errors.OREPhone}</span>
                            <br />
                          </>
                        )}
                      </Col>
                      <Col xs={3} className="mb-3 pe-0">
                        <FloatingLabel controlId="floatingInput" label="Ext">
                          <Form.Control
                            type="text"
                            maxLength={9}
                            placeholder="Ext"
                            value={PropertySave.OREExt}
                            onChange={(e) =>
                              setPropertySave({
                                ...PropertySave,
                                ["OREExt"]: e.target.value,
                              })
                            }
                          />
                        </FloatingLabel>
                      </Col>
                    </Row>
                    <Row className="m-0 mb-2">
                      <Col xs={12} className="mb-3 p-0">
                        <FloatingLabel controlId="floatingInput" label="Email">
                          <Form.Control
                            type="text"
                            maxLength={364}
                            placeholder="Email"
                            value={PropertySave.OREEmail}
                            onChange={(e) =>
                              setPropertySave({
                                ...PropertySave,
                                ["OREEmail"]: e.target.value,
                              })
                            }
                            isInvalid={PropertySave.errors.OREEmail}
                          />
                          {PropertySave.errors.OREEmail && (
                            <Form.Control.Feedback type="invalid">
                              {PropertySave.errors.OREEmail}
                            </Form.Control.Feedback>
                          )}
                        </FloatingLabel>
                      </Col>
                    </Row>
                  </Col>
                  {/* Regional end */}

                  <Col xs={6} className="mb-2 pe-0">
                    <Row className="m-0 mb-2">
                      <Col xs={12} className="mb-2 p-0">
                        <h6>Regional Sales</h6>
                      </Col>
                    </Row>
                    <Row className="m-0 mb-2">
                      <Col xs={12} className="mb-3 p-0">
                        <FloatingLabel controlId="floatingInput" label="Name">
                          <Form.Control
                            type="text"
                            maxLength={364}
                            placeholder="Name"
                            value={PropertySave.ORSName}
                            onChange={(e) =>
                              setPropertySave({
                                ...PropertySave,
                                ["ORSName"]: e.target.value,
                              })
                            }
                          />
                        </FloatingLabel>
                      </Col>
                    </Row>
                    <Row className="m-0 mb-2">
                      <Col xs={3} className="mb-3 ps-0">
                        <FloatingLabel controlId="floatingInput" label="C_Code">
                          <Form.Control
                            type="text"
                            maxLength={364}
                            placeholder="C_Code"
                            value={PropertySave.ORSCountryCode}
                            onChange={(e) =>
                              setPropertySave({
                                ...PropertySave,
                                ["ORSCountryCode"]: e.target.value,
                              })
                            }
                          />
                        </FloatingLabel>
                      </Col>
                      <Col xs={6} className="mb-3">
                        <div className={`${PropertySave.errors.ORSPhone && "red-border-masking"}`}>
                          <FloatingLabel controlId="floatingInput" label="Phone">
                            <PatternFormat
                              className={`form-control ${
                                PropertySave.errors.ORSPhone && "is-invalid"
                              }`}
                              displayType="input"
                              placeholder="Phone"
                              value={PropertySave.ORSPhone}
                              format="###-###-####"
                              onChange={(e) =>
                                setPropertySave({
                                  ...PropertySave,
                                  ["ORSPhone"]: e.target.value,
                                })
                              }
                            />
                          </FloatingLabel>
                        </div>
                        {PropertySave.errors.ORSPhone && (
                          <>
                            <span className="feedbackss">{PropertySave.errors.ORSPhone}</span>
                            <br />
                          </>
                        )}
                      </Col>
                      <Col xs={3} className="mb-3 pe-0">
                        <FloatingLabel controlId="floatingInput" label="Ext">
                          <Form.Control
                            type="text"
                            maxLength={9}
                            placeholder="Ext"
                            value={PropertySave.ORSExt}
                            onChange={(e) =>
                              setPropertySave({
                                ...PropertySave,
                                ["ORSExt"]: e.target.value,
                              })
                            }
                          />
                        </FloatingLabel>
                      </Col>
                    </Row>
                    <Row className="m-0 mb-2">
                      <Col xs={12} className="mb-3 p-0">
                        <FloatingLabel controlId="floatingInput" label="Email">
                          <Form.Control
                            type="text"
                            maxLength={364}
                            placeholder="Email"
                            value={PropertySave.ORSEmail}
                            onChange={(e) =>
                              setPropertySave({
                                ...PropertySave,
                                ["ORSEmail"]: e.target.value,
                              })
                            }
                            isInvalid={PropertySave.errors.ORSEmail}
                          />
                          {PropertySave.errors.ORSEmail && (
                            <Form.Control.Feedback type="invalid">
                              {PropertySave.errors.ORSEmail}
                            </Form.Control.Feedback>
                          )}
                        </FloatingLabel>
                      </Col>
                    </Row>
                  </Col>
                  {/* Regional Sales End */}
                </Row>
              </Container>
            </Tab>
            <Tab eventKey="tab4" title="Address">
              <Container fluid="true" className="p-0">
                <Row className="mb-2">
                  <Col xs={12} className="mb-2">
                    <h6>Address</h6>
                  </Col>
                </Row>
                <Row className=" mb-2">
                  <Col xs={6} className="mb-2">
                    <FloatingLabel controlId="floatingInput" label="Street">
                      <Form.Control
                        type="text"
                        maxLength={360}
                        placeholder="Street"
                        value={PropertySave.AStreet}
                        onChange={(e) =>
                          setPropertySave({
                            ...PropertySave,
                            ["AStreet"]: e.target.value,
                          })
                        }
                      />
                    </FloatingLabel>
                  </Col>
                  <Col xs={6} className="mb-2 fild-input">
                    <div>
                      <FloatingLabel controlId="floatingInput" label="">
                        <CreatableSelect
                          placeholder={"Country"}
                          isClearable
                          options={CountryDropdown}
                          onChange={(event: any) => {
                            setPropertySave({
                              ...PropertySave,
                              ["ACountry"]: event !== null ? event.value : "",
                            });
                          }}
                          value={
                            PropertySave.ACountry !== ""
                              ? {
                                  label: PropertySave.ACountry,
                                  value: PropertySave.ACountry,
                                }
                              : {
                                  label: "Country",
                                  value: "Country",
                                }
                          }
                        />
                      </FloatingLabel>
                    </div>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col xs={6} className="mb-2">
                    <FloatingLabel controlId="floatingInput" label="City">
                      <Form.Control
                        type="text"
                        maxLength={364}
                        placeholder="City"
                        value={PropertySave.ACity}
                        onChange={(e) =>
                          setPropertySave({
                            ...PropertySave,
                            ["ACity"]: e.target.value,
                          })
                        }
                      />
                    </FloatingLabel>
                  </Col>
                  <Col xs={6} className="mb-2">
                    <Row>
                      <Col xs={3} className="mb-2">
                        <FloatingLabel controlId="floatingInput" label="C_Code">
                          <Form.Control
                            type="text"
                            maxLength={66}
                            placeholder="C_Code"
                            value={PropertySave.ACountryCode}
                            onChange={(e) =>
                              setPropertySave({
                                ...PropertySave,
                                ["ACountryCode"]: e.target.value,
                              })
                            }
                          />
                        </FloatingLabel>
                      </Col>
                      <Col xs={6} className="mb-2">
                        <FloatingLabel controlId="floatingInput" label="">
                          <div
                            className={`${PropertySave.errors.APhoneno && "red-border-masking"}`}
                          >
                            <FloatingLabel controlId="floatingInput" label="Phone">
                              <PatternFormat
                                className={`form-control ${
                                  PropertySave.errors.APhoneno && "is-invalid"
                                }`}
                                displayType="input"
                                placeholder="Phone"
                                value={PropertySave.APhoneno}
                                format="###-###-####"
                                onChange={(e) =>
                                  setPropertySave({
                                    ...PropertySave,
                                    ["APhoneno"]: e.target.value,
                                  })
                                }
                              />
                            </FloatingLabel>
                          </div>
                          {PropertySave.errors.APhoneno && (
                            <>
                              <span className="feedbackss">{PropertySave.errors.APhoneno}</span>
                              <br />
                            </>
                          )}
                        </FloatingLabel>
                      </Col>
                      <Col xs={3} className="mb-2">
                        <FloatingLabel controlId="floatingInput" label="Ext">
                          <Form.Control
                            type="text"
                            maxLength={66}
                            placeholder="C_Code"
                            value={PropertySave.AExt}
                            onChange={(e) =>
                              setPropertySave({
                                ...PropertySave,
                                ["AExt"]: e.target.value,
                              })
                            }
                          />
                        </FloatingLabel>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col xs={6} className="mb-2 fild-input">
                    <div>
                      <FloatingLabel controlId="floatingInput" label="">
                        <CreatableSelect
                          isClearable
                          options={StateDropdown}
                          onChange={(event: any) => {
                            setPropertySave({
                              ...PropertySave,
                              ["AState"]: event !== null ? event.value : "",
                            });
                          }}
                          placeholder="State"
                          value={
                            PropertySave.AState !== ""
                              ? {
                                  label: PropertySave.AState,
                                  value: PropertySave.AState,
                                }
                              : {
                                  label: "State",
                                  value: "State",
                                }
                          }
                        />
                      </FloatingLabel>
                    </div>
                  </Col>
                  <Col xs={6} className="mb-2">
                    <FloatingLabel controlId="floatingInput" label="Zip Code">
                      <Form.Control
                        type="text"
                        maxLength={12}
                        placeholder="Zip Code"
                        value={PropertySave.AZipCode}
                        onChange={(e) =>
                          setPropertySave({
                            ...PropertySave,
                            ["AZipCode"]: e.target.value,
                          })
                        }
                      />
                    </FloatingLabel>
                  </Col>
                </Row>
                <Row className=" mb-2">
                  <Col xs={12} className="mb-2">
                    <h6>Address</h6>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6} className="mb-2">
                    <FloatingLabel
                      className="report-email"
                      controlId="floatingInput"
                      label="Report Email to"
                    >
                      <TagsInput
                        inputProps={{
                          placeholder: "",
                        }}
                        className="tag-box react-tagsinput"
                        maxTags={10}
                        value={tags}
                        onChange={handleChange}
                      />
                    </FloatingLabel>
                  </Col>
                </Row>
              </Container>
            </Tab>
            <Tab
              eventKey="tab5"
              title="Tax/Fees"
              tabClassName={`${validateTaxFees === false && "validate-tab"}`}
            >
              {!loading ? (
                <div className="tax-information">
                  <Table className="table-without-space mb-0" bordered>
                    <thead>
                      <tr>
                        <th style={{ width: "60%" }} className="settitle">
                          Tax
                        </th>
                        <th style={{ width: "10%" }} className="settitle">
                          Type ($/%)
                        </th>
                        <th style={{ width: "20%", textAlign: "center" }} className="settitle">
                          Value
                        </th>
                        <th style={{ width: "10%" }} className="settitle">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {inputTaxHotelList.map((x, i) => {
                        return (
                          <>
                            <tr>
                              <td className="">
                                <CreatableSelect
                                  maxMenuHeight={80}
                                  placeholder={"Select Tax"}
                                  isClearable
                                  options={optionsTax}
                                  onChange={(event: any) => handleInputRoomChangeTab(event, i, x)}
                                  className={`creatableSpace ${
                                    inputTaxHotelListEr &&
                                    inputTaxHotelListEr[i] &&
                                    inputTaxHotelListEr[i].TaxTitle !== ""
                                      ? "red-border-masking ClassSelectCreatable"
                                      : x.TaxId !== null &&
                                        x.TaxId !== "" &&
                                        x.TaxId !== undefined &&
                                        "ClassSelectCreatable ptb-2 disabledCreateSelect"
                                  }`}
                                  value={
                                    x.TaxTitle !== ""
                                      ? {
                                          label: x.TaxTitle,
                                          value: x.TaxTitle,
                                        }
                                      : {
                                          label: "Select Tax",
                                          value: "Select Tax",
                                        }
                                  }
                                  isDisabled={
                                    x.TaxId !== null && x.TaxId !== "" && x.TaxId !== undefined
                                      ? true
                                      : false
                                  }
                                />
                              </td>
                              <td>
                                <Form.Select
                                  aria-label="Default select example"
                                  style={{ minWidth: "0", padding: "2" }}
                                  name="IsPercentage"
                                  onChange={(e) => handleInputRoomChangeTab(e, i, x)}
                                  className="ps-2 is_bordr_class"
                                >
                                  <option selected={x.IsPercentage === true && "Yes"} value="Yes">
                                    %
                                  </option>
                                  <option selected={x.IsPercentage === false && "No"} value="No">
                                    $
                                  </option>
                                </Form.Select>
                              </td>
                              <td className="bordernone">
                                <Form.Group>
                                  <Form.Control
                                    type="text"
                                    value={x.RateOrPercentage}
                                    maxLength={256}
                                    name="RateOrPercentage"
                                    onChange={(e) => handleInputRoomChangeTab(e, i, x)}
                                    onKeyPress={(e) => CheckKeyEvent(e)}
                                    className={`lstProperyTaxes[${i}].RateOrPercentage ${
                                      inputTaxHotelListEr &&
                                      inputTaxHotelListEr[i] &&
                                      inputTaxHotelListEr[i].RateOrPercentage !== ""
                                        ? "red-border-masking "
                                        : ""
                                    }`}
                                  />
                                </Form.Group>
                              </td>
                              <td>
                                <span
                                  className={`btn-icon-grp-table justify-content-center ${
                                    x.TaxId !== null &&
                                    x.TaxId !== "" &&
                                    x.TaxId !== undefined &&
                                    ""
                                  }`}
                                >
                                  <Button
                                    className="btn-icon cancelIcon"
                                    onClick={() => RemoveTaxRow(i)}
                                  ></Button>
                                </span>
                              </td>
                            </tr>
                          </>
                        );
                      })}

                      <tr>
                        <td colSpan={4} className="bluetext">
                          <div
                            role="button"
                            tabIndex={-1}
                            onClick={handleAddROOMRowClickTab}
                            onKeyDown={handleAddROOMRowClickTab}
                          >
                            <img src={SquarePlus} alt="" width={15} height={15} className="me-1" />
                            Add More
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              ) : (
                <PageLoader />
              )}
            </Tab>
            <Tab
              eventKey="tab6"
              title="Meeting Rooms"
              tabClassName={`${ValidMeetingRoom === true && "validate-tab"}`}
            >
              {!loading ? (
                <Container fluid="true" className="full-height-tab">
                  <Row className="mb-2">
                    <Col xs={12} className="mb-2">
                      <h6>Meeting Rooms</h6>
                    </Col>
                  </Row>

                  <Row>
                    <div className="col-md-12 customtablemetting mt-3 remove-res-scroll">
                      <Table responsive bordered className="table-without-space">
                        <thead>
                          <tr className="h-35">
                            <th colSpan={3}>
                              <div
                                role="button"
                                tabIndex={-1}
                                onClick={() => MeetingRoomTable_AddNewCol()}
                                onKeyDown={() => MeetingRoomTable_AddNewCol()}
                              >
                                <img
                                  src={SquarePlus}
                                  alt=""
                                  width={15}
                                  height={15}
                                  className="me-1"
                                />
                                Add RoomSetup Column
                              </div>
                            </th>
                            <th
                              colSpan={
                                // 7 -3 +1
                                MeetingRoomTable !== null &&
                                MeetingRoomTable !== undefined &&
                                MeetingRoomTable.length > 0 &&
                                Object.keys(MeetingRoomTable) !== null &&
                                Object.keys(MeetingRoomTable) !== undefined &&
                                Object.keys(MeetingRoomTable[0]) !== null &&
                                Object.keys(MeetingRoomTable[0]) !== undefined &&
                                Object.keys(MeetingRoomTable[0]).length !== null &&
                                Object.keys(MeetingRoomTable[0]).length - 3 + 1
                              }
                            >
                              Setup
                            </th>
                          </tr>
                          <tr className="h-35">
                            <th>
                              {MeetingRoomTable &&
                                MeetingRoomTable[0] &&
                                MeetingRoomTable[0]["Col0"]}
                            </th>

                            <th>
                              {MeetingRoomTable &&
                                MeetingRoomTable[0] &&
                                MeetingRoomTable[0]["Col1"]}
                            </th>
                            <th>
                              {MeetingRoomTable &&
                                MeetingRoomTable[0] &&
                                MeetingRoomTable[0]["Col2"]}
                            </th>

                            {MeetingRoomTable &&
                              MeetingRoomTable[0] &&
                              MeetingRoomTable[0] &&
                              Object.keys(MeetingRoomTable[0]).map(
                                (row, index) =>
                                  index > 3 && (
                                    <th className="w-200">
                                      <div className="d-flex align-items-center">
                                        <div
                                          className="table-create-data"
                                          title={
                                            MeetingRoomTable[0][row] !== ""
                                              ? MeetingRoomTable[0][row]
                                              : "Select Arrangement Type"
                                          }
                                        >
                                          <CreatableSelect
                                            placeholder={"Select Rooms"}
                                            isClearable
                                            options={itemsNew}
                                            id={row + "_" + index}
                                            onChange={(e: any) => {
                                              if (e !== null) {
                                                let lst = [...MeetingRoomTable];
                                                lst[0][row] = e.value;
                                                setMeetingRoomTable(lst);
                                              } else {
                                                let lst = [...MeetingRoomTable];
                                                lst[0][row] = "Select Arrangement Type";
                                                setMeetingRoomTable(lst);
                                              }
                                            }}
                                            value={
                                              MeetingRoomTable[0][row] !== ""
                                                ? {
                                                    label: MeetingRoomTable[0][row],
                                                    value: MeetingRoomTable[0][row],
                                                  }
                                                : {
                                                    label: "Select Arrangement Type",
                                                    value: "Select Arrangement Type",
                                                  }
                                            }
                                          />
                                        </div>
                                        <span className="btn-icon-grp-table justify-content-end">
                                          <Button
                                            className="btn-icon cancelIcon"
                                            onClick={() => MeetingRoomTable_RemoveColumn(row)}
                                          ></Button>
                                        </span>
                                      </div>
                                    </th>
                                  )
                              )}
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {MeetingRoomTable &&
                            MeetingRoomTable.map(
                              (Row, RowIndex) =>
                                RowIndex > 0 && (
                                  <tr key={RowIndex}>
                                    <>
                                      {Object.keys(Row).map(
                                        (Col, ColIndex) =>
                                          Col !== "Col3" && (
                                            <td
                                              key={ColIndex}
                                              className={`w-20 ${Col === "Col1" && "selectTdNew"}`}
                                            >
                                              {Col === "Col0" && (
                                                <Form.Control
                                                  type="text"
                                                  maxLength={364}
                                                  name={"MeetingRoom_" + RowIndex + "_" + Col}
                                                  id={"MeetingRoom_" + RowIndex + "_" + Col}
                                                  value={Row[Col]}
                                                  onChange={(e) => {
                                                    UpdatedoptionsAssociation.push(e.target.value);
                                                    RemoveOrCheckAssociation(
                                                      e.target.value,
                                                      e,
                                                      RowIndex,
                                                      Col
                                                    );
                                                  }}
                                                />
                                              )}

                                              {Col === "Col1" && (
                                                <Select
                                                  placeholder="Select Association"
                                                  name={"Association_" + RowIndex + "_" + Col}
                                                  options={optionsAssociation}
                                                  id={"Association_" + RowIndex + "_" + Col}
                                                  className="mySelectItem"
                                                  onChange={(e: any) =>
                                                    SelectedAssociationRoom(e, RowIndex, Col)
                                                  }
                                                  defaultValue={optionsAssociation.filter(
                                                    (obj) =>
                                                      MeetingRoomTable[RowIndex][Col] &&
                                                      MeetingRoomTable[RowIndex][Col].includes(
                                                        obj.label
                                                      )
                                                  )}
                                                  isMulti
                                                />
                                              )}

                                              {Col === "Col2" && (
                                                <Form.Control
                                                  type="text"
                                                  name={"SquareFeet_" + RowIndex + "_" + Col}
                                                  value={Row[Col]}
                                                  onChange={(e) => {
                                                    let lst = [...MeetingRoomTable];

                                                    lst[RowIndex][Col] = e.target.value;
                                                    setMeetingRoomTable(lst);
                                                  }}
                                                />
                                              )}

                                              {Col !== "Col0" &&
                                                Col !== "Col1" &&
                                                Col !== "Col2" &&
                                                Col !== "Col3" && (
                                                  <Form.Control
                                                    type="number"
                                                    onWheel={(event: any) => {
                                                      event.target.blur();
                                                    }}
                                                    onKeyDown={(e: any) => {
                                                      if (e.keyCode == "38" || e.keyCode == "40") {
                                                        e.stopPropagation();
                                                        e.preventDefault();
                                                      }
                                                    }}
                                                    value={
                                                      !isNaN(parseInt(Row[Col]))
                                                        ? parseInt(Row[Col])
                                                        : 0
                                                    }
                                                    name={"DataCount_" + RowIndex + "_" + Col}
                                                    onChange={(e) => {
                                                      let lst = [...MeetingRoomTable];
                                                      lst[RowIndex][Col] = e.target.value;
                                                      setMeetingRoomTable(lst);
                                                    }}
                                                  />
                                                )}
                                            </td>
                                          )
                                      )}
                                      <td>
                                        <span className="btn-icon-grp-table justify-content-end">
                                          <Button
                                            className="btn-icon cancelIcon"
                                            onClick={() =>
                                              MeetingRoomTable_RemoveRow(Row, RowIndex)
                                            }
                                          ></Button>
                                        </span>
                                      </td>
                                    </>
                                  </tr>
                                )
                            )}
                        </tbody>
                        <tfoot>
                          <th colSpan={3}>
                            <div
                              role="button"
                              tabIndex={-1}
                              onClick={() => MeetingRoomTable_AddNewRow()}
                              onKeyDown={() => MeetingRoomTable_AddNewRow()}
                            >
                              <img
                                src={SquarePlus}
                                alt=""
                                width={15}
                                height={15}
                                className="me-1"
                              />
                              Add New Row
                            </div>
                          </th>
                          <th
                            colSpan={
                              MeetingRoomTable !== null &&
                              MeetingRoomTable !== undefined &&
                              MeetingRoomTable.length > 0 &&
                              Object.keys(MeetingRoomTable) !== null &&
                              Object.keys(MeetingRoomTable) !== undefined &&
                              Object.keys(MeetingRoomTable[0]) !== null &&
                              Object.keys(MeetingRoomTable[0]) !== undefined &&
                              Object.keys(MeetingRoomTable[0]).length !== null &&
                              Object.keys(MeetingRoomTable[0]).length - 4
                            }
                          ></th>
                          <th></th>
                        </tfoot>
                      </Table>
                    </div>
                  </Row>
                  {/* Tax Table Start */}
                  <br />
                  <h6>Tax/Fees</h6>
                  <div className="tax-information customtablemetting">
                    <Table className="table-without-space mb-0" responsive bordered>
                      <thead>
                        <tr>
                          <th style={{ width: "10%", textAlign: "center" }} className="settitle">
                            Apply?
                          </th>
                          <th style={{ width: "60%" }} className="settitle">
                            Tax
                          </th>
                          <th style={{ width: "10%" }} className="settitle">
                            Type ($/%)
                          </th>
                          <th style={{ textAlign: "center", width: "10%" }} className="settitle">
                            Value
                          </th>
                          <th style={{ textAlign: "center", width: "10%" }} className="settitle">
                            Order No.
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {inputMeetingRoomTaxList111 &&
                          inputMeetingRoomTaxList111.map((x1, i) => (
                            // return (
                            <>
                              <tr key={i}>
                                <td className="" style={{ textAlign: "center" }}>
                                  <input
                                    style={{ textAlign: "center" }}
                                    type="checkbox"
                                    id={i}
                                    onChange={(e) => MeetingRoomTaxCheckboxOnchange(e, x1, i)}
                                    checked={x1.IsApply}
                                  ></input>
                                </td>
                                <td>{x1.TaxTitle}</td>
                                <td className="bordernone" style={{ textAlign: "center" }}>
                                  {x1.IsPercentage === false ? "$" : "%"}
                                </td>
                                <td className="bordernone" style={{ textAlign: "center" }}>
                                  <Form.Control
                                    type="number"
                                    style={{ textAlign: "center" }}
                                    onWheel={(event: any) => {
                                      event.target.blur();
                                    }}
                                    onKeyDown={(e: any) => {
                                      if (e.keyCode == "38" || e.keyCode == "40") {
                                        e.stopPropagation();
                                        e.preventDefault();
                                      }
                                    }}
                                    value={x1.RateOrPercentage}
                                    onChange={(e) => {
                                      let Mlist: any = [...inputMeetingRoomTaxList111];
                                      Mlist[i].RateOrPercentage = e.target.value;
                                      setInputMeetingRoomTaxList111(Mlist);

                                      if (
                                        props.EditPropertiesID !== undefined &&
                                        props.EditPropertiesID !== null &&
                                        props.EditPropertiesID !== ""
                                      ) {
                                        setPropertySave({
                                          ...PropertySave,
                                          ["IsTaxChange"]: true,
                                        });
                                      }
                                    }}
                                  />
                                </td>
                                <td className="bordernone" style={{ textAlign: "center" }}>
                                  <Form.Control
                                    type="number"
                                    style={{ textAlign: "center" }}
                                    onWheel={(event: any) => {
                                      event.target.blur();
                                    }}
                                    onKeyDown={(e: any) => {
                                      if (e.keyCode == "38" || e.keyCode == "40") {
                                        e.stopPropagation();
                                        e.preventDefault();
                                      }
                                    }}
                                    value={x1.OrderNo}
                                    onChange={(e) => {
                                      let Mlist: any = [...inputMeetingRoomTaxList111];
                                      Mlist[i].OrderNo = e.target.value === "" ? 0 : e.target.value;
                                      setInputMeetingRoomTaxList111(Mlist);

                                      if (
                                        props.EditPropertiesID !== undefined &&
                                        props.EditPropertiesID !== null &&
                                        props.EditPropertiesID !== ""
                                      ) {
                                        setPropertySave({
                                          ...PropertySave,
                                          ["IsTaxChange"]: true,
                                        });
                                      }
                                    }}
                                  />
                                </td>
                              </tr>
                            </>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                  {/* Tax Table End */}
                </Container>
              ) : (
                <PageLoader />
              )}
            </Tab>
            <Tab
              eventKey="tab7"
              title="Guest Rooms"
              tabClassName={`${ValidRoomTypes === false && "validate-tab"}`}
            >
              {!loading ? (
                <Container fluid="true" className="full-height-tab">
                  <Row className="">
                    <Col xs={12} className="mb-2">
                      <h6>Room Types</h6>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <div className="col-md-12 customtablemetting mt-1">
                      <Table className="table-without-space w-50" bordered>
                        <thead>
                          <tr>
                            <td className="highlighttext w-50">Room Type</td>
                            <td className="highlighttext w-50">Quantity</td>
                            <td className="highlighttext w-50">Action</td>
                          </tr>
                        </thead>
                        <tbody>
                          {inputList.map((x: any, i) => (
                            <>
                              <tr>
                                <td className="w-300">
                                  <div>
                                    <CreatableSelect
                                      className={`lstPropertiesRoomTypes[${i}].RoomType`}
                                      placeholder={"Select Rooms"}
                                      isClearable
                                      options={RoomTypesDropdown}
                                      onCreateOption={(event) => handleInputRoomCreatable(event, i)}
                                      onChange={(event: any) => handleInputRoomSelect(event, i)}
                                      id={"RoomType_" + i}
                                      value={
                                        x.roomType !== ""
                                          ? {
                                              label: x.roomType,
                                              value: x.roomType,
                                            }
                                          : {
                                              label: "Select Rooms",
                                              value: "Select Rooms",
                                            }
                                      }
                                    />
                                  </div>
                                </td>
                                <td className="bordernone">
                                  <Form.Group>
                                    <Form.Control
                                      className={`lstPropertiesRoomTypes[${i}].Quantity`}
                                      type="number"
                                      maxLength={256}
                                      value={x.quantity}
                                      name="quantity"
                                      onChange={(e) => handleInputRoomChange(e, i)}
                                    />
                                  </Form.Group>
                                </td>

                                <td>
                                  <span className="btn-icon-grp-table justify-content-end">
                                    <Button
                                      className="btn-icon cancelIcon"
                                      onClick={() => RemoveRoomTypesRow(i)}
                                    ></Button>
                                  </span>
                                </td>
                              </tr>
                            </>
                          ))}
                          <tr>
                            <td colSpan={4} className="bluetext">
                              <div
                                role="button"
                                tabIndex={-1}
                                onClick={handleAddROOMRowClick}
                                onKeyDown={handleAddROOMRowClick}
                              >
                                <img
                                  src={SquarePlus}
                                  alt=""
                                  width={15}
                                  height={15}
                                  className="me-1"
                                />
                                Add More
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </Row>

                  {/* Tax Table Start */}
                  <br />
                  <h6>Tax/Fees</h6>
                  <div className="tax-information customtablemetting">
                    <Table className="table-without-space mb-0" responsive bordered>
                      <thead>
                        <tr>
                          <th style={{ width: "10%", textAlign: "center" }} className="settitle">
                            Apply?
                          </th>
                          <th style={{ width: "60%" }} className="settitle">
                            Tax
                          </th>
                          <th style={{ width: "10%" }} className="settitle">
                            Type ($/%)
                          </th>
                          <th style={{ textAlign: "center", width: "10%" }} className="settitle">
                            Value
                          </th>
                          <th style={{ textAlign: "center", width: "10%" }} className="settitle">
                            Order No.
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {inputGuestRoomTaxList.map((y, i) => (
                          <>
                            <tr key={i}>
                              <td className="" style={{ textAlign: "center" }}>
                                <input
                                  style={{ textAlign: "center" }}
                                  type="checkbox"
                                  checked={y.IsApply}
                                  onChange={(e) => GuestRoomTaxCheckboxOnchange(e, y, i)}
                                ></input>
                              </td>
                              <td>{y.TaxTitle}</td>
                              <td className="bordernone" style={{ textAlign: "center" }}>
                                {y.IsPercentage === false ? "$" : "%"}
                              </td>
                              <td className="bordernone" style={{ textAlign: "center" }}>
                                <input
                                  type="number"
                                  onWheel={(event: any) => {
                                    event.target.blur();
                                  }}
                                  onKeyDown={(e: any) => {
                                    if (e.keyCode == "38" || e.keyCode == "40") {
                                      e.stopPropagation();
                                      e.preventDefault();
                                    }
                                  }}
                                  style={{ textAlign: "center" }}
                                  onChange={(e) => GuestRoomTaxOnchange(e, y, i)}
                                  value={y.RateOrPercentage}
                                />{" "}
                              </td>
                              <td className="bordernone" style={{ textAlign: "center" }}>
                                <Form.Control
                                  type="number"
                                  style={{ textAlign: "center" }}
                                  onWheel={(event: any) => {
                                    event.target.blur();
                                  }}
                                  onKeyDown={(e: any) => {
                                    if (e.keyCode == "38" || e.keyCode == "40") {
                                      e.stopPropagation();
                                      e.preventDefault();
                                    }
                                  }}
                                  value={y.OrderNo}
                                  onChange={(e) => {
                                    let Glist: any = [...inputGuestRoomTaxList];
                                    Glist[i].OrderNo = e.target.value === "" ? 0 : e.target.value;
                                    setInputGuestRoomTaxList(Glist);

                                    if (
                                      props.EditPropertiesID !== undefined &&
                                      props.EditPropertiesID !== null &&
                                      props.EditPropertiesID !== ""
                                    ) {
                                      setPropertySave({
                                        ...PropertySave,
                                        ["IsTaxChange"]: true,
                                      });
                                    }
                                  }}
                                />
                              </td>
                            </tr>
                          </>
                          // );
                        ))}
                      </tbody>
                    </Table>
                  </div>
                  {/* tax table end */}
                </Container>
              ) : (
                <PageLoader />
              )}
            </Tab>

            {/* Another three (Food Beverage Av_Equipment)  Tabs Start */}
            <Tab
              eventKey="tab8"
              title="AV Equipment"
              tabClassName={`${isAvValid === false ? "validate-tab" : ""}`}
            >
              {!loading ? (
                <Container fluid="true" className="full-height-tab">
                  <Row className="">
                    <Col xs={12} className="mb-2">
                      <h6>AV Equipment Details</h6>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <div className="col-md-12 customtablemetting mt-1">
                      <Table bordered className="table-without-space">
                        <thead>
                          <tr className="h-40">
                            <th className="">Equipment Name</th>
                            <th>Type</th>
                            <th>Cost Per Unit($)</th>
                            <th className="text-center">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.keys(avDataRes).map((data_key, i) => {
                            return (
                              <>
                                <tr>
                                  <td>
                                    <input
                                      className={`input-tabal ps-0 pe-0 creatableSpace ${
                                        avDataResEr[data_key] &&
                                        avDataResEr[data_key].avEquipName !== ""
                                          ? "red-border-masking ClassSelectCreatable"
                                          : ""
                                      }`}
                                      type="text"
                                      maxLength={364}
                                      onChange={(e) => {
                                        setAvDataRes({
                                          ...avDataRes,
                                          [data_key]: {
                                            ...avDataRes[data_key],
                                            avEquipName: e.target.value ? e.target.value : "",
                                          },
                                        });
                                      }}
                                      value={avDataRes[data_key].avEquipName}
                                    />
                                  </td>

                                  <td className="w-300">
                                    <div
                                      className={`${
                                        avDataResEr[data_key] &&
                                        avDataResEr[data_key].avEquipType_Term !== ""
                                          ? "red-border-masking"
                                          : ""
                                      }`}
                                    >
                                      <CreatableSelect
                                        isClearable
                                        maxMenuHeight={80}
                                        options={aveTypesList}
                                        onChange={(event: any) => {
                                          setAvDataRes({
                                            ...avDataRes,
                                            [data_key]: {
                                              ...avDataRes[data_key],
                                              avEquipType_Term: event !== null ? event.label : "",
                                            },
                                          });
                                        }}
                                        placeholder="Select Item"
                                        value={
                                          avDataRes[data_key] &&
                                          avDataRes[data_key].avEquipType_Term !== undefined &&
                                          avDataRes[data_key].avEquipType_Term !== ""
                                            ? {
                                                label: avDataRes[data_key].avEquipType_Term,
                                                value: avDataRes[data_key].avEquipType_Term,
                                              }
                                            : {
                                                label: "Select Item",
                                                value: "Select Item",
                                              }
                                        }
                                      />
                                    </div>
                                  </td>
                                  <td className="bg-white">
                                    <input
                                      className={`input-tabal ps-0 pe-0 listAvEquipmentANDMISC[${i}].AVEquipCost`}
                                      type="number"
                                      onWheel={(event: any) => {
                                        event.target.blur();
                                      }}
                                      onKeyDown={(e: any) => {
                                        if (e.keyCode == "38" || e.keyCode == "40") {
                                          e.stopPropagation();
                                          e.preventDefault();
                                        }
                                      }}
                                      onChange={(e) => {
                                        let newValue = handeFloatValueToFixed(e.target.value);
                                        if (newValue !== undefined && newValue !== null) {
                                          setAvDataRes({
                                            ...avDataRes,
                                            [data_key]: {
                                              ...avDataRes[data_key],
                                              avEquipCost: newValue ? newValue : 0,
                                            },
                                          });
                                        }
                                      }}
                                      value={
                                        avDataRes[data_key].avEquipCost
                                          ? avDataRes[data_key].avEquipCost
                                          : ""
                                      }
                                    />
                                  </td>
                                  <td className="bg-white">
                                    <div className="btn-icon-grp-table justify-content-center">
                                      <Button
                                        onClick={() => {
                                          let newJson: any = {};
                                          Object.keys(avDataRes).forEach((res_key) => {
                                            if (res_key != data_key) {
                                              newJson = {
                                                ...newJson,
                                                [res_key]: avDataRes[res_key],
                                              };
                                            }
                                          });
                                          if (Object.keys(newJson).length) {
                                            setAvDataRes(newJson);
                                          } else {
                                            setAvDataRes({});
                                            setAvCount(0);
                                          }
                                        }}
                                        type="button"
                                        className="btn-icon cancelIcon"
                                      ></Button>
                                    </div>
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                          <tr>
                            <td colSpan={5} className="bluetext">
                              <div
                                role="button"
                                tabIndex={-1}
                                onClick={() => {
                                  setAvDataRes({
                                    ...avDataRes,
                                    [avCount + 1]: {
                                      avEquipName: "",
                                      avEquipType_Term: "",
                                      avEquipCost: 0,
                                      taxRatePerc: 0,
                                      orderNO: 0,
                                    },
                                  });
                                  setAvDataResEr({
                                    ...avDataResEr,
                                    [avCount + 1]: {
                                      avEquipName: "",
                                      avEquipType_Term: "",
                                    },
                                  });
                                  setAvCount(avCount + 1);
                                }}
                                onKeyDown={() => {
                                  setAvDataRes({
                                    ...avDataRes,
                                    [avCount + 1]: {
                                      avEquipName: "",
                                      avEquipType_Term: "",
                                      avEquipCost: 0,
                                      taxRatePerc: 0,
                                      orderNO: 0,
                                    },
                                  });

                                  setAvDataResEr({
                                    ...avDataResEr,
                                    [avCount + 1]: {
                                      avEquipName: "",
                                      avEquipType_Term: "",
                                    },
                                  });

                                  setAvCount(avCount + 1);
                                }}
                              >
                                <img
                                  src={SquarePlus}
                                  alt=""
                                  width={15}
                                  height={15}
                                  className="me-1"
                                />
                                Add More
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </Row>

                  {/* Tax Table Start */}
                  <br />
                  <h6>Tax/Fees</h6>
                  <div className="tax-information customtablemetting">
                    <Table className="table-without-space mb-0" responsive bordered>
                      <thead>
                        <tr>
                          <th style={{ width: "10%", textAlign: "center" }} className="settitle">
                            Apply?
                          </th>
                          <th style={{ width: "60%" }} className="settitle">
                            Tax
                          </th>
                          <th style={{ width: "10%" }} className="settitle">
                            Type ($/%)
                          </th>
                          <th style={{ textAlign: "center", width: "10%" }} className="settitle">
                            Value
                          </th>
                          <th style={{ textAlign: "center", width: "10%" }} className="settitle">
                            Order No.
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {inputAVEquipmentTaxList.map((y, i) => (
                          <>
                            <tr key={i}>
                              <td className="" style={{ textAlign: "center" }}>
                                <input
                                  style={{ textAlign: "center" }}
                                  type="checkbox"
                                  checked={y.IsApply}
                                  onChange={(e) => AVEquipmentTaxCheckboxOnchange(e, y, i)}
                                ></input>
                              </td>
                              <td>{y.TaxTitle}</td>
                              <td className="bordernone" style={{ textAlign: "center" }}>
                                {y.IsPercentage === false ? "$" : "%"}
                              </td>
                              <td className="bordernone" style={{ textAlign: "center" }}>
                                <input
                                  type="number"
                                  onWheel={(event: any) => {
                                    event.target.blur();
                                  }}
                                  onKeyDown={(e: any) => {
                                    if (e.keyCode == "38" || e.keyCode == "40") {
                                      e.stopPropagation();
                                      e.preventDefault();
                                    }
                                  }}
                                  style={{ textAlign: "center" }}
                                  onChange={(e) => AVEquipmentRoomTaxOnchange(e, y, i)}
                                  value={y.RateOrPercentage}
                                />{" "}
                              </td>
                              <td className="bordernone" style={{ textAlign: "center" }}>
                                <Form.Control
                                  type="number"
                                  style={{ textAlign: "center" }}
                                  onWheel={(event: any) => {
                                    event.target.blur();
                                  }}
                                  onKeyDown={(e: any) => {
                                    if (e.keyCode == "38" || e.keyCode == "40") {
                                      e.stopPropagation();
                                      e.preventDefault();
                                    }
                                  }}
                                  value={y.OrderNo}
                                  onChange={(e) => {
                                    let AVlist: any = [...inputAVEquipmentTaxList];
                                    AVlist[i].OrderNo = e.target.value === "" ? 0 : e.target.value;
                                    setInputAVEquipmentTaxList(AVlist);

                                    if (
                                      props.EditPropertiesID !== undefined &&
                                      props.EditPropertiesID !== null &&
                                      props.EditPropertiesID !== ""
                                    ) {
                                      setPropertySave({
                                        ...PropertySave,
                                        ["IsTaxChange"]: true,
                                      });
                                    }
                                  }}
                                />
                              </td>
                            </tr>
                          </>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                  {/* tax table end */}
                </Container>
              ) : (
                <PageLoader />
              )}
            </Tab>
            <Tab
              eventKey="tab9"
              title="Food"
              tabClassName={`${isFoodValid === false ? "validate-tab" : ""}`}
            >
              {!loading ? (
                <Container fluid="true" className="full-height-tab">
                  <Row className="">
                    <Col xs={12} className="mb-2">
                      <h6>Food Details</h6>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <div className="col-md-12 customtablemetting mt-1">
                      <Table responsive bordered className="table-without-space">
                        <thead>
                          <tr className="h-40">
                            <th>Item Name</th>
                            <th>Type</th>
                            <th>Cost Per Unit($)</th>

                            <th>Description</th>
                            <th className="text-center">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.keys(dataResFood).map((data_key, i) => {
                            return (
                              <>
                                <tr>
                                  <td>
                                    <input
                                      className={`input-tabal ps-0 pe-0 creatableSpace ${
                                        dataResFoodEr[data_key] &&
                                        dataResFoodEr[data_key].itemName !== ""
                                          ? "red-border-masking ClassSelectCreatable"
                                          : ""
                                      }`}
                                      type="text"
                                      maxLength={364}
                                      onChange={(e) => {
                                        setDataResFood({
                                          ...dataResFood,
                                          [data_key]: {
                                            ...dataResFood[data_key],
                                            itemName: e.target.value ? e.target.value : "",
                                          },
                                        });
                                      }}
                                      value={dataResFood[data_key].itemName}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      className="input-tabal ps-0 pe-0"
                                      type="text"
                                      value={dataResFood[data_key].itemType_Term}
                                    />
                                  </td>

                                  <td className="bg-white">
                                    <input
                                      // className="input-tabal ps-0 pe-0"
                                      className={`input-tabal ps-0 pe-0 listFood[${i}].ItemCostPerUnit`}
                                      type="number"
                                      onWheel={(event: any) => {
                                        event.target.blur();
                                      }}
                                      onKeyDown={(e: any) => {
                                        if (e.keyCode == "38" || e.keyCode == "40") {
                                          e.stopPropagation();
                                          e.preventDefault();
                                        }
                                      }}
                                      onChange={(e) => {
                                        let newValue = handeFloatValueToFixed(e.target.value);
                                        if (newValue !== undefined && newValue !== null) {
                                          setDataResFood({
                                            ...dataResFood,
                                            [data_key]: {
                                              ...dataResFood[data_key],
                                              itemCostPerUnit: newValue ? newValue : 0,
                                            },
                                          });
                                        }
                                      }}
                                      value={
                                        dataResFood[data_key].itemCostPerUnit
                                          ? dataResFood[data_key].itemCostPerUnit
                                          : ""
                                      }
                                    />
                                  </td>

                                  <td>
                                    <input
                                      className="input-tabal ps-0 pe-0"
                                      type="text"
                                      onChange={(e) => {
                                        setDataResFood({
                                          ...dataResFood,
                                          [data_key]: {
                                            ...dataResFood[data_key],
                                            description: e.target.value ? e.target.value : "",
                                          },
                                        });
                                      }}
                                      value={dataResFood[data_key].description}
                                    />
                                  </td>
                                  <td className="bg-white">
                                    <div className="btn-icon-grp-table justify-content-center">
                                      <Button
                                        onClick={() => {
                                          let newJson: any = {};
                                          Object.keys(dataResFood).forEach((res_key) => {
                                            if (res_key != data_key) {
                                              newJson = {
                                                ...newJson,
                                                [res_key]: dataResFood[res_key],
                                              };
                                            }
                                          });
                                          if (Object.keys(newJson).length) {
                                            setDataResFood(newJson);
                                          } else {
                                            setDataResFood({});
                                            setCountFood(0);
                                          }
                                        }}
                                        type="button"
                                        className="btn-icon cancelIcon"
                                      ></Button>
                                    </div>
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                          <tr>
                            <td colSpan={5} className="bluetext">
                              <div
                                role="button"
                                tabIndex={-1}
                                onClick={() => {
                                  setDataResFood({
                                    ...dataResFood,
                                    [countFood + 1]: {
                                      itemName: "",
                                      itemType_Term: "Food",
                                      itemCostPerUnit: 0,
                                      taxRatePerc: 0,
                                      orderNo: 0,
                                      description: "",
                                    },
                                  });
                                  setDataResFoodEr({
                                    ...dataResFoodEr,
                                    [countFood + 1]: {
                                      itemName: "",
                                    },
                                  });
                                  setCountFood(countFood + 1);
                                }}
                                onKeyDown={() => {
                                  setDataResFood({
                                    ...dataResFood,
                                    [countFood + 1]: {
                                      itemName: "",
                                      itemType_Term: "Food",
                                      itemCostPerUnit: 0,
                                      taxRatePerc: 0,
                                      orderNo: 0,
                                      description: "",
                                    },
                                  });
                                  setDataResFoodEr({
                                    ...dataResFoodEr,
                                    [countFood + 1]: {
                                      itemName: "",
                                    },
                                  });
                                  setCountFood(countFood + 1);
                                }}
                              >
                                <img
                                  src={SquarePlus}
                                  alt=""
                                  width={15}
                                  height={15}
                                  className="me-1"
                                />
                                Add More
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </Row>

                  {/* Tax Table Start */}
                  <br />
                  <h6>Tax/Fees</h6>
                  <div className="tax-information customtablemetting">
                    <Table className="table-without-space mb-0" responsive bordered>
                      <thead>
                        <tr>
                          <th style={{ width: "10%", textAlign: "center" }} className="settitle">
                            Apply?
                          </th>
                          <th style={{ width: "60%" }} className="settitle">
                            Tax
                          </th>
                          <th style={{ width: "10%" }} className="settitle">
                            Type ($/%)
                          </th>
                          <th style={{ textAlign: "center", width: "10%" }} className="settitle">
                            Value
                          </th>
                          <th style={{ textAlign: "center", width: "10%" }} className="settitle">
                            Order No.
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {inputFoodTaxList.map((y, i) => (
                          <>
                            <tr key={i}>
                              <td className="" style={{ textAlign: "center" }}>
                                <input
                                  style={{ textAlign: "center" }}
                                  type="checkbox"
                                  checked={y.IsApply}
                                  onChange={(e) => FoodTaxCheckboxOnchange(e, y, i)}
                                ></input>
                              </td>
                              <td>{y.TaxTitle}</td>
                              <td className="bordernone" style={{ textAlign: "center" }}>
                                {y.IsPercentage === false ? "$" : "%"}
                              </td>
                              <td className="bordernone" style={{ textAlign: "center" }}>
                                <input
                                  type="number"
                                  onWheel={(event: any) => {
                                    event.target.blur();
                                  }}
                                  onKeyDown={(e: any) => {
                                    if (e.keyCode == "38" || e.keyCode == "40") {
                                      e.stopPropagation();
                                      e.preventDefault();
                                    }
                                  }}
                                  style={{ textAlign: "center" }}
                                  onChange={(e) => FoodRoomTaxOnchange(e, y, i)}
                                  value={y.RateOrPercentage}
                                />{" "}
                              </td>
                              <td className="bordernone" style={{ textAlign: "center" }}>
                                <Form.Control
                                  type="number"
                                  style={{ textAlign: "center" }}
                                  onWheel={(event: any) => {
                                    event.target.blur();
                                  }}
                                  onKeyDown={(e: any) => {
                                    if (e.keyCode == "38" || e.keyCode == "40") {
                                      e.stopPropagation();
                                      e.preventDefault();
                                    }
                                  }}
                                  value={y.OrderNo}
                                  onChange={(e) => {
                                    let Flist: any = [...inputFoodTaxList];
                                    Flist[i].OrderNo = e.target.value === "" ? 0 : e.target.value;
                                    setInputFoodTaxList(Flist);

                                    if (
                                      props.EditPropertiesID !== undefined &&
                                      props.EditPropertiesID !== null &&
                                      props.EditPropertiesID !== ""
                                    ) {
                                      setPropertySave({
                                        ...PropertySave,
                                        ["IsTaxChange"]: true,
                                      });
                                    }
                                  }}
                                />
                              </td>
                            </tr>
                          </>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                  {/* tax table end */}
                </Container>
              ) : (
                <PageLoader />
              )}
            </Tab>
            <Tab
              eventKey="tab10"
              title="Beverage"
              tabClassName={`${isBeverageValid === false ? "validate-tab" : ""}`}
            >
              {!loading ? (
                <Container fluid="true" className="full-height-tab">
                  <Row className="">
                    <Col xs={12} className="mb-2">
                      <h6>Beverage Details</h6>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <div className="col-md-12 customtablemetting mt-1">
                      <Table responsive bordered className="table-without-space">
                        <thead>
                          <tr className="h-40">
                            <th>Item Name</th>
                            <th>Type</th>
                            <th>Cost Per Unit($)</th>

                            <th>Description</th>
                            <th className="text-center">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.keys(dataResBeverage).map((data_key, i) => {
                            return (
                              <>
                                <tr>
                                  <td>
                                    <input
                                      className={`input-tabal ps-0 pe-0 creatableSpace ${
                                        dataResBeverageEr[data_key] &&
                                        dataResBeverageEr[data_key].itemName !== ""
                                          ? "red-border-masking ClassSelectCreatable"
                                          : ""
                                      }`}
                                      type="text"
                                      maxLength={364}
                                      onChange={(e) => {
                                        setDataResBeverage({
                                          ...dataResBeverage,
                                          [data_key]: {
                                            ...dataResBeverage[data_key],
                                            itemName: e.target.value ? e.target.value : "",
                                          },
                                        });
                                      }}
                                      value={dataResBeverage[data_key].itemName}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      className="input-tabal ps-0 pe-0"
                                      type="text"
                                      value={dataResBeverage[data_key].itemType_Term}
                                    />
                                  </td>

                                  <td className="bg-white">
                                    <input
                                      className={`input-tabal ps-0 pe-0 listBeverages[${i}].ItemCostPerUnit`}
                                      // className="input-tabal ps-0 pe-0"
                                      type="number"
                                      onWheel={(event: any) => {
                                        event.target.blur();
                                      }}
                                      onKeyDown={(e: any) => {
                                        if (e.keyCode == "38" || e.keyCode == "40") {
                                          e.stopPropagation();
                                          e.preventDefault();
                                        }
                                      }}
                                      onChange={(e) => {
                                        let newValue = handeFloatValueToFixed(e.target.value);
                                        if (newValue !== undefined && newValue !== null) {
                                          setDataResBeverage({
                                            ...dataResBeverage,
                                            [data_key]: {
                                              ...dataResBeverage[data_key],
                                              itemCostPerUnit: newValue ? newValue : 0,
                                            },
                                          });
                                        }
                                      }}
                                      value={
                                        dataResBeverage[data_key].itemCostPerUnit
                                          ? dataResBeverage[data_key].itemCostPerUnit
                                          : ""
                                      }
                                    />
                                  </td>

                                  <td>
                                    <input
                                      className="input-tabal ps-0 pe-0"
                                      type="text"
                                      onChange={(e) => {
                                        setDataResBeverage({
                                          ...dataResBeverage,
                                          [data_key]: {
                                            ...dataResBeverage[data_key],
                                            description: e.target.value ? e.target.value : "",
                                          },
                                        });
                                      }}
                                      maxLength={364}
                                      value={dataResBeverage[data_key].description}
                                    />
                                  </td>
                                  <td className="bg-white">
                                    <div className="btn-icon-grp-table justify-content-center">
                                      <Button
                                        onClick={() => {
                                          let newJson: any = {};
                                          Object.keys(dataResBeverage).forEach((res_key) => {
                                            if (res_key != data_key) {
                                              newJson = {
                                                ...newJson,
                                                [res_key]: dataResBeverage[res_key],
                                              };
                                            }
                                          });
                                          if (Object.keys(newJson).length) {
                                            setDataResBeverage(newJson);
                                          } else {
                                            setDataResBeverage({});
                                            setCountBeverage(0);
                                          }
                                        }}
                                        type="button"
                                        className="btn-icon cancelIcon"
                                      ></Button>
                                    </div>
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                          <tr>
                            <td colSpan={5} className="bluetext">
                              <div
                                role="button"
                                tabIndex={-1}
                                onClick={() => {
                                  setDataResBeverage({
                                    ...dataResBeverage,
                                    [countBeverage + 1]: {
                                      itemName: "",
                                      itemType_Term: "Beverage",
                                      itemCostPerUnit: 0,
                                      taxRatePerc: 0,
                                      orderNo: 0,
                                      description: "",
                                    },
                                  });
                                  setDataResBeverageEr({
                                    ...dataResBeverageEr,
                                    [countBeverage + 1]: {
                                      itemName: "",
                                    },
                                  });
                                  setCountBeverage(countBeverage + 1);
                                }}
                                onKeyDown={() => {
                                  setDataResBeverage({
                                    ...dataResBeverage,
                                    [countBeverage + 1]: {
                                      itemName: "",
                                      itemType_Term: "Beverage",
                                      itemCostPerUnit: 0,
                                      taxRatePerc: 0,
                                      orderNo: 0,
                                      description: "",
                                    },
                                  });
                                  setDataResBeverageEr({
                                    ...dataResBeverageEr,
                                    [countBeverage + 1]: {
                                      itemName: "",
                                    },
                                  });
                                  setCountBeverage(countBeverage + 1);
                                }}
                              >
                                <img
                                  src={SquarePlus}
                                  alt=""
                                  width={15}
                                  height={15}
                                  className="me-1"
                                />
                                Add More
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </Row>

                  {/* Tax Table Start */}
                  <br />
                  <h6>Tax/Fees</h6>
                  <div className="tax-information customtablemetting">
                    <Table className="table-without-space mb-0" responsive bordered>
                      <thead>
                        <tr>
                          <th style={{ width: "10%", textAlign: "center" }} className="settitle">
                            Apply?
                          </th>
                          <th style={{ width: "60%" }} className="settitle">
                            Tax
                          </th>
                          <th style={{ width: "10%" }} className="settitle">
                            Type ($/%)
                          </th>
                          <th style={{ textAlign: "center", width: "10%" }} className="settitle">
                            Value
                          </th>
                          <th style={{ textAlign: "center", width: "10%" }} className="settitle">
                            Order No.
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {inputBeverageTaxList.map((y, i) => (
                          <>
                            <tr key={i}>
                              <td className="" style={{ textAlign: "center" }}>
                                <input
                                  style={{ textAlign: "center" }}
                                  type="checkbox"
                                  checked={y.IsApply}
                                  onChange={(e) => BeverageRoomTaxCheckboxOnchange(e, y, i)}
                                ></input>
                              </td>
                              <td>{y.TaxTitle}</td>
                              <td className="bordernone" style={{ textAlign: "center" }}>
                                {y.IsPercentage === false ? "$" : "%"}
                              </td>
                              <td className="bordernone" style={{ textAlign: "center" }}>
                                <input
                                  type="number"
                                  onWheel={(event: any) => {
                                    event.target.blur();
                                  }}
                                  onKeyDown={(e: any) => {
                                    if (e.keyCode == "38" || e.keyCode == "40") {
                                      e.stopPropagation();
                                      e.preventDefault();
                                    }
                                  }}
                                  style={{ textAlign: "center" }}
                                  onChange={(e) => BeverageRoomTaxOnchange(e, y, i)}
                                  value={y.RateOrPercentage}
                                />{" "}
                              </td>
                              <td className="bordernone" style={{ textAlign: "center" }}>
                                <Form.Control
                                  type="number"
                                  style={{ textAlign: "center" }}
                                  onWheel={(event: any) => {
                                    event.target.blur();
                                  }}
                                  onKeyDown={(e: any) => {
                                    if (e.keyCode == "38" || e.keyCode == "40") {
                                      e.stopPropagation();
                                      e.preventDefault();
                                    }
                                  }}
                                  value={y.OrderNo}
                                  onChange={(e) => {
                                    let Blist: any = [...inputBeverageTaxList];
                                    Blist[i].OrderNo = e.target.value === "" ? 0 : e.target.value;
                                    setInputBeverageTaxList(Blist);

                                    if (
                                      props.EditPropertiesID !== undefined &&
                                      props.EditPropertiesID !== null &&
                                      props.EditPropertiesID !== ""
                                    ) {
                                      setPropertySave({
                                        ...PropertySave,
                                        ["IsTaxChange"]: true,
                                      });
                                    }
                                  }}
                                />
                              </td>
                            </tr>
                          </>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                  {/* tax table end */}
                </Container>
              ) : (
                <PageLoader />
              )}
            </Tab>
            {/* Another three (Food Beverage Av_Equipment) Tabs End */}

            <Tab
              eventKey="tab11"
              title="Estimated BAR Rate"
              tabClassName={`${isEstBarRate === false ? "validate-tab" : ""}`}
            >
              {!loading ? (
                <Container fluid="true" className="p-0">
                  <Row className="m-0">
                    <Row className="m-0 mb-2">
                      <Col xs={3} className="mb-2 p-2">
                        <h6>Tier 1 or Season 1</h6>
                      </Col>
                      <Col xs={3} className="mb-2 p-2">
                        <h6>Tier 2 or Season 2</h6>
                      </Col>
                      <Col xs={3} className="mb-2 p-2">
                        <h6>Tier 3 or Season 3</h6>
                      </Col>
                      <Col xs={3} className="mb-2 p-2">
                        <h6>Tier 4 or Season 4</h6>
                      </Col>
                    </Row>
                    <Row className="m-0 mb-2">
                      <Col xs={3} className="mb-3 p-2">
                        <FloatingLabel controlId="floatingInput" label="Estimated BAR Rate">
                          <Form.Control
                            type="text"
                            maxLength={365}
                            className={`lstPropertyEstimatedRate[${0}].EstimatedBarRate`}
                            placeholder="Tier 1 BAR Rate"
                            value={PropertySave.EBR_Tier1}
                            onChange={(e: any) => {
                              let newValue = handeFloatValueToFixed(e.target.value);
                              if (newValue !== undefined && newValue !== null) {
                                let finalValue = calculateFloatValueToFixed(
                                  newValue,
                                  PropertySave.EBR_Tier1
                                );

                                setPropertySave({
                                  ...PropertySave,
                                  ["EBR_Tier1"]: finalValue,
                                });
                              }
                            }}
                          />
                        </FloatingLabel>
                      </Col>
                      <Col xs={3} className="mb-3 p-2">
                        <FloatingLabel controlId="floatingInput" label="Estimated BAR Rate">
                          <Form.Control
                            type="text"
                            maxLength={365}
                            className={`lstPropertyEstimatedRate[${1}].EstimatedBarRate`}
                            placeholder="Tier 2 BAR Rate"
                            value={PropertySave.EBR_Tier2}
                            onChange={(e: any) => {
                              let newValue = handeFloatValueToFixed(e.target.value);
                              if (newValue !== undefined && newValue !== null) {
                                let finalValue = calculateFloatValueToFixed(
                                  newValue,
                                  PropertySave.EBR_Tier2
                                );
                                setPropertySave({
                                  ...PropertySave,
                                  ["EBR_Tier2"]: finalValue,
                                });
                              }
                            }}
                          />
                        </FloatingLabel>
                      </Col>
                      <Col xs={3} className="mb-3 p-2">
                        <FloatingLabel controlId="floatingInput" label="Estimated BAR Rate">
                          <Form.Control
                            type="text"
                            maxLength={365}
                            className={`lstPropertyEstimatedRate[${2}].EstimatedBarRate`}
                            placeholder="Tier 3 BAR Rate"
                            value={PropertySave.EBR_Tier3}
                            onChange={(e: any) => {
                              let newValue = handeFloatValueToFixed(e.target.value);
                              if (newValue !== undefined && newValue !== null) {
                                let finalValue = calculateFloatValueToFixed(
                                  newValue,
                                  PropertySave.EBR_Tier3
                                );
                                setPropertySave({
                                  ...PropertySave,
                                  ["EBR_Tier3"]: finalValue,
                                });
                              }
                            }}
                          />
                        </FloatingLabel>
                      </Col>
                      <Col xs={3} className="mb-3 p-2">
                        <FloatingLabel controlId="floatingInput" label="Estimated BAR Rate">
                          <Form.Control
                            type="text"
                            maxLength={365}
                            className={`lstPropertyEstimatedRate[${3}].EstimatedBarRate`}
                            placeholder="Tier 4 BAR Rate"
                            value={PropertySave.EBR_Tier4}
                            onChange={(e: any) => {
                              let newValue = handeFloatValueToFixed(e.target.value);
                              if (newValue !== undefined && newValue !== null) {
                                let finalValue = calculateFloatValueToFixed(
                                  newValue,
                                  PropertySave.EBR_Tier4
                                );
                                setPropertySave({
                                  ...PropertySave,
                                  ["EBR_Tier4"]: finalValue,
                                });
                              }
                            }}
                          />
                        </FloatingLabel>
                      </Col>
                    </Row>
                  </Row>
                </Container>
              ) : (
                <PageLoader />
              )}
            </Tab>
          </Tabs>
        </Modal.Body>
        <Modal.Footer className="myfotercode">
          {AddPropertyActiveKey > 1 && (
            <Button className="savebtnclient" onClick={() => TabNextPrevious("Previous")}>
              <i className="fa-solid fa-arrow-left-long me-2"></i>Previous
            </Button>
          )}
          {AddPropertyActiveKey === 11 ? (
            <Button
              className="savebtnclient"
              disabled={OnProcessHide === true ? true : false}
              onClick={() => SaveData()}
            >
              <i className="fa-solid fa-floppy-disk me-2"></i>Save
            </Button>
          ) : (
            <>
              <Button className="savebtnclient" onClick={() => TabNextPrevious("Next")}>
                <i className="fa-solid fa-arrow-right-long me-2"></i>Next
              </Button>
              <Button
                className="savebtnclient"
                disabled={OnProcessHide === true ? true : false}
                onClick={() => SaveData()}
              >
                <i className="fa-solid fa-floppy-disk me-2"></i>Save
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
      {hotelChangeLoading ? (
        <div className="calender-loader-main">
          <PageLoader />
        </div>
      ) : (
        ""
      )}

      {modalShowRoomTypeTaxInformation && (
        <RoomTypeTaxDetailModal
          TaxRoomTypeRowNo={TaxRoomTypeRowNo}
          RoomNamePass={RoomNamePass}
          storeRoomTypeTax={storeRoomTypeTax}
          StoreEditRoomTypeTaxdata={StoreEditRoomTypeTaxdata}
          show={modalShowRoomTypeTaxInformation}
          onHide={() => {
            setmodalShowRoomTypeTaxInformation(false);
          }}
        />
      )}
    </>
  );
};

export default AddHotelsModal;
