import React from "react";
import { Button, Col, FloatingLabel, Form, Modal, Row, Tab, Tabs } from "react-bootstrap";
import { PatternFormat } from "react-number-format";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import BlackCancelIcon from "../../../Asset/Images/Icons/blackCancelIcon.svg";
import WhiteSaveIcon from "../../../Asset/Images/Icons/whiteSaveIcon.svg";
import PageLoader from "../../../Component/Loader/PageLoader";
import TabsView from "../../../Component/Tabs/Index";
import {
  ToasterError,
  ToasterSuccess,
  fnCheckValidationOfObject,
} from "../../../Services/CommonServices";
import {
  SaveAccountListInfo,
  convertToAccount,
  getEmailAssignToDropdownList,
  getLeadDetailByID,
  getSelectedAccountDetails,
} from "../../../Services/LeftMenuServices";
import { getProjectTermByCategory } from "../../../Services/Setup&Config/AdditionalSettingService";

const EditAccountInfo = (props: any) => {
  const dataSelector: any = useSelector((state: any) => state.session);
  const clientID: any = dataSelector.auth_UserDetail.C_ClientID;

  let init: any = {
    genCountryCode: "",
    locCountryCode: "",
    lstAccountType: [],
    lstAccountProperty: "",
    accountCode: "",
    accountDetail: "",
    accountID: "00000000-0000-0000-0000-000000000000",
    accountName: "",
    accountType_Term: "Active",
    addressStreet1: "",
    addressStreet2: "",
    agencyExt: "",

    anticipatedRev: "0.00",

    area: "",
    billingArea: "",
    billingCity: "",
    billingCountry: "",
    billingState: "",
    billingStreet1: "",
    billingStreet2: "",
    billingZip: "",
    bookingURL: "",
    city: "",
    citySpend: 0,

    citySpendUpdateOn: null,
    clientID: clientID, // "00000000-0000-0000-0000-000000000000",
    clientTargetRate: 0,

    comment: "",
    compSetHotel1: "",
    compSetHotel1UpdateOn: null, // "2022-11-08T01:29:30.237",
    compSetHotel1rate: 0,

    compSetHotel2: "",

    compSetHotel2rate: 0,

    companyDo: "",
    companyEmail: "",
    companyExt: "",
    contactEmail: "",
    contactName: "",
    contactNo: "",
    country: "",
    createdOn: null, // "2022-11-08T01:29:30.237",
    currentHotelUsed: "",
    currentRate: 0,
    directBookingLink: "",
    employees: "",
    features: "",
    feederCities: "",
    gds: "",
    headCountryCode: "",
    headExt: "",
    headPhoneNo: "",
    headquarterLocation: null,
    hotelHistorySpend: 0,

    hotelllingSpend: 0,
    iata: "",
    industryType_Term: "",
    isActive: true,
    isBlock: false,
    isCommissionable: true,
    isTargetAccount: null,
    isTargetGrowthAccount: null,
    isTop: false,
    lastActivityDate: null,
    lastContactedBy: "00000000-0000-0000-0000-000000000000",
    lastContactedComments: "",
    lastContactedOn: null, // "2022-11-08T05:16:41.76",
    location: "",
    lostReason: "",
    numberOfRooms: 0,
    other: "",
    ownerID: "", //"00000000-0000-0000-0000-000000000000",
    ownership_Term: "",
    phone: "",
    phoneExt: "",
    preferredBilling: "",
    propertyID: "00000000-0000-0000-0000-000000000000",
    pseudoCityCode: "",
    rateAccessCode: "",
    rateCode: 0,
    rateExpiryDate: null, // "2022-11-08T05:16:41.76",
    rateStartDate: null, // "2022-11-08T05:16:41.76",
    ratings_Term: "",
    refAcctID: "00000000-0000-0000-0000-000000000000",
    reservationMode: "",
    revenueManagerID: "00000000-0000-0000-0000-000000000000",
    rfpSource: "",
    seqNo: 0,
    sortCode: "",
    sourceField: "",
    state: "",
    stayPattern: "",
    subType_Term: "",
    territeryID: "00000000-0000-0000-0000-000000000000",
    tickerSymbol: "",
    totalOffices: 0,
    transportationNeeds: "",
    travelAgencyAddress: null,
    travelAgencyCity: null,
    travelAgencyContact: null,
    travelAgencyEmail: null,
    travelAgencyName: null,
    travelAgencyState: null,
    travelAgencyZip: null,
    travelerAndDept: "",
    updateBy: "00000000-0000-0000-0000-000000000000",
    updateLog: "",
    updateOn: null,
    vanityBookingURL: "",
    website: "",
    zipCode: "",
    leadID: null, // It is for Convert to account :
    errors: {
      accountName: "",
      lstAccountProperty: "",
      contactNo: "",
      phone: "",
      headPhoneNo: "",
      sourceField: "",
      accountType_Term: "",
      ownerID: "",
      ValidationRules: [
        {
          FieldName: "ownerID",
          ValidationType: "required",
          ValidationMessage: "This Field is required.",
        },
        {
          FieldName: "accountName",
          ValidationType: "required",
          ValidationMessage: "This Field is required.",
        },
        {
          FieldName: "accountType_Term",
          ValidationType: "required",
          ValidationMessage: "This Field is required.",
        },
        {
          FieldName: "contactNo",
          ValidationType: "range",
          ValidationMessage: "Enter Valid Contact No.",
        },
        {
          FieldName: "phone",
          ValidationType: "range",
          ValidationMessage: "Enter Valid Contact No.",
        },
        {
          FieldName: "headPhoneNo",
          ValidationType: "range",
          ValidationMessage: "Enter Valid Contact No.",
        },
        {
          FieldName: "lstAccountProperty",
          ValidationType: "required",
          ValidationMessage: "This Field is required.",
        },
        {
          FieldName: "sourceField",
          ValidationType: "required",
          ValidationMessage: "This Field is required.",
        },
      ],
    },
  };

  const [tabActiveKey, setTabActiveKey] = React.useState("Account"); // Active the tab.
  const [IsStatusSelect, setIsStatusSelect] = React.useState({ status: false, message: "" });

  const SelectedPropertyID: any = useSelector((state: any) => state.dashboard.SelectedPropertyID);
  const SelectedPropertyName: any = useSelector(
    (state: any) => state.dashboard.SelectedPropertyName
  );
  const globalHotelData: any = useSelector((state: any) => state.dashboard);
  const [loading, setLoading] = React.useState(false);
  const [StatusData, setStatusData]: any = React.useState([]);
  const [AccDetails, setAccDetails]: any = React.useState(init);
  const [AccountTypeCheck, setAccountTypeCheck] = React.useState([]);
  const [OnProcessHide, setOnProcessHide] = React.useState(false);
  const hotelsData: any = useSelector((state: any) => state.header.Hotels);

  const Token = dataSelector.access_Token;
  const userId: any = dataSelector.auth_UserDetail.U_UserID;
  const userTypeID: any = dataSelector.auth_UserDetail.U_UserTypeID;
  const [Source, setSource]: any = React.useState([]);
  const [AccountOwnerDropdown, setAccountOwnerDropdown]: any = React.useState([]);
  let optionsHotel: any = [];
  const [AccType, setAccType]: any = React.useState([]);
  const [selectedHotel, setSelectedHotel]: any = React.useState([]);
  const [Industry, setIndustry]: any = React.useState([]);

  const [hotelChangeLoading, sethotelChangeLoading] = React.useState(false); // Hotel Selection Loader State.

  let navigate: any = useNavigate();

  globalHotelData.GlobalHotels !== undefined &&
    globalHotelData.GlobalHotels &&
    globalHotelData.GlobalHotels.length > 0 &&
    globalHotelData.GlobalHotels.map((item: any) => {
      optionsHotel.push({
        label: item.HotelCode + " " + item.PropertyName,
        value: item.PropertyID,
      });
    });

  let optionsAccountOwner: any = [];
  AccountOwnerDropdown !== undefined &&
    AccountOwnerDropdown !== null &&
    AccountOwnerDropdown.length > 0 &&
    AccountOwnerDropdown.map((item: any) => {
      optionsAccountOwner.push({
        label: item.UserName,
        value: item.UserID,
      });
    });

  let optionsStatus: any = [];

  StatusData.length > 0 &&
    StatusData.map((item: any) => {
      optionsStatus.push({
        label: item.TermCode,
        value: item.TermCode,
      });
    });

  let optionsSource: any = [];
  Source !== undefined &&
    Source !== null &&
    Source.length > 0 &&
    Source.map((item: any) => {
      if (item.Category === "Source") {
        optionsSource.push({
          label: item.Term,
          value: item.TermID,
        });
      }
    });

  let StateDropdown: any = [];
  Source !== undefined &&
    Source !== null &&
    Source.length > 0 &&
    Source.map((item: any) => {
      if (item.Category === "State") {
        StateDropdown.push({
          value: item.Term,
          label: item.TermCode + " - " + item.Term,
        });
      }
    });
  let CountryDropdown: any = [];
  Source !== undefined &&
    Source !== null &&
    Source.length > 0 &&
    Source.map((item: any) => {
      if (item.Category === "Country") {
        CountryDropdown.push({
          value: item.Term,
          label: item.TermCode + " - " + item.Term,
        });
      }
    });

  let optionsIndustry: any = [
    {
      value: null,
      label: "Select Industry ",
    },
  ];
  Industry !== undefined &&
    Industry !== null &&
    Industry.length > 0 &&
    Industry.map((item: any) => {
      optionsIndustry.push({
        label: item.Term,
        value: item.TermID,
      });
    });

  React.useEffect(() => {
    sethotelChangeLoading(true);
    if (!props.AccountID) {
      if (!SelectedPropertyID?.includes(",")) {
        AccountOwnerDropdownList(SelectedPropertyID);
        setSelectedHotel({ label: SelectedPropertyName, value: SelectedPropertyID });
      }
    }

    if (props.AccountID !== undefined && props.AccountID !== null && props.AccountID !== "") {
      getAccountDeatails();
    }

    if (props.leadID !== undefined && props.leadID !== null) {
      EditwithId();
    }
    getSourceData();
  }, []);

  async function getStatusAndPriorityByProjectTerm() {
    let input: any = {
      clientID: clientID, // "00000000-0000-0000-0000-000000000000",
      category: "Account Status",
    };
    let res: any = await getProjectTermByCategory(input, Token);
    if (res.data.success) {
      if (res.data !== undefined && res.data !== null) {
        if (res.data.data !== undefined && res.data.data !== null) {
          let tempStatus =
            res.data.data.dataList1.length > 0 &&
            res.data.data.dataList1.filter((item) => item.Category === "Account Status");
          setStatusData(tempStatus);
        }
      }
    }
  }

  async function EditwithId() {
    let input: any = {
      keyID: props && props.leadID,
    };
    let res: any = await getLeadDetailByID(input, Token);

    if (res.data.success) {
      if (res.data !== null && res.data !== undefined) {
        let listProperty: any = [];
        listProperty.push({
          propertyID:
            res.data.data.propertyData &&
            res.data.data.propertyData[0] &&
            res.data.data.propertyData[0].PropertyID,
          isTop: true,
        });

        setAccDetails({
          ...AccDetails,

          leadID: res.data.data.leadData.leadID,
          ownerID: res.data.data.leadData.ownerID,
          accountName: res.data.data.leadData.companyName,
          website: res.data.data.leadData.website,
          companyEmail: res.data.data.leadData.email,
          country: res.data.data.leadData.country,
          contactname: res.data.data.leadData.firstName + res.data.data.leadData.lastName,
          phone:
            res.data.data.leadData.telephoneNo && !res.data.data.leadData.telephoneNo.includes(" ")
              ? res.data.data.leadData?.telephoneNo
              : res.data.data.leadData?.telephoneNo?.split(" ")[1],
          locCountryCode:
            res.data.data.leadData?.telephoneNo &&
            !res.data.data.leadData.telephoneNo?.includes(" ")
              ? ""
              : res.data.data.leadData?.telephoneNo?.split(" ")[0],
          phoneExt: res.data.data.leadData.ext,
          contactNo:
            res.data.data.leadData.mobileNo && !res.data.data.leadData.mobileNo?.includes(" ")
              ? res.data.data.leadData.mobileNo
              : res.data.data.leadData.mobileNo?.split(" ")[1],
          genCountryCode:
            res.data.data.leadData.mobileNo && !res.data.data.leadData.mobileNo?.includes(" ")
              ? ""
              : res.data.data.leadData.mobileNo?.split(" ")[0],

          addressStreet1: res.data.data.leadData.address,
          city: res.data.data.leadData.city,
          sourceField: res.data.data.leadData.lead_Source_Term,
          zipCode: res.data.data.leadData.zipcode,
          state: res.data.data.leadData.state,
          comment: res.data.data.leadData.comment,
          lstAccountProperty: listProperty,
        });
        let hotelSelectedArray: any = {};
        (hotelSelectedArray = {
          label:
            res.data.data.propertyData &&
            res.data.data.propertyData[0] &&
            res.data.data.propertyData[0].PropertyCode +
              " " +
              res.data.data.propertyData[0].PropertyName,
          value:
            res.data.data.propertyData &&
            res.data.data.propertyData[0] &&
            res.data.data.propertyData[0].PropertyID,
        }),
          setSelectedHotel(hotelSelectedArray);
        AccountOwnerDropdownList(
          res.data.data.propertyData &&
            res.data.data.propertyData[0] &&
            res.data.data.propertyData[0].PropertyID.toString()
        );
      }
    }
  }

  async function getAccountDeatails() {
    let input: any = {
      keyID: props.AccountID,
    };
    // setLoading(true);
    sethotelChangeLoading(true);
    let res: any = await getSelectedAccountDetails(input, Token);
    if (res.data.success) {
      if (res.data.data !== undefined && res.data.data !== null) {
        let hotelSelectedArray: any = {};
        let tempPropertyHotelObj: any = [];
        // Handle propery Data :
        if (res.data.data.propertyData.length) {
          let tempPropertyIDs: any = [];
          res.data.data.propertyData.forEach((item: any) => {
            hotelSelectedArray = {
              label: item.PropertyName,
              value: item.PropertyID,
            };

            tempPropertyIDs.push(item.PropertyID);
            tempPropertyHotelObj.push({
              propertyID: item.PropertyID,
              isTop: true,
            });
          });
          setSelectedHotel(hotelSelectedArray);
          // Account Owner Dropdown :
          AccountOwnerDropdownList(tempPropertyIDs.toString());
        }
        let tempAccountTypeIDs: any = [];
        if (res.data.data.typeData.length > 0) {
          res.data.data.typeData.map((item: any) => {
            tempAccountTypeIDs.push(item.AccountType_Term);
          });

          setAccType(tempAccountTypeIDs);
        }

        setAccDetails({
          ...AccDetails,
          lstAccountType: tempAccountTypeIDs,
          lstAccountProperty: tempPropertyHotelObj,
          accountCode: res.data.data.accountData.accountCode,
          accountID: res.data.data.accountData.accountID,
          accountName:
            res.data.data.accountData.accountName &&
            res.data.data.accountData.accountName?.includes("#")
              ? res.data.data.accountData.accountName?.split("#")[0]
              : res.data.data.accountData.accountName,
          accountType_Term: res.data.data.accountData.accountType_Term,
          addressStreet1: res.data.data.accountData.addressStreet1,
          addressStreet2: res.data.data.accountData.addressStreet2,
          agencyExt: res.data.data.accountData.agencyExt,

          anticipatedRev: res.data.data.accountData.anticipatedRev,

          area: res.data.data.accountData.area,
          billingArea: res.data.data.accountData.billingArea,
          billingCity: res.data.data.accountData.billingCity,
          billingCountry: res.data.data.accountData.billingCountry,
          billingState: res.data.data.accountData.billingState,
          billingStreet1: res.data.data.accountData.billingStreet1,
          billingStreet2: res.data.data.accountData.billingStreet2,
          billingZip: res.data.data.accountData.billingZip,
          bookingURL: res.data.data.accountData.bookingURL,
          city: res.data.data.accountData.city,
          citySpend: res.data.data.accountData.citySpend,

          citySpendUpdateOn: res.data.data.accountData.citySpendUpdateOn,
          clientID: res.data.data.accountData.clientID,
          clientTargetRate: res.data.data.accountData.clientTargetRate,

          comment: res.data.data.accountData.comment,
          compSetHotel1: res.data.data.accountData.compSetHotel1,
          compSetHotel1UpdateOn: res.data.data.accountData.compSetHotel1UpdateOn,
          compSetHotel1rate: res.data.data.accountData.compSetHotel1rate,

          compSetHotel2: res.data.data.accountData.compSetHotel2,

          compSetHotel2rate: res.data.data.accountData.compSetHotel2rate,

          companyDo: res.data.data.accountData.companyDo,
          companyEmail: res.data.data.accountData.companyEmail,
          companyExt: res.data.data.accountData.companyExt,
          contactEmail: res.data.data.accountData.contactEmail,
          contactName: res.data.data.accountData.contactName,
          genCountryCode:
            res.data.data.accountData.contactNo &&
            res.data.data.accountData.contactNo?.includes(" ")
              ? res.data.data.accountData.contactNo?.split(" ")[0] !== "null" &&
                res.data.data.accountData.contactNo?.split(" ")[0] !== undefined &&
                res.data.data.accountData.contactNo?.split(" ")[0] !== "undefined"
                ? res.data.data.accountData.contactNo?.split(" ")[0]
                : ""
              : "",
          contactNo:
            res.data.data.accountData.contactNo &&
            res.data.data.accountData.contactNo?.includes(" ")
              ? res.data.data.accountData.contactNo?.split(" ")[1] !== "null" &&
                res.data.data.accountData.contactNo?.split(" ")[1] !== undefined &&
                res.data.data.accountData.contactNo?.split(" ")[1] !== "undefined"
                ? res.data.data.accountData.contactNo?.split(" ")[1]
                : ""
              : res.data.data.accountData.contactNo !== undefined &&
                res.data.data.accountData.contactNo !== "undefined"
              ? res.data.data.accountData.contactNo
              : "",
          country: res.data.data.accountData.country,
          createdOn: res.data.data.accountData.createdOn,
          currentHotelUsed: res.data.data.accountData.currentHotelUsed,
          currentRate: res.data.data.accountData.currentRate,
          directBookingLink: res.data.data.accountData.directBookingLink,
          employees: res.data.data.accountData.employees,
          features: res.data.data.accountData.features,
          feederCities: res.data.data.accountData.feederCities,
          gds: res.data.data.accountData.gds,
          headCountryCode:
            res.data.data.accountData.headCountryCode &&
            res.data.data.accountData.headCountryCode !== null &&
            res.data.data.accountData.headCountryCode !== "null" &&
            res.data.data.accountData.headCountryCode !== undefined &&
            res.data.data.accountData.headCountryCode !== "undefined"
              ? res.data.data.accountData.headCountryCode
              : "",

          headExt: res.data.data.accountData.headExt,
          headPhoneNo:
            res.data.data.accountData.headPhoneNo &&
            res.data.data.accountData.headPhoneNo !== null &&
            res.data.data.accountData.headPhoneNo !== "null" &&
            res.data.data.accountData.headPhoneNo !== undefined &&
            res.data.data.accountData.headPhoneNo !== "undefined"
              ? res.data.data.accountData.headPhoneNo
              : "",
          headquarterLocation: res.data.data.accountData.headquarterLocation,
          hotelHistorySpend: res.data.data.accountData.hotelHistorySpend,

          hotelllingSpend: res.data.data.accountData.hotelllingSpend,
          iata: res.data.data.accountData.iata,
          industryType_Term: res.data.data.accountData.industryType_Term,
          isActive: res.data.data.accountData.isActive,
          isBlock: res.data.data.accountData.isBlock,
          isCommissionable: res.data.data.accountData.isCommissionable,
          isTargetAccount: res.data.data.accountData.isTargetAccount,
          isTargetGrowthAccount: res.data.data.accountData.isTargetGrowthAccount,
          isTop: res.data.data.accountData.isTop,
          lastActivityDate: res.data.data.accountData.lastActivityDate,
          lastContactedBy: res.data.data.accountData.lastContactedBy,
          lastContactedComments: res.data.data.accountData.lastContactedComments,
          lastContactedOn: res.data.data.accountData.lastContactedOn,
          location: res.data.data.accountData.location,
          lostReason: res.data.data.accountData.lostReason,
          numberOfRooms: res.data.data.accountData.numberOfRooms,
          other: res.data.data.accountData.other,
          ownerID: res.data.data.accountData.ownerID,
          ownership_Term: res.data.data.accountData.ownership_Term,
          locCountryCode:
            res.data.data.accountData.phone && res.data.data.accountData.phone?.includes(" ")
              ? res.data.data.accountData.phone?.split(" ")[0] !== "null" &&
                res.data.data.accountData.phone?.split(" ")[0] !== undefined &&
                res.data.data.accountData.phone?.split(" ")[0] !== "undefined"
                ? res.data.data.accountData.phone?.split(" ")[0]
                : ""
              : "",
          phone:
            res.data.data.accountData.phone && res.data.data.accountData.phone?.includes(" ")
              ? res.data.data.accountData.phone?.split(" ")[1] !== "null" &&
                res.data.data.accountData.phone?.split(" ")[1] !== undefined &&
                res.data.data.accountData.phone?.split(" ")[1] !== "undefined"
                ? res.data.data.accountData.phone?.split(" ")[1]
                : ""
              : res.data.data.accountData.phone !== undefined &&
                res.data.data.accountData.phone !== "undefined"
              ? res.data.data.accountData.phone
              : "",
          phoneExt: res.data.data.accountData.phoneExt,
          preferredBilling: res.data.data.accountData.preferredBilling,
          propertyID: res.data.data.accountData.propertyID,
          pseudoCityCode: res.data.data.accountData.pseudoCityCode,
          rateAccessCode: res.data.data.accountData.rateAccessCode,
          rateCode: res.data.data.accountData.rateCode,
          rateExpiryDate: res.data.data.accountData.rateExpiryDate,
          rateStartDate: res.data.data.accountData.rateStartDate,
          ratings_Term: res.data.data.accountData.ratings_Term,
          refAcctID: res.data.data.accountData.refAcctID,
          reservationMode: res.data.data.accountData.reservationMode,
          revenueManagerID: res.data.data.accountData.revenueManagerID,
          rfpSource: res.data.data.accountData.rfpSource,
          seqNo: res.data.data.accountData.seqNo,
          sortCode: res.data.data.accountData.sortCode,
          sourceField: res.data.data.accountData.sourceField,
          state: res.data.data.accountData.state,
          stayPattern: res.data.data.accountData.stayPattern,
          subType_Term: res.data.data.accountData.subType_Term,
          territeryID: res.data.data.accountData.territeryID,
          tickerSymbol: res.data.data.accountData.tickerSymbol,
          totalOffices: res.data.data.accountData.totalOffices,
          transportationNeeds: res.data.data.accountData.transportationNeeds,
          travelAgencyAddress: res.data.data.accountData.travelAgencyAddress,
          travelAgencyCity: res.data.data.accountData.travelAgencyCity,
          travelAgencyContact: res.data.data.accountData.travelAgencyContact,
          travelAgencyEmail: res.data.data.accountData.travelAgencyEmail,
          travelAgencyName: res.data.data.accountData.travelAgencyName,
          travelAgencyState: res.data.data.accountData.travelAgencyState,
          travelAgencyZip: res.data.data.accountData.travelAgencyZip,
          travelerAndDept: res.data.data.accountData.travelerAndDept,
          updateBy: res.data.data.accountData.updateBy,
          updateLog: res.data.data.accountData.updateLog,
          updateOn: res.data.data.accountData.updateOn,
          vanityBookingURL: res.data.data.accountData.vanityBookingURL,
          website: res.data.data.accountData.website,
          zipCode: res.data.data.accountData.zipCode,
        });
      }
    }
    // setLoading(false);
    sethotelChangeLoading(false);
  }

  async function AccountTypeDropdownList() {
    let input = {
      category: "Account Type",
      clientID: clientID,
    };
    let res: any = await getProjectTermByCategory(input, Token);
    if (res.data.success) {
      if (res.data.data !== undefined && res.data.data !== null && res.data.data !== "") {
        setAccountTypeCheck(res.data.data.dataList1);
      }
    }
  }

  function SelectAccounttypes(event: any, item: any) {
    if (event.target.checked === true) {
      setAccDetails({
        ...AccDetails,
        ["lstAccountType"]: item.Term,
      });

      setAccType((t) => [...t, item.Term]);
    }
    if (event.target.checked === false) {
      for (var i = 0; i < AccType.length; i++) {
        if (AccType[i] === event.target.value) {
          AccType.splice(i, 1);
          i--;
        }
      }
      setAccDetails({
        ...AccDetails,
        ["lstAccountType"]: AccType,
      });
      setAccType(AccType);
    }
  }

  async function getSourceData() {
    let input = {
      category: "Source,State,Country,Account Status,Industry Type,Account Type",
      clientID: clientID,
    };
    let res: any = await getProjectTermByCategory(input, Token);
    if (res.data.success) {
      if (res.data.data !== undefined && res.data.data !== null && res.data.data !== "") {
        let tempStatus =
          res.data.data.dataList1.length > 0 &&
          res.data.data.dataList1.filter((item) => item.Category === "Account Status");
        setStatusData(tempStatus);

        let tempIndustry =
          res.data.data.dataList1.length > 0 &&
          res.data.data.dataList1.filter((item) => item.Category === "Industry Type");
        setIndustry(tempIndustry);

        let tempAccountType =
          res.data.data.dataList1.length > 0 &&
          res.data.data.dataList1.filter((item) => item.Category === "Account Type");
        setAccountTypeCheck(tempAccountType);

        setSource(res.data.data.dataList1);
      }
    }
    sethotelChangeLoading(false);
  }

  async function getIndustryData() {
    let input = {
      category: "Industry Type",
      clientID: clientID,
    };
    let res: any = await getProjectTermByCategory(input, Token);
    if (res.data.success) {
      if (res.data.data !== undefined && res.data.data !== null && res.data.data !== "") {
        setIndustry(res.data.data.dataList1);
      }
    }
  }

  function handleSelectedHotels(event) {
    setSelectedHotel(event);
    let listProperty: any = [];
    listProperty.push({
      propertyID: event.value,
      isTop: true,
    });

    if (event.length === 0) {
      setAccDetails({
        ...AccDetails,
        ["lstAccountProperty"]: "",
      });
    } else {
      setAccDetails({
        ...AccDetails,
        ["lstAccountProperty"]: listProperty,
        ["propertyID"]: event.value,
      });
    }
    sethotelChangeLoading(true);
    AccountOwnerDropdownList(event.value);
  }

  async function AccountOwnerDropdownList(data) {
    if (data !== "") {
      let input = {
        clientID: clientID,
        propertyIDs: data,
      };

      let res: any = await getEmailAssignToDropdownList(input, Token);

      if (res.data.success) {
        if (res.data.data !== null && res.data.data !== undefined && res.data.data !== "") {
          setAccountOwnerDropdown(res.data.data.dataList1);
          if (!props.leadID) {
            if (!props.AccountID) {
              let userIDRes = res.data.data.dataList1.filter((item) => item.UserID === userId);
              setAccDetails({
                ...AccDetails,
                ["lstAccountProperty"]: [
                  {
                    propertyID: data,
                    isTop: true,
                  },
                ],
                ["propertyID"]: data,
                ["ownerID"]: userIDRes.length && userIDRes[0].UserID,
              });
            }
          }
        }
      } else {
        setAccountOwnerDropdown([]);
      }
    } else {
      setAccountOwnerDropdown([]);
    }
    sethotelChangeLoading(false);
  }

  async function saveFullAccountInformation() {
    if (AccDetails.lstAccountType.length == 0) {
      let obj = fnCheckValidationOfObject(AccDetails);

      setIsStatusSelect({ status: true, message: "Please select atleast one Account type" });
    } else {
      setIsStatusSelect({ status: false, message: "" });
      let obj = fnCheckValidationOfObject(AccDetails);
      obj.obj.lstAccountType = AccType;
      setAccDetails({
        ...obj.obj,
      });
      if (obj.isValid) {
        if (
          AccDetails.accountID !== "00000000-0000-0000-0000-000000000000" &&
          AccDetails.accountID !== undefined &&
          AccDetails.accountID !== null
        ) {
          // Update
          let input: any = {
            accountID: AccDetails.accountID,
            accountCode: AccDetails.accountCode,
            accountName: AccDetails.accountName,
            sortCode: "",
            territeryID: "00000000-0000-0000-0000-000000000000",
            comment: AccDetails.comment,
            website: AccDetails.website,
            companyEmail: "",
            accountType_Term: AccDetails.accountType_Term,
            industryType_Term: AccDetails.industryType_Term,
            subType_Term: "",
            ratings_Term: "",
            employees: "",
            numberOfRooms: 0,
            refAcctID: "00000000-0000-0000-0000-000000000000",
            contactName: "",
            contactNo: AccDetails.genCountryCode + " " + AccDetails.contactNo,
            contactEmail: "",
            addressStreet1: AccDetails.addressStreet1,
            addressStreet2: "",
            area: "",
            city: AccDetails.city,
            zipCode: AccDetails.zipCode,
            state: AccDetails.state,
            country: AccDetails.country,
            revenueManagerID: "00000000-0000-0000-0000-000000000000",
            tickerSymbol: "",
            ownership_Term: "",
            billingStreet1: AccDetails.billingStreet1,
            billingStreet2: "",
            billingArea: "",
            billingCity: AccDetails.billingCity,
            billingZip: AccDetails.billingZip,
            billingState: AccDetails.billingState,
            billingCountry: AccDetails.billingCountry,
            accountDetail: "",
            lastContactedOn: null, //"2022-11-08T05:16:41.761Z",
            lastContactedBy: "00000000-0000-0000-0000-000000000000",
            lastContactedComments: "",
            ownerID: AccDetails.ownerID,
            citySpend: 0,
            hotelllingSpend: 0,
            hotelHistorySpend: 0,
            totalOffices: 0,
            companyDo: AccDetails.companyDo,
            travelerAndDept: AccDetails.travelerAndDept,
            feederCities: AccDetails.feederCities,
            gds: "",
            pseudoCityCode: "",
            iata: "",
            rateAccessCode: "",
            stayPattern: "",
            currentRate: 0,
            anticipatedRev: "",
            rateCode: 0,
            rateStartDate: null, // "2022-11-08T05:16:41.761Z",
            rateExpiryDate: null, // "2022-11-08T05:16:41.761Z",
            location: "",
            clientTargetRate: 0,
            features: "",
            transportationNeeds: "",
            other: "",
            currentHotelUsed: "",
            reservationMode: "",
            isTop: AccDetails.isTop,
            headquarterLocation: AccDetails.headquarterLocation,
            bookingURL: "",
            vanityBookingURL: "",
            compSetHotel1: "",
            compSetHotel1rate: 0,
            compSetHotel2: "",
            compSetHotel2rate: 0,
            compSetHotel1UpdateOn: null, // "2022-11-08T05:16:41.761Z",

            rfpSource: "",

            citySpendUpdateOn: null, //"2022-11-08T05:16:41.761Z",

            phone: AccDetails.locCountryCode + " " + AccDetails.phone,
            lostReason: "",
            lastActivityDate: null, // "2022-11-08T05:16:41.761Z",
            isCommissionable: true,
            sourceField: AccDetails.sourceField,
            travelAgencyName: "",
            travelAgencyAddress: "",
            travelAgencyContact: "",
            travelAgencyCity: "",
            travelAgencyState: "",
            travelAgencyZip: "",
            travelAgencyEmail: "",
            phoneExt: AccDetails.phoneExt,
            agencyExt: "",
            companyExt: AccDetails.companyExt,
            preferredBilling: "",
            isTargetAccount: AccDetails.isTargetAccount,
            isTargetGrowthAccount: AccDetails.isTargetGrowthAccount,
            directBookingLink: AccDetails.directBookingLink,
            headPhoneNo: AccDetails.headPhoneNo,
            headCountryCode: AccDetails.headCountryCode,
            headExt: AccDetails.headExt,
            lstAccountType: AccDetails.lstAccountType,
            lstAccountProperty: AccDetails.lstAccountProperty,
          };
          setOnProcessHide(true);

          let res: any = await SaveAccountListInfo(input, Token);
          setOnProcessHide(false);
          if (res.data !== undefined) {
            let Message = "Accounts Updated SuccessFully";
            if (res.data.statusCode === 200) {
              ToasterSuccess(Message);
              props.getAccountDeatails();

              navigate("/accounts-view", {
                state: {
                  AccountID: res.data.data.accountID,
                  AccountName: res.data.data.accountName,
                },
              });
              if (typeof props.setAccountGetCount !== "undefined" && props.setAccountGetCount) {
                props.setAccountGetCount(props.accountGetCount + 1);
              }
              props.onHide(false);
            } else if (res.data.statusCode === 208) {
              ToasterError(res.data.message);
            }
          } else {
            ToasterError(res.message);
          }
        } else {
          // Convert to Account :

          if (props.LeadInfo !== undefined && props.LeadInfo !== null) {
            let inputCnvt: any = {
              accountID: "00000000-0000-0000-0000-000000000000",
              accountCode: AccDetails.accountCode,
              accountName: AccDetails.accountName,
              sortCode: "",
              territeryID: "00000000-0000-0000-0000-000000000000",
              comment: AccDetails.comment,
              website: AccDetails.website,
              companyEmail: "",
              accountType_Term: AccDetails.accountType_Term,
              industryType_Term: AccDetails.industryType_Term,
              subType_Term: "",
              ratings_Term: "",
              employees: "",
              numberOfRooms: 0,
              refAcctID: "00000000-0000-0000-0000-000000000000",
              contactName: "",
              contactNo: AccDetails.genCountryCode + " " + AccDetails.contactNo,
              contactEmail: "",
              addressStreet1: AccDetails.addressStreet1,
              addressStreet2: "",
              area: "",
              city: AccDetails.city,
              zipCode: AccDetails.zipCode,
              state: AccDetails.state,
              country: AccDetails.country,
              revenueManagerID: "00000000-0000-0000-0000-000000000000",
              tickerSymbol: "",
              ownership_Term: "",
              billingStreet1: AccDetails.billingStreet1,
              billingStreet2: "",
              billingArea: "",
              billingCity: AccDetails.billingCity,
              billingZip: AccDetails.billingZip,
              billingState: AccDetails.billingState,
              billingCountry: AccDetails.billingCountry,
              accountDetail: "",
              lastContactedOn: null, // "2022-11-08T05:16:41.761Z",
              lastContactedBy: "00000000-0000-0000-0000-000000000000",
              lastContactedComments: "",
              ownerID: AccDetails.ownerID,
              citySpend: 0,
              hotelllingSpend: 0,
              hotelHistorySpend: 0,
              totalOffices: 0,
              companyDo: AccDetails.companyDo,
              travelerAndDept: AccDetails.travelerAndDept,
              feederCities: AccDetails.feederCities,
              gds: "",
              pseudoCityCode: "",
              iata: "",
              rateAccessCode: "",
              stayPattern: "",
              currentRate: 0,
              anticipatedRev: "",
              rateCode: 0,
              rateStartDate: null, //"2022-11-08T05:16:41.761Z",
              rateExpiryDate: null, //"2022-11-08T05:16:41.761Z",
              location: "",
              clientTargetRate: 0,
              features: "",
              transportationNeeds: "",
              other: "",
              currentHotelUsed: "",
              reservationMode: "",
              isTop: AccDetails.isTop,
              headquarterLocation: AccDetails.headquarterLocation,
              bookingURL: "",
              vanityBookingURL: "",
              compSetHotel1: "",
              compSetHotel1rate: 0,
              compSetHotel2: "",
              compSetHotel2rate: 0,
              compSetHotel1UpdateOn: null, //"2022-11-08T05:16:41.761Z",

              rfpSource: "",

              citySpendUpdateOn: null, //"2022-11-08T05:16:41.761Z",

              phone: AccDetails.locCountryCode + " " + AccDetails.phone,
              lostReason: "",
              lastActivityDate: null, //"2022-11-08T05:16:41.761Z",
              isCommissionable: true,
              sourceField: AccDetails.sourceField,
              travelAgencyName: "",
              travelAgencyAddress: "",
              travelAgencyContact: "",
              travelAgencyCity: "",
              travelAgencyState: "",
              travelAgencyZip: "",
              travelAgencyEmail: "",
              phoneExt: AccDetails.phoneExt,
              agencyExt: "",
              companyExt: AccDetails.companyExt,
              preferredBilling: "",
              isTargetAccount: AccDetails.isTargetAccount,
              isTargetGrowthAccount: AccDetails.isTargetGrowthAccount,
              directBookingLink: AccDetails.directBookingLink,
              headPhoneNo: AccDetails.headPhoneNo,
              headCountryCode: AccDetails.headCountryCode,
              headExt: AccDetails.headExt,
              lstAccountType: AccDetails.lstAccountType,
              lstAccountProperty: AccDetails.lstAccountProperty,
              leadID: props.leadID,
            };
            setOnProcessHide(true);

            let res: any = await convertToAccount(inputCnvt, Token);
            setOnProcessHide(false);
            if (res.data !== undefined) {
              let Message = "Account Converted SuccessFully";
              if (res.data.data !== undefined && res.data.data !== null && res.data.statusCode === 200) {
                ToasterSuccess(Message);
                navigate("/accounts-view", {
                  state: {
                    AccountID: res.data.data.accountID,
                    AccountName: res.data.data.accountName,
                  },
                });
                props.onHide(false);
              } else if (res.data.statusCode === 208) {
                ToasterError(res.data.message);
              }
            } else {
              ToasterError(res.message);
            }
          } else {
            // Save :
            let input: any = {
              accountID: "00000000-0000-0000-0000-000000000000",
              accountCode: AccDetails.accountCode,
              accountName: AccDetails.accountName,
              sortCode: "",
              territeryID: "00000000-0000-0000-0000-000000000000",
              comment: AccDetails.comment,
              website: AccDetails.website,
              companyEmail: "",
              accountType_Term: AccDetails.accountType_Term,
              industryType_Term: AccDetails.industryType_Term,
              subType_Term: "",
              ratings_Term: "",
              employees: "",
              numberOfRooms: 0,
              refAcctID: "00000000-0000-0000-0000-000000000000",
              contactName: "",
              contactNo: AccDetails.genCountryCode + " " + AccDetails.contactNo,
              contactEmail: "",
              addressStreet1: AccDetails.addressStreet1,
              addressStreet2: "",
              area: "",
              city: AccDetails.city,
              zipCode: AccDetails.zipCode,
              state: AccDetails.state,
              country: AccDetails.country,
              revenueManagerID: "00000000-0000-0000-0000-000000000000",
              tickerSymbol: "",
              ownership_Term: "",
              billingStreet1: AccDetails.billingStreet1,
              billingStreet2: "",
              billingArea: "",
              billingCity: AccDetails.billingCity,
              billingZip: AccDetails.billingZip,
              billingState: AccDetails.billingState,
              billingCountry: AccDetails.billingCountry,
              accountDetail: "",
              lastContactedOn: null, // "2022-11-08T05:16:41.761Z",
              lastContactedBy: "00000000-0000-0000-0000-000000000000",
              lastContactedComments: "",
              ownerID: AccDetails.ownerID,
              citySpend: 0,
              hotelllingSpend: 0,
              hotelHistorySpend: 0,
              totalOffices: 0,
              // propertyID: AccDetails.propertyID, // "00000000-0000-0000-0000-000000000000",
              companyDo: AccDetails.companyDo,
              travelerAndDept: AccDetails.travelerAndDept,
              feederCities: AccDetails.feederCities,
              gds: "",
              pseudoCityCode: "",
              iata: "",
              rateAccessCode: "",
              stayPattern: "",
              currentRate: 0,
              anticipatedRev: "",
              rateCode: 0,
              rateStartDate: null, // "2022-11-08T05:16:41.761Z",
              rateExpiryDate: null, //"2022-11-08T05:16:41.761Z",
              location: "",
              clientTargetRate: 0,
              features: "",
              transportationNeeds: "",
              other: "",
              currentHotelUsed: "",
              reservationMode: "",
              isTop: AccDetails.isTop,
              headquarterLocation: AccDetails.headquarterLocation,
              bookingURL: "",
              vanityBookingURL: "",
              compSetHotel1: "",
              compSetHotel1rate: 0,
              compSetHotel2: "",
              compSetHotel2rate: 0,
              compSetHotel1UpdateOn: null, //"2022-11-08T05:16:41.761Z",

              rfpSource: "",

              citySpendUpdateOn: null, //"2022-11-08T05:16:41.761Z",

              phone: AccDetails.locCountryCode + " " + AccDetails.phone,
              lostReason: "",
              lastActivityDate: null, //"2022-11-08T05:16:41.761Z",
              isCommissionable: true,
              sourceField: AccDetails.sourceField,
              travelAgencyName: "",
              travelAgencyAddress: "",
              travelAgencyContact: "",
              travelAgencyCity: "",
              travelAgencyState: "",
              travelAgencyZip: "",
              travelAgencyEmail: "",
              phoneExt: AccDetails.phoneExt,
              agencyExt: "",
              companyExt: AccDetails.companyExt,
              preferredBilling: "",
              isTargetAccount: AccDetails.isTargetAccount,
              isTargetGrowthAccount: AccDetails.isTargetGrowthAccount,
              directBookingLink: AccDetails.directBookingLink,
              headPhoneNo: AccDetails.headPhoneNo,
              headCountryCode: AccDetails.headCountryCode,
              headExt: AccDetails.headExt,
              lstAccountType: AccDetails.lstAccountType,
              lstAccountProperty: AccDetails.lstAccountProperty,
            };
            setOnProcessHide(true);
            let res: any = await SaveAccountListInfo(input, Token);
            setOnProcessHide(false);
            if (res.data !== undefined) {
              let Message = "Account saved SuccessFully";
              if (res.data.statusCode === 200) {
                ToasterSuccess(Message);
                props.getAccountListData();
                props.onHide(false);
                if (res.data.data) {
                  navigate("/accounts-view", {
                    state: {
                      AccountID: res.data.data.accountID,
                      AccountName: res.data.data.accountName,
                      InAfterSaveDetail: true,
                    },
                  });
                }
              } else if (res.data.statusCode === 208) {
                ToasterError(res.data.message);
              }
            } else {
              ToasterError(res.message);
            }
          }
        }
      }
    }
  }

  function handleHeadquaterAddress(e) {
    //  Set the local address in headquater :

    if (e.target.checked === true) {
      setAccDetails({
        ...AccDetails,
        ["billingStreet1"]: AccDetails.addressStreet1,
        ["billingCity"]: AccDetails.city,
        ["billingState"]: AccDetails.state,
        ["billingZip"]: AccDetails.zipCode,
        ["billingCountry"]: AccDetails.country,
        ["locCountryCode"]: AccDetails.headCountryCode,
        ["phone"]: AccDetails.headPhoneNo,
        ["phoneExt"]: AccDetails.headExt,
      });
    } else {
      setAccDetails({
        ...AccDetails,
        ["billingStreet1"]: "",
        ["billingCity"]: "",
        ["billingState"]: "",
        ["billingZip"]: "",
        ["billingCountry"]: "",
        ["locCountryCode"]: "",
        ["phone"]: "",
        ["phoneExt"]: "",
      });
    }
  }
  function handleLocalAddress(e) {
    //  Set the  headquater address in local :

    if (e.target.checked === true) {
      setAccDetails({
        ...AccDetails,
        ["addressStreet1"]: AccDetails.billingStreet1,
        ["city"]: AccDetails.billingCity,
        ["state"]: AccDetails.billingState,
        ["zipCode"]: AccDetails.billingZip,
        ["country"]: AccDetails.billingCountry,
        ["headCountryCode"]: AccDetails.locCountryCode,
        ["headPhoneNo"]: AccDetails.phone,
        ["headExt"]: AccDetails.phoneExt,
      });
    } else {
      setAccDetails({
        ...AccDetails,
        ["addressStreet1"]: "",
        ["city"]: "",
        ["state"]: "",
        ["zipCode"]: "",
        ["country"]: "",
        ["headCountryCode"]: "",
        ["headPhoneNo"]: "",
        ["headExt"]: "",
      });
    }
  }

  return (
    <>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="modal-50w"
        className="quickAdd-popup"
        scrollable={true}
      >
        <Modal.Header>
          {AccDetails.accountID !== undefined &&
          AccDetails.accountID !== null &&
          AccDetails.accountID !== "00000000-0000-0000-0000-000000000000" ? (
            <Modal.Title id="contained-modal-title-vcenter">Edit Accounts Information</Modal.Title>
          ) : (
            <Modal.Title id="contained-modal-title-vcenter">Add Accounts Information</Modal.Title>
          )}
        </Modal.Header>

        <Modal.Body>
          {!loading ? (
            <>
              <Tabs
                activeKey={tabActiveKey}
                id="uncontrolled-tab-example"
                className="tab-component mb-3"
                onSelect={(key: any) => setTabActiveKey(key)}
              >
                <Tab
                  eventKey={"Account"}
                  title={"Account"}
                  tabClassName={`${
                    AccDetails.errors.accountName
                      ? "validate-tab"
                      : AccDetails.errors.lstAccountProperty
                      ? "validate-tab"
                      : AccDetails.errors.sourceField
                      ? "validate-tab"
                      : AccDetails.errors.accountType_Term
                      ? "validate-tab"
                      : AccDetails.errors.ownerID
                      ? "validate-tab"
                      : IsStatusSelect.status === true
                      ? "validate-tab"
                      : " "
                  }`}
                >
                  <>
                    <Row>
                      <Col xs={6} className="mb-3">
                        <FloatingLabel controlId="floatingInput" label="Account Code">
                          <Form.Control
                            type="text"
                            placeholder="Account Code"
                            value={AccDetails.accountCode}
                            onChange={(e) =>
                              setAccDetails({
                                ...AccDetails,
                                ["accountCode"]: e.target.value,
                              })
                            }
                            maxLength={15}
                            disabled={true}
                          />
                        </FloatingLabel>
                      </Col>
                      <Col xs={6} className="mb-3">
                        <FloatingLabel controlId="floatingPassword" label="Account Name">
                          <Form.Control
                            type="text"
                            placeholder="Account Name"
                            value={AccDetails.accountName}
                            maxLength={399}
                            onChange={(e) =>
                              setAccDetails({
                                ...AccDetails,
                                ["accountName"]: e.target.value,
                              })
                            }
                            isInvalid={AccDetails.errors && AccDetails.errors.accountName}
                          />
                          {AccDetails.errors && AccDetails.errors.accountName && (
                            <Form.Control.Feedback type="invalid">
                              {AccDetails.errors.accountName}
                            </Form.Control.Feedback>
                          )}
                        </FloatingLabel>
                      </Col>
                    </Row>
                    <Row>
                      {AccDetails.accountID !== undefined &&
                      AccDetails.accountID !== null &&
                      AccDetails.accountID !== "00000000-0000-0000-0000-000000000000" ? (
                        <Col xs={6} className="mb-3">
                          <div
                            className={`${
                              AccDetails.errors.lstAccountProperty && "red-border-masking"
                            }`}
                          >
                            <FloatingLabel controlId="floatingInput" label="">
                              <Select
                                placeholder="Select Hotels"
                                options={optionsHotel}
                                // value={selectedHotel}
                                value={optionsHotel.filter(
                                  (obj) =>
                                    selectedHotel.value && selectedHotel.value?.includes(obj.value)
                                )}
                                onChange={handleSelectedHotels}
                                isDisabled
                              />
                            </FloatingLabel>
                          </div>
                          <small style={{ color: "#dc3545" }}>
                            {" "}
                            {AccDetails.errors.lstAccountProperty &&
                              AccDetails.errors.lstAccountProperty}{" "}
                          </small>
                        </Col>
                      ) : (
                        <Col xs={6} className="mb-3">
                          <div
                            className={`${
                              AccDetails.errors.lstAccountProperty && "red-border-masking"
                            }`}
                          >
                            <FloatingLabel controlId="floatingInput" label="">
                              <Select
                                placeholder="Select Hotels"
                                options={optionsHotel}
                                // value={selectedHotel}
                                value={optionsHotel.filter(
                                  (obj) =>
                                    selectedHotel.value && selectedHotel.value?.includes(obj.value)
                                )}
                                onChange={handleSelectedHotels}
                              />
                            </FloatingLabel>
                          </div>
                          <small style={{ color: "#dc3545" }}>
                            {" "}
                            {AccDetails.errors.lstAccountProperty &&
                              AccDetails.errors.lstAccountProperty}{" "}
                          </small>
                        </Col>
                      )}

                      <Col xs={6} className="mb-3">
                        <div className={`${AccDetails.errors.ownerID && "red-border-masking"}`}>
                          <FloatingLabel controlId="floatingInput" label="">
                            <Select
                              placeholder="Account Owner"
                              options={optionsAccountOwner}
                              value={optionsAccountOwner.filter(
                                (obj) =>
                                  AccDetails.ownerID && AccDetails.ownerID?.includes(obj.value)
                              )}
                              onChange={(e: any) =>
                                setAccDetails({
                                  ...AccDetails,
                                  ["ownerID"]: e.value,
                                })
                              }
                            />
                          </FloatingLabel>
                        </div>
                        <small style={{ color: "#dc3545" }}>
                          {" "}
                          {AccDetails.errors.ownerID && AccDetails.errors.ownerID}{" "}
                        </small>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={6} className="mb-3">
                        <div className={`${AccDetails.errors.sourceField && "red-border-masking"}`}>
                          <FloatingLabel controlId="floatingSelectGrid" label="">
                            <Select
                              placeholder="Source"
                              value={optionsSource.filter(
                                (obj) =>
                                  AccDetails.sourceField &&
                                  AccDetails.sourceField?.includes(obj.label)
                              )}
                              options={optionsSource}
                              onChange={(e: any) =>
                                setAccDetails({
                                  ...AccDetails,
                                  ["sourceField"]: e.label,
                                })
                              }
                            />
                          </FloatingLabel>
                        </div>
                        <small style={{ color: "#dc3545" }}>
                          {" "}
                          {AccDetails.errors.sourceField && AccDetails.errors.sourceField}{" "}
                        </small>
                      </Col>
                      <Col xs={6} className="mb-3  align-self-center">
                        {["checkbox"].map((type: any) => (
                          <div key={`inline-${type}`} className="option-field">
                            <Form.Check
                              inline
                              label="Top Account"
                              type={type}
                              id={`inline-${type}-Start` + 1}
                              checked={AccDetails.isTop === true ? true : false}
                              onChange={(e) =>
                                setAccDetails({
                                  ...AccDetails,
                                  ["isTop"]: e.target.checked,
                                })
                              }
                            />

                            <Form.Check
                              inline
                              label="Target Account"
                              type={type}
                              id={`inline-${type}-Start` + 2}
                              checked={AccDetails.isTargetAccount === true ? true : false}
                              onChange={(e) =>
                                setAccDetails({
                                  ...AccDetails,
                                  ["isTargetAccount"]: e.target.checked,
                                })
                              }
                            />
                            <Form.Check
                              inline
                              label="Top Growth Account"
                              type={type}
                              id={`inline-${type}-Start` + 3}
                              checked={AccDetails.isTargetGrowthAccount === true ? true : false}
                              onChange={(e) =>
                                setAccDetails({
                                  ...AccDetails,
                                  ["isTargetGrowthAccount"]: e.target.checked,
                                })
                              }
                            />
                          </div>
                        ))}
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={6} className="mb-3">
                        <label className="for-radio-header" htmlFor="Status">
                          Status
                        </label>
                        {["radio"].map((type: any) => (
                          <div key={`inline-${type}`} className="option-field">
                            {StatusData.length > 0 &&
                              StatusData.map((item) => (
                                <>
                                  <Form.Check
                                    inline
                                    label={item.TermCode}
                                    name={item.TermCode}
                                    type={type}
                                    id={item.TermCode}
                                    checked={
                                      AccDetails.accountType_Term === item.TermCode ? true : false
                                    }
                                    value={AccDetails.accountType_Term}
                                    onChange={(e: any) =>
                                      setAccDetails({
                                        ...AccDetails,
                                        ["accountType_Term"]: item.TermCode,
                                      })
                                    }
                                  />
                                </>
                              ))}
                          </div>
                        ))}
                      </Col>
                      <Col xs={6} className="mb-3">
                        <label className="for-radio-header" htmlFor="AccountType">
                          Account Type
                        </label>
                        <div
                          className={`${IsStatusSelect.status === true && "red-border-masking"}`}
                        >
                          {["checkbox"].map((type: any) => (
                            <div key={`inline-${type}`} className="option-field">
                              {AccountTypeCheck.map((item: any, index) =>
                                AccType.length ? (
                                  <Form.Check
                                    inline
                                    key={index}
                                    id={"inline-rfp-Start" + index} // Solution
                                    label={item.Term}
                                    value={item.Term}
                                    name="group1"
                                    type={type}
                                    defaultChecked={AccType?.includes(item.Term)}
                                    onChange={(e) => SelectAccounttypes(e, item)}
                                  />
                                ) : (
                                  <Form.Check
                                    inline
                                    key={index}
                                    id={"inline-rfp-Start" + index} // Solution
                                    label={item.Term}
                                    value={item.Term}
                                    name="group1"
                                    type={type}
                                    onChange={(e) => SelectAccounttypes(e, item)}
                                  />
                                )
                              )}
                            </div>
                          ))}
                          <small style={{ color: "#dc3545" }}>
                            {IsStatusSelect.status === true && IsStatusSelect.message}
                          </small>
                        </div>
                      </Col>
                    </Row>
                  </>
                </Tab>
                <Tab
                  eventKey={"Address"}
                  title={"Address"}
                  tabClassName={`${
                    AccDetails.errors.phone
                      ? "validate-tab"
                      : AccDetails.errors.headPhoneNo
                      ? "validate-tab"
                      : ""
                  }`}
                >
                  <div>
                    <div>
                      <Row>
                        <Col xs={12} className="mb-3">
                          {["checkbox"].map((type: any) => (
                            <div key={`inline-${type}`} className="option-field d-flex">
                              <h6 className="me-3 mb-0">Local Address</h6>
                              <Form.Check
                                inline
                                label="Copy Headquarter Address"
                                name="Headquarter"
                                type={type}
                                id="local"
                                onChange={handleHeadquaterAddress}
                              />
                            </div>
                          ))}
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6} className="mb-3">
                          <FloatingLabel controlId="floatingInput" label="Street">
                            <Form.Control
                              type="text"
                              placeholder="billingStreet1"
                              maxLength={366}
                              value={AccDetails.billingStreet1}
                              onChange={(e: any) =>
                                setAccDetails({
                                  ...AccDetails,
                                  ["billingStreet1"]: e.target.value,
                                })
                              }
                            />
                          </FloatingLabel>
                        </Col>
                        <Col xs={6} className="mb-3">
                          <FloatingLabel controlId="floatingPassword" label="City">
                            <Form.Control
                              type="text"
                              placeholder="billingCity"
                              maxLength={166}
                              value={AccDetails.billingCity}
                              onChange={(e: any) =>
                                setAccDetails({
                                  ...AccDetails,
                                  ["billingCity"]: e.target.value,
                                })
                              }
                            />
                          </FloatingLabel>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6} className="mb-3 fild-input">
                          <div>
                            <FloatingLabel controlId="floatingInput" label="">
                              <CreatableSelect
                                isClearable
                                options={StateDropdown}
                                onChange={(event: any) => {
                                  setAccDetails({
                                    ...AccDetails,
                                    ["billingState"]: event !== null ? event.value : "",
                                  });
                                }}
                                placeholder="State"
                                value={
                                  AccDetails.billingState !== ""
                                    ? {
                                        label: AccDetails.billingState,
                                        value: AccDetails.billingState,
                                      }
                                    : {
                                        label: "State",
                                        value: "State",
                                      }
                                }
                              />
                            </FloatingLabel>
                          </div>
                        </Col>

                        <Col xs={6} className="mb-3 fild-input">
                          <div>
                            <FloatingLabel controlId="floatingInput" label="">
                              <CreatableSelect
                                placeholder={"Country"}
                                isClearable
                                options={CountryDropdown}
                                onChange={(event: any) => {
                                  setAccDetails({
                                    ...AccDetails,
                                    ["billingCountry"]: event !== null ? event.value : "",
                                  });
                                }}
                                value={
                                  AccDetails.billingCountry !== ""
                                    ? {
                                        label: AccDetails.billingCountry,
                                        value: AccDetails.billingCountry,
                                      }
                                    : {
                                        label: "Country",
                                        value: "Country",
                                      }
                                }
                              />
                            </FloatingLabel>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6} className="mb-3">
                          <FloatingLabel controlId="floatingPassword" label="Zip Code">
                            <Form.Control
                              type="text"
                              placeholder="billingZip"
                              maxLength={66}
                              value={AccDetails.billingZip}
                              onChange={(e: any) =>
                                setAccDetails({
                                  ...AccDetails,
                                  ["billingZip"]: e.target.value,
                                })
                              }
                            />
                          </FloatingLabel>
                        </Col>
                        <Col xs={6} className="mb-3">
                          <Row>
                            <Col xs={3}>
                              <FloatingLabel controlId="floatingInput" label="Code">
                                <Form.Control
                                  type="text"
                                  maxLength={6}
                                  placeholder="Code"
                                  value={AccDetails.locCountryCode}
                                  onChange={(e: any) =>
                                    setAccDetails({
                                      ...AccDetails,
                                      ["locCountryCode"]: e.target.value,
                                    })
                                  }
                                />
                              </FloatingLabel>
                            </Col>
                            <Col xs={6}>
                              <FloatingLabel controlId="floatingInput" label="">
                                <div
                                  className={`${AccDetails.errors.phone && "red-border-masking"}`}
                                >
                                  <FloatingLabel controlId="floatingInput" label="Phone">
                                    <PatternFormat
                                      className="form-control"
                                      placeholder="Phone"
                                      displayType="input"
                                      format="###-###-####"
                                      value={AccDetails.phone}
                                      onChange={(e: any) =>
                                        setAccDetails({
                                          ...AccDetails,
                                          ["phone"]: e.target.value,
                                        })
                                      }
                                    />
                                  </FloatingLabel>
                                </div>
                                {AccDetails.errors.phone && (
                                  <>
                                    <span className="feedbackss">{AccDetails.errors.phone}</span>
                                    <br />
                                  </>
                                )}
                              </FloatingLabel>
                            </Col>
                            <Col xs={3}>
                              <FloatingLabel controlId="floatingInput" label="Ext">
                                <Form.Control
                                  type="text"
                                  placeholder="Ext"
                                  maxLength={66}
                                  value={AccDetails.phoneExt}
                                  onChange={(e: any) =>
                                    setAccDetails({
                                      ...AccDetails,
                                      ["phoneExt"]: e.target.value,
                                    })
                                  }
                                />
                              </FloatingLabel>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                    <div>
                      <Row>
                        <Col xs={12} className="mb-3">
                          {["checkbox"].map((type: any) => (
                            <div key={`inline-${type}`} className="option-field d-flex">
                              <h6 className="me-3 mb-0">Headquarter</h6>
                              <Form.Check
                                inline
                                label="Copy Local Address"
                                name="Local"
                                type={type}
                                id="Headquarter"
                                onChange={handleLocalAddress}
                              />
                            </div>
                          ))}
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6} className="mb-3">
                          <FloatingLabel controlId="floatingInput" label="Street">
                            <Form.Control
                              type="text"
                              placeholder="Street"
                              maxLength={366}
                              value={AccDetails.addressStreet1}
                              onChange={(e: any) =>
                                setAccDetails({
                                  ...AccDetails,
                                  ["addressStreet1"]: e.target.value,
                                })
                              }
                            />
                          </FloatingLabel>
                        </Col>
                        <Col xs={6} className="mb-3">
                          <FloatingLabel controlId="floatingPassword" label="City">
                            <Form.Control
                              type="text"
                              placeholder="City"
                              maxLength={166}
                              value={AccDetails.city}
                              onChange={(e: any) =>
                                setAccDetails({
                                  ...AccDetails,
                                  ["city"]: e.target.value,
                                })
                              }
                            />
                          </FloatingLabel>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6} className="mb-3 fild-input">
                          <div>
                            <FloatingLabel controlId="floatingInput" label="">
                              <CreatableSelect
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: (provided) => ({
                                    ...provided,
                                    zIndex: 99999999,
                                  }),
                                  menu: (base) => ({
                                    ...base,
                                    zIndex: 2,
                                  }),
                                }}
                                isClearable
                                options={StateDropdown}
                                onChange={(event: any) => {
                                  setAccDetails({
                                    ...AccDetails,
                                    ["state"]: event !== null ? event.value : "",
                                  });
                                }}
                                placeholder="State"
                                value={
                                  AccDetails.state !== ""
                                    ? {
                                        label: AccDetails.state,
                                        value: AccDetails.state,
                                      }
                                    : {
                                        label: "State",
                                        value: "State",
                                      }
                                }
                              />
                            </FloatingLabel>
                          </div>
                        </Col>

                        <Col xs={6} className="mb-3 fild-input">
                          <div>
                            <FloatingLabel controlId="floatingInput" label="">
                              <CreatableSelect
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: (provided) => ({
                                    ...provided,
                                    zIndex: 99999999,
                                  }),
                                  menu: (base) => ({
                                    ...base,
                                    zIndex: 2,
                                  }),
                                }}
                                placeholder={"Country"}
                                isClearable
                                options={CountryDropdown}
                                onChange={(event: any) => {
                                  setAccDetails({
                                    ...AccDetails,
                                    ["country"]: event !== null ? event.value : "",
                                  });
                                }}
                                value={
                                  AccDetails.country !== ""
                                    ? {
                                        label: AccDetails.country,
                                        value: AccDetails.country,
                                      }
                                    : {
                                        label: "Country",
                                        value: "Country",
                                      }
                                }
                              />
                            </FloatingLabel>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6} className="mb-3">
                          <FloatingLabel controlId="floatingPassword" label="Zip Code">
                            <Form.Control
                              type="text"
                              maxLength={66}
                              placeholder="Zip Code"
                              value={AccDetails.zipCode}
                              onChange={(e: any) =>
                                setAccDetails({
                                  ...AccDetails,
                                  ["zipCode"]: e.target.value,
                                })
                              }
                            />
                          </FloatingLabel>
                        </Col>

                        <Col xs={6} className="mb-3">
                          <Row>
                            <Col xs={3}>
                              <FloatingLabel controlId="floatingInput" label="Code">
                                <Form.Control
                                  type="text"
                                  maxLength={6}
                                  placeholder="Code"
                                  value={AccDetails.headCountryCode}
                                  onChange={(e: any) =>
                                    setAccDetails({
                                      ...AccDetails,
                                      ["headCountryCode"]: e.target.value,
                                    })
                                  }
                                />
                              </FloatingLabel>
                            </Col>
                            <Col xs={6}>
                              <FloatingLabel controlId="floatingInput" label="">
                                <div
                                  className={`${
                                    AccDetails.errors.headPhoneNo && "red-border-masking"
                                  }`}
                                >
                                  <FloatingLabel controlId="floatingInput" label="Phone">
                                    <PatternFormat
                                      className="form-control"
                                      placeholder="Phone"
                                      displayType="input"
                                      format="###-###-####"
                                      value={AccDetails.headPhoneNo}
                                      onChange={(e: any) =>
                                        setAccDetails({
                                          ...AccDetails,
                                          ["headPhoneNo"]: e.target.value,
                                        })
                                      }
                                    />
                                  </FloatingLabel>
                                </div>
                                {AccDetails.errors.headPhoneNo && (
                                  <>
                                    <span className="feedbackss">
                                      {AccDetails.errors.headPhoneNo}
                                    </span>
                                    <br />
                                  </>
                                )}
                              </FloatingLabel>
                            </Col>
                            <Col xs={3}>
                              <FloatingLabel controlId="floatingInput" label="Ext">
                                <Form.Control
                                  type="text"
                                  placeholder="Ext"
                                  maxLength={9}
                                  value={AccDetails.headExt}
                                  onChange={(e: any) =>
                                    setAccDetails({
                                      ...AccDetails,
                                      ["headExt"]: e.target.value,
                                    })
                                  }
                                />
                              </FloatingLabel>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Tab>
                <Tab
                  eventKey={"General"}
                  title={"General"}
                  tabClassName={`${AccDetails.errors.contactNo ? "validate-tab" : ""}`}
                >
                  <div>
                    <Row>
                      <Col xs={6} className="mb-3">
                        <FloatingLabel controlId="floatingSelectGrid" label="">
                          <Select
                            placeholder="Select Industry"
                            value={optionsIndustry.filter(
                              (obj) =>
                                AccDetails.industryType_Term &&
                                AccDetails.industryType_Term?.includes(obj.label)
                            )}
                            options={optionsIndustry}
                            onChange={(e: any) =>
                              setAccDetails({
                                ...AccDetails,
                                ["industryType_Term"]: e.label,
                              })
                            }
                          />
                        </FloatingLabel>
                      </Col>
                      <Col xs={6} className="mb-3">
                        <FloatingLabel controlId="floatingPassword" label="What does company do ?">
                          <Form.Control
                            type="text"
                            placeholder="What does company do ?"
                            value={AccDetails.companyDo}
                            onChange={(e: any) =>
                              setAccDetails({
                                ...AccDetails,
                                ["companyDo"]: e.target.value,
                              })
                            }
                          />
                        </FloatingLabel>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6} className="mb-3">
                        <Row>
                          <Col xs={3}>
                            <FloatingLabel controlId="floatingInput" label="Code">
                              <Form.Control
                                type="text"
                                maxLength={6}
                                placeholder="Country Code"
                                value={AccDetails.genCountryCode}
                                onChange={(e) =>
                                  setAccDetails({
                                    ...AccDetails,
                                    ["genCountryCode"]: e.target.value,
                                  })
                                }
                              />
                            </FloatingLabel>
                          </Col>
                          <Col xs={6}>
                            <FloatingLabel controlId="floatingInput" label="">
                              <div
                                className={`${AccDetails.errors.contactNo && "red-border-masking"}`}
                              >
                                <FloatingLabel controlId="floatingInput" label="Phone">
                                  <PatternFormat
                                    className="form-control"
                                    placeholder="Phone"
                                    displayType="input"
                                    format="###-###-####"
                                    value={AccDetails.contactNo}
                                    onChange={(e) =>
                                      setAccDetails({
                                        ...AccDetails,
                                        ["contactNo"]: e.target.value,
                                      })
                                    }
                                  />
                                </FloatingLabel>
                              </div>
                              {AccDetails.errors.contactNo && (
                                <>
                                  <span className="feedbackss">{AccDetails.errors.contactNo}</span>
                                  <br />
                                </>
                              )}
                            </FloatingLabel>
                          </Col>
                          <Col xs={3}>
                            <FloatingLabel controlId="floatingInput" label="Ext">
                              <Form.Control
                                type="text"
                                placeholder="Ext"
                                maxLength={9}
                                value={AccDetails.companyExt}
                                onChange={(e) =>
                                  setAccDetails({
                                    ...AccDetails,
                                    ["companyExt"]: e.target.value,
                                  })
                                }
                              />
                            </FloatingLabel>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={6} className="mb-3">
                        <FloatingLabel controlId="floatingPassword" label="Feeder Cities">
                          <Form.Control
                            type="text"
                            placeholder="Feeder Cities"
                            maxLength={499}
                            value={AccDetails.feederCities}
                            onChange={(e) =>
                              setAccDetails({
                                ...AccDetails,
                                ["feederCities"]: e.target.value,
                              })
                            }
                          />
                        </FloatingLabel>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6} className="mb-3">
                        <FloatingLabel controlId="floatingPassword" label="Website">
                          <Form.Control
                            type="text"
                            placeholder="Websites"
                            maxLength={266}
                            value={AccDetails.website}
                            onChange={(e) =>
                              setAccDetails({
                                ...AccDetails,
                                ["website"]: e.target.value,
                              })
                            }
                          />
                        </FloatingLabel>
                      </Col>
                      <Col xs={6} className="mb-3">
                        <FloatingLabel controlId="floatingPassword" label="Direct Booking Link">
                          <Form.Control
                            type="text"
                            placeholder="Direct Booking Links"
                            maxLength={164}
                            value={AccDetails.directBookingLink}
                            onChange={(e) =>
                              setAccDetails({
                                ...AccDetails,
                                ["directBookingLink"]: e.target.value,
                              })
                            }
                          />
                        </FloatingLabel>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} className="mb-3">
                        <FloatingLabel
                          controlId="floatingPassword"
                          label="Who are the travelers and departures?"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Who are the travelers and departures?"
                            value={AccDetails.travelerAndDept}
                            onChange={(e) =>
                              setAccDetails({
                                ...AccDetails,
                                ["travelerAndDept"]: e.target.value,
                              })
                            }
                          />
                        </FloatingLabel>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} className="mb-3">
                        <FloatingLabel controlId="floatingPassword" label="Notes">
                          <Form.Control
                            as="textarea"
                            placeholder="comment"
                            style={{ height: "100px" }}
                            value={AccDetails.comment}
                            onChange={(e) =>
                              setAccDetails({
                                ...AccDetails,
                                ["comment"]: e.target.value,
                              })
                            }
                          />
                        </FloatingLabel>
                      </Col>
                    </Row>
                  </div>
                </Tab>
              </Tabs>
            </>
          ) : (
            <PageLoader />
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            <span className="me-2">
              <img src={BlackCancelIcon} alt="" />
            </span>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={saveFullAccountInformation}
            disabled={OnProcessHide === true ? true : false}
          >
            <span className="me-2">
              <img src={WhiteSaveIcon} alt="" />
            </span>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      {hotelChangeLoading ? (
        <div className="calender-loader-main">
          <PageLoader />
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default EditAccountInfo;
