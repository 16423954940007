import { debugPort } from "process";
import React from "react";
import { Button, Col, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import XLSX from "sheetjs-style";
import Swal from "sweetalert2";
import BlueLabel from "../../../Component/Labels/BlueLabel/Index";
import GreenLabel from "../../../Component/Labels/GreenLabel/Index";
import RedLabel from "../../../Component/Labels/RedLabel/Index";
import TableView from "../../../Component/Table/Index";
import ActivityResultViewModel from "../../../Model/ActivityResultView";
import AddActivity from "../../../Model/AddActivity";
import {
  CommonService,
  ToasterError,
  ToasterSuccess,
  getDateInFormat,
  getDateTimeInDBFormat,
  getDateInDBFormatForExcell,
  getSortDataByObjKey,
} from "../../../Services/CommonServices";
import { DeleteActivities, getAllActivitiesList } from "../../../Services/LeftMenuServices";
import { GetTodoMarkAsComplete } from "../../../Services/Setup&Config/HotelService";
import { getProjectTermByCategory } from "../../../Services/Setup&Config/AdditionalSettingService";

function PriorActivities() {
  let page_rights_Activities = CommonService.getPageRight("Activities");
  let page_rights_Account = CommonService.getPageRight("Account");
  let page_rights_LNR = CommonService.getPageRight("LNR");
  let page_rights_RFP = CommonService.getPageRight("RFP");
  let page_rights_Group = CommonService.getPageRight("Group");
  let page_rights_MeetingAndCatering = CommonService.getPageRight("MeetingAndCatering");
  let page_rights_Contacts = CommonService.getPageRight("Contacts");
  let page_rights_Lead = CommonService.getPageRight("Lead");

  const [modalResultView, setModalResultView] = React.useState(false);
  const [modalResultViewText, setModalResultViewText] = React.useState();

  const [modalShowActivity, setModalShowActivity] = React.useState(false);
  const [classname, setclassname] = React.useState("All");
  const [EditActivityId, setEditActivityId]: any = React.useState(
    "00000000-0000-0000-0000-000000000000"
  );
  const dataSelector: any = useSelector((state: any) => state.session);
  let SelectedPropertyID: any = useSelector((state: any) => state.dashboard.SelectedPropertyID);
  const clientID: any = dataSelector.auth_UserDetail.C_ClientID;
  const Token = dataSelector.access_Token;
  const activity_data_reload_count = dataSelector.activity_data_reload_count;

  let navigate = useNavigate();
  const activiesTableColumns = React.useMemo(
    () => [
      {
        Header: "Objective",
        accessor: "objective",
      },
      {
        Header: "Start Date",
        accessor: "startDate",
      },
      {
        Header: "End Date",
        accessor: "endDate",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Priority",
        accessor: "priority",
      },
      {
        Header: "Assigned To",
        accessor: "assignedTo",
      },
      {
        Header: "Result",
        accessor: "result",
      },
      {
        Header: "Account Name",
        accessor: "accountName",
      },
      {
        Header: "Hotel Code",
        accessor: "hotelCode",
      },
      {
        Header: "Action",
        accessor: "action",
        className: "table-action sticky-action",
      },
    ],
    []
  );

  const activiesTableData: any = [];
  let [PriorActivity, setPriorActivity]: any = React.useState({ IsData: true, data: [] });
  const [activityTypesList, setActivityTypesList]: any = React.useState([]);

  async function getAllActivityTypes() {
    let input: any = {
      category: "Activity Type",
      clientID: clientID,
    };
    let res: any = await getProjectTermByCategory(input, Token);
    if (res.data.success) {
      if (res.data !== undefined) {
        if (res.data.data !== undefined && res.data.data !== null) {
          setActivityTypesList(res.data.data.dataList1);
        }
      }
    }
  }

  React.useEffect(() => {
    getAllActivityTypes();
    if (SelectedPropertyID.length > 0) {
      PriorActivitytdetails();
    } else {
      setPriorActivity({ IsData: false, data: [] });
    }
  }, [SelectedPropertyID, activity_data_reload_count]);

  var rightAlignClass = { textAlign: "right" };
  var centerAlignClass = { textAlign: "center" };

  PriorActivity.data !== undefined &&
    PriorActivity.data !== null &&
    PriorActivity.data.length > 0 &&
    PriorActivity.data.map((item: any) => {
      activiesTableData.push({
        controlRender: {
          accountName: (
            <div
              role="button"
              tabIndex={-1}
              onClick={() => {
                if (page_rights_Account.is_view) {
                  handleNavigate(item);
                } else {
                  CommonService.unauthorizedAction();
                }
              }}
              onKeyDown={() => {
                if (page_rights_Account.is_view) {
                  handleNavigate(item);
                } else {
                  CommonService.unauthorizedAction();
                }
              }}
            >
              {item.AccountName}
            </div>
          ),

          objective: (
            <div
              role="button"
              tabIndex={-1}
              onClick={() => {
                if (page_rights_Activities.is_view) {
                  navigate("/activity/details", { state: { activityID: item.ActivityID } });
                } else {
                  CommonService.unauthorizedAction();
                }
              }}
              onKeyDown={() => {
                if (page_rights_Activities.is_view) {
                  navigate("/activity/details", { state: { activityID: item.ActivityID } });
                } else {
                  CommonService.unauthorizedAction();
                }
              }}
            >
              {item.ActivitySubject}
            </div>
          ),
          status:
            item.ActivityStatus_Term !== "" &&
            (item.ActivityStatus_Term === "Complete" ? (
              <>
                <button onClick={() => handleCheck(item.ActivityID)}>
                  <GreenLabel successLabel={item.ActivityStatus_Term} />
                </button>
              </>
            ) : (
              <>
                <button onClick={() => handleCheck(item.ActivityID)}>
                  <RedLabel dangerLabel={item.ActivityStatus_Term} />
                </button>
              </>
            )),
          priority:
            item.Priority_Term !== "" &&
            item.Priority_Term !== null &&
            (item.Priority_Term === "Low" ? (
              <GreenLabel successLabel={item.Priority_Term} />
            ) : item.Priority_Term === "Normal" ? (
              <BlueLabel blueLabel={item.Priority_Term} />
            ) : (
              <RedLabel dangerLabel={item.Priority_Term} />
            )),
          result: (
            <>
              <button
                onClick={() => {
                  setModalResultView(true);
                  setModalResultViewText(item);
                }}
                style={{ color: "blue", margin: "0 0 0 3px" }}
              >
                View
              </button>
              {/* )} */}
            </>
          ),
          startDate: getDateInFormat(item.StartDate),
          endDate: getDateInFormat(item.EndDate),
        },

        className: {
          status: "centerAlignClass text-center",
          startDate: "rightAlignClass text-end",
          endDate: "rightAlignClass text-end",
          action: "table-action sticky-action",
        },
        hotelCode: item.HotelCode,
        objective: item.ActivitySubject,
        status: item.ActivityStatus_Term,
        startDate: getDateTimeInDBFormat(item.StartDate), //item.StartDate && item.StartDate.split("T")[0],
        endDate: getDateTimeInDBFormat(item.EndDate), //item.EndDate && item.EndDate.split("T")[0],
        priority: item.Priority_Term,
        assignedTo: item.AssigntoUserName,
        result: item.ActivityDetails,
        accountName: item.AccountName,
        action: (
          <div className="btn-icon-grp-table">
            <Button
              className="btn-icon editIcon"
              onClick={() => {
                if (page_rights_Activities.is_update) {
                  setEditActivityId(item.ActivityID);
                  setModalShowActivity(true);
                } else {
                  CommonService.unauthorizedAction();
                }
              }}
            ></Button>

            <Button
              className="btn-icon cancelIcon"
              onClick={() => {
                if (page_rights_Activities.is_delete) {
                  DeleteActivity(item);
                } else {
                  CommonService.unauthorizedAction();
                }
              }}
            ></Button>
          </div>
        ),
      });
    });

  function handleNavigate(itm) {
    if (itm.AssociationType_Term.toLowerCase() == "rfp oppo") {
      if (page_rights_RFP.is_view) {
        navigate("/Opportunity/RFP/details", { state: { OppoID: itm.AssociationID } });
        // props.onHide(true);
      } else {
        CommonService.unauthorizedAction();
      }
    }
    if (itm.AssociationType_Term.toLowerCase() == "lnr oppo") {
      if (page_rights_LNR.is_view) {
        navigate("/Opportunity/LNR/details", { state: { LNROppoID: itm.AssociationID } });
        // props.onHide(true);
      } else {
        CommonService.unauthorizedAction();
      }
    }
    if (itm.AssociationType_Term.toLowerCase() == "group oppo") {
      if (page_rights_Group.is_view) {
        navigate("/Opportunity/Group/details", { state: { GroupOppoID: itm.AssociationID } });
        // props.onHide(true);
      } else {
        CommonService.unauthorizedAction();
      }
    }
    if (itm.AssociationType_Term.toLowerCase() == "mac oppo") {
      if (page_rights_MeetingAndCatering.is_view) {
        navigate("/Opportunity/MeetingAndCatering/details", {
          state: { MACOppoID: itm.AssociationID },
        });
        // props.onHide(true);
      } else {
        CommonService.unauthorizedAction();
      }
    }
    if (itm.AssociationType_Term.toLowerCase() == "lead") {
      if (page_rights_Lead.is_view) {
        navigate("/suspect/details", {
          state: { leadID: itm.AssociationID, leadName: itm.AccountName },
        });
        // props.onHide(true);
      } else {
        CommonService.unauthorizedAction();
      }
    }
    if (itm.AssociationType_Term.toLowerCase() == "contact") {
      if (page_rights_Contacts.is_view) {
        navigate("/contact/details", {
          state: { ContactID: itm.AssociationID, ContactName: itm.AccountName },
        });
        // props.onHide(true);
      } else {
        CommonService.unauthorizedAction();
      }
    }
    if (itm.AssociationType_Term.toLowerCase() == "account") {
      if (page_rights_Account.is_view) {
        navigate("/accounts-view", {
          state: { AccountID: itm.AssociationID, AccountName: itm.AccountName },
        });
        // props.onHide(true);
      } else {
        CommonService.unauthorizedAction();
      }
    }
    if (itm.AssociationType_Term.toLowerCase() == "accounts") {
      if (page_rights_Account.is_view) {
        navigate("/accounts-view", {
          state: { AccountID: itm.AssociationID, AccountName: itm.AccountName },
        });
        // props.onHide(true);
      } else {
        CommonService.unauthorizedAction();
      }
    }
  }

  async function handleCheck(activity_ID) {
    let input: any = {
      keyID: activity_ID,
    };
    let res: any = await GetTodoMarkAsComplete(input, Token);

    if (res.data.success) {
      if (res.data !== undefined && res.data !== null) {
        let Message = "Status Updated SuccessFully";
        PriorActivitytdetails();
        ToasterSuccess(Message);
      } else {
        ToasterError("Something went wrong.");
      }
    } else {
      ToasterError(res.message);
    }
  }

  async function PriorActivitytdetails() {
    let input: any = {
      clientID: clientID,
      associationID: null,
      associationType_Term: null,
      startDate: null,
      endDate: null,
      hotelIDs: SelectedPropertyID,
      // .length === 0 ? null : SelectedPropertyID, // SelectedPropertyID !== null ? SelectedPropertyID.length === 0 && "" : null, // SelectedPropertyID.length === 0 ? "" : null,Ó
      userIDs: null,
      activityType_Term: classname === "All" ? null : classname,
      activityStatus_Term: "Complete,Open",
      searchText: null,
    };

    setPriorActivity({ IsData: true, data: [] });

    let res: any = await getAllActivitiesList(input, Token);

    if (res.data.success) {
      if (res.data.statusCode === 200) {
        if (res.data.data !== undefined && res.data.data !== null) {
          if (SelectedPropertyID) {
            setPriorActivity({ IsData: false, data: res.data.data.dataList1 });
          }
        } else {
          setPriorActivity({ IsData: false, data: [] });
        }
      }
    }
  }

  async function abcdBar(data) {
    if (SelectedPropertyID.length) {
      setclassname(data);

      let input: any = {
        clientID: clientID,
        associationID: null,
        contactID: null,
        accountID: null,
        associationType_Term: "",
        startDate: null,
        endDate: null,
        hotelIDs: SelectedPropertyID, // SelectedPropertyID.length === 0 ? "" : null,
        userIDs: "",
        activityType_Term: data !== "All" ? data : null,
        activityStatus_Term: "Complete,Open",
        searchText: "",
      };

      setPriorActivity({ IsData: true, data: [] });
      let res: any = await getAllActivitiesList(input, Token);
      if (res.data.success) {
        if (res.data.statusCode === 200) {
          if (res.data.data !== undefined && res.data.data !== null) {
            if (SelectedPropertyID) {
              setPriorActivity({ IsData: false, data: res.data.data.dataList1 });
            }
          } else {
            setPriorActivity({ IsData: false, data: [] });
          }
        }
      }
    } else {
      setclassname(data);
    }
  }
  function DeleteActivity(data: any) {
    Swal.fire({
      title: "Are you sure want to delete ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "No",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let input: any = {
          keyID: data.ActivityID,
        };

        let res: any = await DeleteActivities(input, Token);

        if (res.data.success) {
          if (res.data !== undefined && res.data !== null && res.data !== "") {
            if (res.data.statusCode === 200) {
              ToasterSuccess("Activity Deleted Successfully");

              PriorActivitytdetails();
            }
          } else {
            ToasterError(res.data.message);
          }
        }
      }
    });
  }
  //#region Excel done with data
  function downloadExcel() {
    const DateValue = new Date();
    let localMultiFilters = JSON.parse(localStorage.getItem("activityFilter"));
    let localFilters = localMultiFilters.priorActivity;
    let mainTableData: any = [];

    PriorActivity.data !== undefined &&
      PriorActivity.data !== null &&
      PriorActivity.data.map((item: any) => {
        mainTableData.push({
          objective: item.ActivitySubject,
          priority: item.Priority_Term,
          assignedTo: item.AssigntoUserName,
          result: item.ActivityDetails,
          accountName: item.AccountName,
          hotelCode: item.HotelCode,
          startDate: getDateInDBFormatForExcell(item.StartDate),
          endDate: getDateInDBFormatForExcell(item.EndDate),
          status: item.ActivityStatus_Term,
          PropertyName: item.PropertyName,
          ActivitySubject: item.ActivitySubject,
          UpdatedBy: item.UpdatedByName,
          ActivityDetails: item.ActivityDetails,
          strTime: item.StrTime,
        });
      });

    if (localFilters.isSort.length !== 0) {
      if (localFilters.isSort[0].id !== "" && localFilters.isSort[0].desc === false) {
        // asc
        let ascData = getSortDataByObjKey(localFilters.isSort[0].id, mainTableData, "asc");
        let ascDataBind: any = [];
        ascData.map((item: any) => {
          ascDataBind.push({
            PropertyName: item.PropertyName,
            ActivitySubject: item.objective,
            StartDate: item.startDate,
            EndDate: item.endDate,
            ActivityStatus_Term: item.status,
            UpdatedBy: item.UpdatedBy,
            AccountName: item.accountName,
            HotelCode: item.hotelCode,
            ActivityDetails: item.ActivityDetails,
            StrTime: item.StrTime,
          });
        });

        let FileName =
          "Prior Activity" +
          DateValue.getFullYear() +
          "-" +
          (DateValue.getMonth() + 1) +
          "-" +
          DateValue.getDate();
        let binaryWS = XLSX.utils.json_to_sheet(ascDataBind);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, binaryWS, FileName);
        XLSX.writeFile(wb, FileName + ".xlsx");
      }
      if (localFilters.isSort[0].id !== "" && localFilters.isSort[0].desc === true) {
        // desc
        let descData = getSortDataByObjKey(localFilters.isSort[0].id, mainTableData, "desc");

        let descDataBind: any = [];
        descData.map((item: any) => {
          descDataBind.push({
            PropertyName: item.PropertyName,
            ActivitySubject: item.objective,
            StartDate: item.startDate,
            EndDate: item.endDate,
            ActivityStatus_Term: item.status,
            UpdatedBy: item.UpdatedBy,
            AccountName: item.accountName,
            HotelCode: item.hotelCode,
            ActivityDetails: item.ActivityDetails,
            StrTime: item.StrTime,
          });
        });

        let FileName =
          "Prior Activity" +
          DateValue.getFullYear() +
          "-" +
          (DateValue.getMonth() + 1) +
          "-" +
          DateValue.getDate();
        let binaryWS = XLSX.utils.json_to_sheet(descDataBind);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, binaryWS, FileName);
        XLSX.writeFile(wb, FileName + ".xlsx");
      }
      if (localFilters.isSort[0].id === "" && localFilters.isSort[0].desc === true) {
        // Normal
        let normalDataBind: any = [];
        mainTableData.map((item: any) => {
          normalDataBind.push({
            PropertyName: item.PropertyName,
            ActivitySubject: item.objective,
            StartDate: item.startDate,
            EndDate: item.endDate,
            ActivityStatus_Term: item.status,
            UpdatedBy: item.UpdatedBy,
            AccountName: item.accountName,
            HotelCode: item.hotelCode,
            ActivityDetails: item.ActivityDetails,
            StrTime: item.StrTime,
          });
        });

        let FileName =
          "Prior Activity" +
          DateValue.getFullYear() +
          "-" +
          (DateValue.getMonth() + 1) +
          "-" +
          DateValue.getDate();
        let binaryWS = XLSX.utils.json_to_sheet(normalDataBind);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, binaryWS, FileName);
        XLSX.writeFile(wb, FileName + ".xlsx");
      }
    } else {
      let normalInitDataBind: any = [];
      mainTableData.map((item: any) => {
        normalInitDataBind.push({
          PropertyName: item.PropertyName,
          ActivitySubject: item.objective,
          StartDate: item.startDate,
          EndDate: item.endDate,
          ActivityStatus_Term: item.status,
          UpdatedBy: item.UpdatedBy,
          AccountName: item.accountName,
          HotelCode: item.hotelCode,
          ActivityDetails: item.ActivityDetails,
          StrTime: item.StrTime,
        });
      });

      let FileName =
        "Prior Activity" +
        DateValue.getFullYear() +
        "-" +
        (DateValue.getMonth() + 1) +
        "-" +
        DateValue.getDate();
      let binaryWS = XLSX.utils.json_to_sheet(normalInitDataBind);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, binaryWS, FileName);
      XLSX.writeFile(wb, FileName + ".xlsx");
    }

    // Previous Export Code :
    // let mainTableData: any = [];
    // PriorActivity.data !== undefined &&
    //   PriorActivity.data !== null &&
    //   PriorActivity.data.map((item: any) => {
    //     mainTableData.push({
    //       PropertyName: item.PropertyName,
    //       ActivitySubject: item.ActivitySubject,
    //       StartDate: item.StartDate,
    //       EndDate: item.EndDate,
    //       ActivityStatus_Term: item.ActivityStatus_Term,
    //       UpdatedBy: item.UpdatedByName,
    //       AccountName: item.AccountName,
    //       ActivityDetails: item.ActivityDetails,
    //       strTime: item.StrTime,
    //     });
    //   });

    // let FileName = "Activity Export";
    // let binaryWS = XLSX.utils.json_to_sheet(mainTableData);
    // var wb = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(wb, binaryWS, FileName);
    // XLSX.writeFile(wb, FileName + ".xlsx");
  }
  //#endregion

  return (
    <>
      <div className="btn-icon-grp adjust-height">
        <OverlayTrigger placement="bottom" overlay={<Tooltip>Export</Tooltip>}>
          <Button
            className="btn-icon exportFileIcon"
            onClick={() => {
              if (page_rights_Activities.is_export) {
                downloadExcel();
              } else {
                CommonService.unauthorizedAction();
              }
            }}
            disabled={PriorActivity.data.length !== 0 ? false : true}
          ></Button>
        </OverlayTrigger>
        <OverlayTrigger placement="bottom" overlay={<Tooltip>Add Activity</Tooltip>}>
          <Button
            className="btn-icon addFormIcon"
            onClick={() => {
              if (page_rights_Activities.is_create) {
                setModalShowActivity(true);
              } else {
                CommonService.unauthorizedAction();
              }
            }}
          ></Button>
        </OverlayTrigger>
      </div>
      <Container fluid={true} className="p-0 position-relative">
        <div className="abcd-pill flex-wrap d-flex align-items-center justify-content-center mb-3">
          <button
            className={`mt-2 ${classname === "All" && "pills-buttons"}`}
            onClick={() => abcdBar("All")}
          >
            All
          </button>
          {activityTypesList.length ? (
            activityTypesList.map((itm, idx) => (
              <button
                key={idx}
                className={`mt-2 ${classname === itm.TermCode && "pills-buttons"}`}
                onClick={() => abcdBar(itm.TermCode)}
              >
                {itm.TermCode}
              </button>
            ))
          ) : (
            <button className={`mt-2 pills-buttons`}>{"Loading"}</button>
          )}
        </div>
        <Row>
          <Col xs={12}>
            <div className="activityList-height-table">
              <TableView
                columnsData={activiesTableColumns}
                tableData={activiesTableData}
                isData={PriorActivity.IsData}
              />
            </div>
          </Col>
        </Row>
      </Container>

      {modalShowActivity && (
        <AddActivity
          Activity={"MainActivity"}
          show={modalShowActivity}
          getEditActivitytdetails={PriorActivitytdetails}
          EditActivityId={EditActivityId}
          onHide={() => {
            setModalShowActivity(false);
            setEditActivityId("00000000-0000-0000-0000-000000000000");
          }}
        ></AddActivity>
      )}
      {modalResultView && (
        <ActivityResultViewModel
          resultdata={modalResultViewText}
          show={modalResultView}
          onHide={() => setModalResultView(false)}
        />
      )}
    </>
  );
}

export default PriorActivities;
