import axios from "axios";
import { AppConfigData } from "./CommonServices";
import { Cookies } from "react-cookie-consent";
//#region Lead
export const getLeadListData = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Lead/GetList",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const ExportLeadListData = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Lead/ExportLead",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};

export const getLeadDetailByID = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Lead/GetByPrimaryKeyWithAllDetails",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};

// export const getAssignToByHotel = async (ReqData: any, accessToken) => {
//   try {
//     const response = await axios({
//       method: "POST",
//       url: AppConfigData.APIBasePath + "client/api/Properties/GetAssignToByPropertyID",
//       data: ReqData,
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "bearer " + accessToken,
//       },
//     });
//     return response;
//   } catch (error) {
//     let data: any = {
//       data: {
//         success: false,
//         StatusCode: "02",
//         Message: "Something Wrong in API",
//       },
//     };
//     return data;
//   }
// };
export const getAssignToByPropertyID = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/User/GetListOfPropertySalesGroupUser",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};

export const saveSuspectLead = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Lead/Save",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};

export const deleteSuspectLead = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Lead/Delete",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};

export const convertToAccount = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Lead/ConvertToAccount",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};

export const CopyLead = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Lead/CopyLead",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};

//#endregion End Lead

//#region  Suspect
export const getSuspectList = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "suspects/api/Suspects/GetSuspectsList",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const saveSuspect = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "suspects/api/Suspects/SaveSuspects",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const getByIDSuspect = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "suspects/api/Suspects/GetSuspectsDetailsByID",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};

export const getChatHistroyByThreadId = async (ReqData: any) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.GetThread,
      // url: "https://chatbot.inntelligentcrm.com/api/chat/gethistroy", // Live working server of Thread URL.
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};

//#endregion
//#region  Account
export const getSwitchAccount = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Account/SwitchAccount",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const getAccountList = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Account/GetList",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const getNoActivityAccountsGetList = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Account/NoActivityAccountsGetList",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const getNewAccountsGetList = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Account/NewAccountsGetList",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};

export const getAccountCountList = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "accounts/api/Accounts/GetAccountListCount",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const SaveNewAccountList = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "accounts/api/Accounts/SaveNewAccountsDetail",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const SaveAccountListInfo = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Account/Save",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const SaveCopyAccount = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Account/CopyAccount",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const CopyContact = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Contacts/CopyContact",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const ExportAccountSummaryDetail = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Account/ExportAccountSummary",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const DeleteAccount = async (ReqData, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Account/Delete",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};

export const ExportContactFromAccountDetails = async (ReqData, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Contacts/ExportContactByAccount",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};

export const getAccountCount = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Account/DashboardSummary",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};

export const GetListByAccount = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Lead/GetListByAccount",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};

// izhar
export const getSelectedAccountDetails = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Account/GetByPrimaryKeyWithAllDetails",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};

//#endregion
//#region  Contact
export const getContactList = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "contacts/api/Contacts/GetContactsList",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};

export const getInsertContact = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "contacts/api/Contacts/InsertContacts",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};

export const getContactListById = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "contacts/api/Contacts/GetContactsDetailByID",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};

export const getContactListUpdate = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "contacts/api/Contacts/UpdateContacts",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const DeleteContactRecord = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Contacts/Delete",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};

//#endregion
//#region Opportunity

export const GetPropertyEstimatedRateListByHotel = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Properties/GetPropertyEstimatedRateList",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};

export const getRFPList = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Opportunities/RFPGetList",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const getEditRFPList = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Opportunities/RFPGetByPrimaryKey",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const getEditRFPListByID = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Opportunities/RFPGetByPrimaryKey",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const getRFPStatusList = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "accounts/api/CommonGen/GetSourceProjectTerm",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const getRFPStatusChange = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Opportunities/RFPChangeStatus",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const moveRFPTOLNR = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Opportunities/RFPToLNR",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const RFPExportSummary = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Opportunities/RFPExport",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const DeleteRFPOpportunity = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Opportunities/RFPDelete",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const RFPSave = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Opportunities/RFPSave",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};

export const SaveActualRateListByOppoID = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Opportunities/SaveActualRateListByOppoID",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};

export const getLNRList = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Opportunities/LNRGetList",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const getLNRStatusChange = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Opportunities/LNRChangeStatus",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const DeleteLNROpportunity = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Opportunities/LNRDelete",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const getEditLNRList = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Opportunities/LNRGetByPrimaryKey",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const moveLNRToRFP = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Opportunities/LNRToRFP",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const LNRExportSummary = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Opportunities/LNRExport",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const LNRSave = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Opportunities/LNRSave",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};

export const getGroupList = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Opportunities/GroupGetList",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const getGroupExportSummary = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Opportunities/GroupExport",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const getGroupStatusChange = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Opportunities/GroupChangeStatus",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const DeleteGroupOpportunity = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Opportunities/GroupDelete",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const getEditGroupList = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Opportunities/GroupGetByPrimaryKey",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const getEditTableGroupRate = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Opportunities/GroupRateDatesListByGroupOppoId",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const getTimeEditTableGroupRoomTax = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Opportunities/GetListHotelTaxByGroupOppoId",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const GroupSave = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Opportunities/GroupSave",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const GroupSaveFromLead = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Opportunities/GroupSaveFromLead",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const GenerateGroupDocument = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Opportunities/GroupGenerateWordFile",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const GenerateLNRDocument = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Opportunities/LNRGenerateWordFile",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const getGroupDetailViewTableData = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Opportunities/GroupRateDatesListByGroupOppoId",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};

// Opportunities Payment API's :
export const PaymentTransactionGetList = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/PaymentInfo/PaymentTransactionGetList",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};

export const PaymentTransactionSave = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/PaymentInfo/PaymentTransactionSave",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};

export const PaymentTransactionDelete = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/PaymentInfo/PaymentTransactionDelete",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};





export const GenerateMeetingAndCateringDocument = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Opportunities/MeetingCateringGenerateWordFile",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const getMeetingAndCateringExportSummary = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Opportunities/MeetingCateringExport",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const getMeetingCateringList = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Opportunities/MeetingCateringGetList",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const getOpportunityRateTableData = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Opportunities/GetAccountRateByAccountID",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const getActualRateTableData = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Opportunities/GetActualRateList",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};

// New Update By Satish Sir :
export const GetActualRateListByOppoID = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Opportunities/GetActualRateListByOppoID",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};

export const getMeetingCateringStatusChange = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Opportunities/MeetingCateringChangeStatus",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const DeleteMeetingCateringOpportunity = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Opportunities/MeetingCateringDelete",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const MeetingCateringSave = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Opportunities/MeetingCateringSave",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const GetEditMeetingCateringTaxByOppoID = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url:
        AppConfigData.APIBasePath +
        "client/api/Opportunities/GetListAllTaxByMeetingAndCateringOppoID",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const copyMeetingCateringData = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Opportunities/MeetingCateringCopy",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const getEditMeetingCateringList = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url:
        AppConfigData.APIBasePath +
        "client/api/Opportunities/MeetingCateringGetByPrimaryKeyWithAllDetails",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const getMeetingRoomByHotelDropdownList = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Properties/GetMeetingRoomsList",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const getRoomTypeByHotelDropdownList = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Opportunities/GroupRoomTypesListByHotelId",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const getFoodAndBeveragesByHotelDropdownList = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Properties/GetMeetingRoomsList",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const getAvEquipmentsAndMISCDropdownListData = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Properties/GetAvEquipmentsAndMISCList",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const getFoodsAndBeveragesData = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Properties/GetFoodAndBeveragesList",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const getRoomMeetingTaxFinalData = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Properties/GetMeetingRoomTaxesList",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const getFoodMeetingTaxFinalData = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Properties/GetFoodTaxesList",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const getBeverageMeetingTaxFinalData = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Properties/GetBeverageTaxesList",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const getAVEquipmentMeetingTaxFinalData = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Properties/GetAvEquipmentTaxesList",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const getGroupMeetingTaxFinalData = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Properties/GetGuestRoomTaxesList",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const getCompSetRatesRequestSaveByGroupOppoId = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url:
        AppConfigData.APIBasePath +
        "client/api/Opportunities/GroupCompSetRatesRequestSaveByGroupOppoId",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};

export const getGroupCompSetRefreshRatesRequestSaveByGroupOppoId = async (
  ReqData: any,
  accessToken
) => {
  try {
    const response = await axios({
      method: "POST",
      url:
        AppConfigData.APIBasePath +
        "client/api/Opportunities/GroupCompSetRefreshRatesRequestSaveByGroupOppoId",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};

export const getSetupRoomData = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Properties/GetMeetingRoomSetupsList",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const MeetingCateringRoomsBookingCheckAvailablility = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url:
        AppConfigData.APIBasePath +
        "client/api/Opportunities/MeetingCateringRoomsBookingCheckAvailablility",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
//#endregion
//#region Activities

export const getAllActivitiesList = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Activities/GetList",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const EditAllActivitiesList = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Activities/GetByPrimaryKey",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const DeleteActivities = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Activities/Delete",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};

export const copyActivityData = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Activities/CopyActivity",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const MoreResultInActivity = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Activities/GetActivityDetailsByPrimaryKey",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};

export const copyRFPData = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Opportunities/RFPCopy",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};

export const copyLNRData = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Opportunities/LNRCopy",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};

export const copyGroupData = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Opportunities/GroupCopy",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};

export const saveActivities = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Activities/Save",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};

export const getEmailAssignToDropdownList = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Properties/GetEmailAssignToByProperties",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};

//#endregion

//#endregion
//#region Support API
export const getSupportList = async (accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Support/GetList",
      // data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const SaveSupportReq = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Support/Save",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};

export const getAssignToUserList = async (accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "AdminPanel/api/Clients/GetSupportAssignToUserList",
      // data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const getSupportDataById = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Support/GetByPrimaryKey",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const SupportDetailBySupportID = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Support/SupportDetailBySupportID",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const ReOpenTicket = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Support/ReOpen",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
//#endregion
//#region

export const getContactListing = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Contacts/GetList",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error: any) {
    let data: any = null;
    if (error.response.status === 401) {
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear();
      window.location.pathname = "/";
    } else {
      data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};

//#endregion
