import { useEffect, useState } from "react";
import { Button, Col, FloatingLabel, Form, Modal, Row, Spinner, Table } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import BlackCancelIcon from "../../Asset/Images/Icons/blackCancelIcon.svg";
import WhiteSaveIcon from "../../Asset/Images/Icons/whiteSaveIcon.svg";
import { updateActivityDataFetch } from "../../Redux/actions";
import {
  CommonService,
  GenerateGUID,
  ToasterError,
  ToasterSuccess,
  truncateFileName,
} from "../../Services/CommonServices";
import { getAccountDropdownData } from "../../Services/DashboardServices";
import {
  getContactListing,
  getEmailAssignToDropdownList,
  saveActivities,
} from "../../Services/LeftMenuServices";
import { getProjectTermByCategory } from "../../Services/Setup&Config/AdditionalSettingService";
import AddActivity from "../AddActivity";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie-consent";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import PageLoader from "../../Component/Loader/PageLoader";
import {
  DeletePropertyAttachment,
  PropertyAttachmentList,
  PropertyAttachmentsSave,
} from "../../Services/Setup&Config/HotelService";
import Swal from "sweetalert2";

export default function AddActivityModal({ modalShowActivity, setModalShowActivity }) {
  const SelectedPropertyID: any = useSelector((state: any) => state.dashboard.SelectedPropertyID);
  const SelectedPropertyName: any = useSelector(
    (state: any) => state.dashboard.SelectedPropertyName
  );
  const [fullModalShowActivity, setFullModalShowActivity] = useState(false);
  const globalHotelData: any = useSelector((state: any) => state.dashboard);
  const dataSelector: any = useSelector((state: any) => state.session);
  const clientID: any = dataSelector.auth_UserDetail.C_ClientID;
  let navigate = useNavigate();
  const Token = dataSelector.access_Token;
  const activity_data_reload_count = dataSelector.activity_data_reload_count;
  const userId: any = dataSelector.auth_UserDetail.U_UserID;
  const dispatch = useDispatch();
  let initialHotelToSet: any = [];

  globalHotelData.GlobalHotels !== undefined &&
    globalHotelData.GlobalHotels &&
    globalHotelData.GlobalHotels.length > 0 &&
    globalHotelData.GlobalHotels.forEach((item: any) => {
      initialHotelToSet = [
        ...initialHotelToSet,
        {
          label: item.HotelCode + " " + item.PropertyName,
          value: item.PropertyID,
        },
      ];
    });

  let initialFormInputs = {
    activitySubject: "",
    hotelID: null,
    activityType_Term: "",
    startDate: new Date(),
    duration: 0,
    strDuration: "",
    activityTime: "",
    endDate: new Date(),
    accountID: null,
    assigntoUserName: "",
    activityDetails: "",
    emailSendTo: "",
    activityStatus_Term: "Open",
    priority_Term: "Low",
    // unused
    ownerID: null,
    associationID: null,
    associationType_Term: "Accounts",
    accountName: "",
    assignTo: null,
    otherUsers: "",
    emailReminders_BeforeHrs: 0,
    isFollowUp: false,
    followUpOnActivityID: null,
    isInComing: false,
    contactID: null,
    reminderSendEmail: false,
  };

  let initialFormInputEr = {
    activityTime: "",
    activitySubject: "",
    hotelID: "",
    activityType_Term: "",
    startDate: "",
    accountID: "",
    duration: "",
  };

  const validation_msg = {
    activityTime: "This field is required.",
    activitySubject: "This field is required.",
    hotelID: "This field is required.",
    activityType_Term: "This field is required.",
    startDate: "This field is required.",
    accountID: "This field is required.",
    duration: "This field is required.",
  };

  const [hotelChangeLoading, sethotelChangeLoading] = useState(false); // Hotel Selection Loader State.

  const [submitLoading, setSubmitLoading] = useState(false);

  // const [allDropdown, setAllDropdown]: any = useState([]);
  // const [selectedAllDropDown, setSelectedAllDropDown] = useState([])

  const [activityTypes, setActivityTypes] = useState([]);
  const [selectedActivityTypes, setSelectedActivityTypes] = useState([]);

  const [durations, setDurations] = useState([]);
  const [selectedDurations, setSelectedDurations] = useState([]);

  const [timeDropdown, setTimeDropdown]: any = useState([]);
  const [selectedOptionTime, setSelectedOptionTime] = useState([]);

  const [assingnToEmailResponce, setAssingnToEmailResponce]: any = useState([]);
  const [selectedAssignTo, setSelectedAssignTo]: any = useState([]);

  const [emails, setEmails]: any = useState([]);
  const [selectedEmails, setSelectedEmails]: any = useState([]);

  const [contactsList, setContactsList]: any = useState([]);
  const [selectedContactList, setSelectedContactList] = useState([]);

  const [accountListData, setAccountListData]: any = useState([]);
  const [selectedAccountList, setSelectedAccountList] = useState([]);

  const [hotelOptions, setHotelOptions] = useState(initialHotelToSet);
  const [selectedHotelOptions, setSelectedHotelOptions]: any = useState([]);

  const [formInputs, setFormInputs] = useState<any>(initialFormInputs);
  const [formInputsEr, setFormInputsEr] = useState<any>(initialFormInputEr);

  const [StatusData, setStatusData]: any = useState([]);
  const [PriorityData, setPriorityData]: any = useState([]);

  const [files, setFiles]: any = useState([]);
  const [selectedFiles, setSelectedFiles]: any = useState(0);

  const handleClosePopup = () => {
    setModalShowActivity(false);
    setFormInputsEr(initialFormInputEr);
    setFormInputs(initialFormInputs);
    setSelectedHotelOptions([]);
    setSelectedAccountList([]);
    setAccountListData([]);
    setSelectedContactList([]);
    setContactsList([]);
    setSelectedEmails([]);
    setEmails([]);
    setSelectedAssignTo([]);
    setAssingnToEmailResponce([]);
    setSelectedOptionTime([]);
    setTimeDropdown([]);
    setSelectedDurations([]);
    setDurations([]);
    setSelectedActivityTypes([]);
    setActivityTypes([]);
  };

  const getAssignedToOREmailData = async (selectedHotelId) => {
    if (selectedHotelId) {
      let input = {
        clientID: clientID,
        propertyIDs: selectedHotelId,
      };
      // if(userTypeID === "78a001cf-27cc-408f-b68f-8bb216cbfef3") {
      //   input.propertyIDs = null
      // }
      //this Function to get assignTo Dropdown From same api
      const res: any = await getEmailAssignToDropdownList(input, Token);
      if (res.data.success) {
        if (res.data !== undefined && res.data !== null && res.data !== "") {
          if (res.data.data !== undefined && res.data.data !== null && res.data.data !== "") {
            let AssingnToEmailResponce: any = [];
            let emailsToRes: any = [
              {
                value: null,
                label: "Select Email To",
              },
            ];
            res.data.data.dataList1.forEach((element_itm: any) => {
              AssingnToEmailResponce = [
                ...AssingnToEmailResponce,
                {
                  label: element_itm.UserName,
                  value: element_itm.UserID,
                },
              ];
              emailsToRes = [
                ...emailsToRes,
                {
                  label: element_itm.Email,
                  value: element_itm.Email,
                },
              ];
            });
            setAssingnToEmailResponce(AssingnToEmailResponce);
            setEmails(emailsToRes);

            // Auto fill Email and AssignTO's :

            let userIDRes = res.data.data.dataList1.filter((item) => item.UserID === userId);
            setFormInputs({
              ...formInputs,
              hotelID: selectedHotelId,
              assignTo: userIDRes.length && userIDRes[0].UserID,
              assigntoUserName: userIDRes.length && userIDRes[0].UserName,
              // emailSendTo: userIDRes.length && userIDRes[0].Email,
            });

            setSelectedAssignTo({
              label: userIDRes.length && userIDRes[0].UserName,
              value: userIDRes.length && userIDRes[0].UserID,
            });
            // setSelectedEmails({
            //   label: userIDRes.length && userIDRes[0].Email,
            //   value: userIDRes.length && userIDRes[0].Email,
            // });
          }
        }
      }
    }
  };

  const getAccountsDropdownsData = async (selectedHotelId) => {
    let input: any = {
      propertiesIDs: null,
    };
    if (selectedHotelId !== undefined && selectedHotelId !== null && selectedHotelId !== "") {
      input.propertiesIDs = selectedHotelId;
      let res: any = await getAccountDropdownData(input, Token);
      if (res.data.success) {
        if (res.data !== undefined && res.data !== null && res.data !== "") {
          if (res.data.data !== undefined && res.data.data !== null && res.data.data !== "") {
            let accountListDataToAppend: any = [];
            res.data.data.dataList1.forEach((item: any) => {
              accountListDataToAppend = [
                ...accountListDataToAppend,
                {
                  label: item.AccountName,
                  value: item.AccountID,
                },
              ];
            });
            setAccountListData(accountListDataToAppend);
          }
        }
      }
    }
    sethotelChangeLoading(false);
  };

  const getAllDropdownData = async () => {
    let input: any = {
      category: "Activity Type,Duration",
      clientID: clientID,
    };
    let res: any = await getProjectTermByCategory(input, Token);
    if (res.data.success) {
      if (res.data !== undefined) {
        if (res.data.data !== undefined && res.data.data !== null) {
          let activityTypesAppend: any = [];
          let durationTypeAppend: any = [];
          res.data.data.dataList1.forEach((item_time: any) => {
            if (item_time.Category === "Duration") {
              durationTypeAppend = [
                ...durationTypeAppend,
                {
                  label: item_time.Term,
                  value: item_time.Term,
                },
              ];
            }
            if (item_time.Category === "Activity Type") {
              activityTypesAppend = [
                ...activityTypesAppend,
                {
                  label: item_time.Term,
                  value: item_time.TermID,
                },
              ];
            }
          });

          setActivityTypes(activityTypesAppend);
          setDurations(durationTypeAppend);
          // setAllDropdown(dropDownToAppend);
        }
      }
    }
  };

  const getTimeDropdownData = async () => {
    let input: any = {
      category: "Time",
      clientID: clientID,
    };
    let res: any = await getProjectTermByCategory(input, Token);
    if (res.data.success) {
      if (res.data !== undefined) {
        if (res.data.data !== undefined && res.data.data !== null) {
          let timeDropDownToAppend: any = [];
          res.data.data.dataList1.forEach((item_time: any) => {
            timeDropDownToAppend = [
              ...timeDropDownToAppend,
              {
                label: item_time.Term,
                value: item_time.Term,
              },
            ];
          });

          setTimeDropdown(timeDropDownToAppend);
        }
      }
    }
  };

  const fetchContactList = async (account_id) => {
    let input = {
      clientID: clientID,
      propertyIDs: formInputs.hotelID,
      accountID: account_id,
      searchText: "",
    };
    let res: any = await getContactListing(input, Token);
    if (res.data.success) {
      if (res.data !== undefined && res.data !== null && res.data !== "") {
        if (res.data.data !== undefined && res.data.data !== null && res.data.data !== "") {
          let contactArrayToAppend: any = [];
          res.data.data.dataList1.forEach((item: any) => {
            contactArrayToAppend = [
              ...contactArrayToAppend,
              {
                label: item.ContactName,
                value: item.ContactID,
              },
            ];
          });
          setContactsList(contactArrayToAppend);
        }
      }
    }
  };

  const validateForm = () => {
    let isValid = true;
    let error_json = {};

    Object.keys(formInputsEr).forEach((form_key) => {
      error_json = {
        ...error_json,
        [form_key]: "",
      };
    });

    Object.keys(formInputsEr).forEach((form_key) => {
      if (form_key === "activityTime" && (formInputs[form_key] === "" || !formInputs[form_key])) {
        if (
          (formInputs["emailSendTo"] !== null &&
            formInputs["emailSendTo"] !== "" &&
            formInputs["emailSendTo"] !== undefined) ||
          formInputs["activityType_Term"] === "Blitz" ||
          formInputs["activityType_Term"] === "Client Visit" ||
          formInputs["activityType_Term"] === "Meeting" ||
          formInputs["activityType_Term"] === "Networking" ||
          formInputs["activityType_Term"] === "Site Visit"
        ) {
          error_json = {
            ...error_json,
            [form_key]: validation_msg[form_key],
          };
          isValid = false;
        }
      } else if (
        form_key === "duration" &&
        (formInputs[form_key] === "" || !formInputs[form_key])
      ) {
        if (
          formInputs["activityType_Term"] === "Blitz" ||
          formInputs["activityType_Term"] === "Client Visit" ||
          formInputs["activityType_Term"] === "Meeting" ||
          formInputs["activityType_Term"] === "Networking" ||
          formInputs["activityType_Term"] === "Site Visit"
        ) {
          error_json = {
            ...error_json,
            [form_key]: validation_msg[form_key],
          };
          isValid = false;
        }
      } else if (formInputs[form_key] === "" || !formInputs[form_key]) {
        error_json = {
          ...error_json,
          [form_key]: validation_msg[form_key],
        };
        isValid = false;
      }
    });

    setFormInputsEr(error_json);
    return isValid;
  };

  const handleFormSave = async (e) => {
    // setFormInputs({
    //   ...formInputs,
    //   hotelId: SelectedPropertyID,
    // });
    e.preventDefault();
    if (validateForm()) {
      setSubmitLoading(true);

      let res: any = await saveActivities(formInputs, Token);

      if (res.data !== undefined) {
        let Message = "Activity Inserted SuccessFully";
        if (res.data.statusCode === 200) {
          setSubmitLoading(false);
          // Save Documents :
          if (files.length > 0) {
            await handleUploadFiles(res.data.data.activityID);
            setSelectedFiles(0);
          }
          ToasterSuccess(Message);
          dispatch(updateActivityDataFetch(activity_data_reload_count + 1));
          handleClosePopup();
          navigate("/activity/details", { state: { activityID: res.data.data.activityID } });
        }
      } else {
        setSubmitLoading(false);
        ToasterError(res.message);
      }
      setSubmitLoading(false);
    }
  };

  async function SaveActivityFollowUp(e) {
    e.preventDefault();
    if (validateForm()) {
      setSubmitLoading(true);
      let res: any = await saveActivities(formInputs, Token);
      let Message = "Activity Inserted SuccessFully";
      if (res.data !== undefined) {
        if (res.data.statusCode === 200) {
          // Save Documents :
          if (files.length > 0) {
            await handleUploadFiles(res.data.data.activityID);
            setSelectedFiles(0);
          }
          setFormInputs({
            ...formInputs,
            activitySubject: "",
            activityType_Term: "",
            startDate: "",
            endDate: "",
            // activityTime: "",
            // duration: "",
            // strDuration: "",
            assigntoUserName: "",
            contactID: "",
            emailSendTo: "",
          });
          setSelectedActivityTypes([]);
          ToasterSuccess(Message);
        } else {
          ToasterError("Some thing went wrong.");
        }
      } else {
        setSubmitLoading(false);
        ToasterError(res.message);
      }
      setSubmitLoading(false);
    }
  }

  useEffect(() => {
    sethotelChangeLoading(true);
    if (!SelectedPropertyID.includes(",")) {
      setSelectedHotelOptions({ label: SelectedPropertyName, value: SelectedPropertyID });
      getAssignedToOREmailData(SelectedPropertyID);
      getAccountsDropdownsData(SelectedPropertyID);
      // getAccountsDDTData(SelectedPropertyID); // Before commented
    }

    getAllDataOfProjectTerm();
    // getStatusAndPriorityByProjectTerm();
    // getAllDropdownData();
    // getTimeDropdownData();
  }, []);

  async function getAllDataOfProjectTerm() {
    let input: any = {
      clientID: clientID, // "00000000-0000-0000-0000-000000000000",
      category: "Status, Priority, Time, Activity Type,Duration",
    };
    let res: any = await getProjectTermByCategory(input, Token);
    if (res.data.success) {
      if (res.data !== undefined && res.data !== null) {
        if (res.data.data !== undefined && res.data.data !== null) {
          let tempStatus =
            res.data.data.dataList1.length > 0 &&
            res.data.data.dataList1.filter((item) => item.Category === "Status");
          setStatusData(tempStatus);
          let tempPriority =
            res.data.data.dataList1.length > 0 &&
            res.data.data.dataList1.filter((item) => item.Category === "Priority");
          setPriorityData(tempPriority);

          let timeDropDownToAppend: any = [];
          res.data.data.dataList1.forEach((item_time: any) => {
            if (item_time.Category === "Time") {
              timeDropDownToAppend = [
                ...timeDropDownToAppend,
                {
                  label: item_time.Term,
                  value: item_time.Term,
                },
              ];
            }
          });
          setTimeDropdown(timeDropDownToAppend);

          let activityTypesAppend: any = [];
          let durationTypeAppend: any = [];
          res.data.data.dataList1.forEach((item: any) => {
            if (item.Category === "Duration") {
              durationTypeAppend = [
                ...durationTypeAppend,
                {
                  label: item.Term,
                  value: item.Term,
                },
              ];
            }
            if (item.Category === "Activity Type") {
              activityTypesAppend = [
                ...activityTypesAppend,
                {
                  label: item.Term,
                  value: item.TermID,
                },
              ];
            }
          });

          setActivityTypes(activityTypesAppend);
          setDurations(durationTypeAppend);
        }
      }
    }
    sethotelChangeLoading(false);
  }

  // File Upload Section : ---------------------- Start

  const fetchActivityFilesList = async (ActivityId: string) => {
    let data = {
      clientID: clientID,
      associationIDs: ActivityId,
      associationType_Term: "Activity/Files",
    };

    let res: any = await PropertyAttachmentList(data, Token);
    if (res.status === 200) {
      if (res.data.success) {
        if (res.data !== undefined && res.data !== null) {
          if (res.data.data !== undefined && res.data.data !== null) {
            let fileUrls: any = [];
            res.data?.data?.dataList1?.forEach((file_el: any, idx: any) => {
              // let fileURL = "";
              // fileURL = URL.createObjectURL(file_el);
              fileUrls.push({
                id: file_el.AttachmentID, // GenerateGUID(),
                isSaved: true,
                url: file_el.DocumentPath,
                name: file_el.DocumentName,
                fileObj: "",
              });
            });
            setFiles([...files, ...fileUrls]);
          }
        }
      }
    }
  };

  const handleUploadFiles = async (activityId) => {
    var formData = new FormData();
    formData.append("OwnerType", "");
    formData.append("AssociationID", activityId);
    formData.append("AssociationType_Term", "Activity/Files");
    formData.append("AboutDocument", "");
    formData.append("ExpiryDate", "");
    formData.append("RefAttachmentID", "");
    formData.append("DocumentFrom", "Activity/Files");
    files?.forEach((form_fl) => {
      formData.append("FormFiles", form_fl.fileObj);
    });

    let res: any = await PropertyAttachmentsSave(formData, Token);
    if (res.status === 200) {
      if (res.data.statusCode === 200) {
        setFiles([]);
      } else {
        ToasterError("Something Went Wrong");
      }
    } else {
    }
  };

  const handleAttachmentFileQuickAdd = (file_res) => {
    let fileUrls: any = [];

    file_res.forEach((file_el: any) => {
      let fileURL = "";
      fileURL = URL.createObjectURL(file_el);
      fileUrls.push({
        id: GenerateGUID(),
        isSaved: false,
        url: fileURL,
        name: file_el.name,
        fileObj: file_el,
      });
    });

    setSelectedFiles(fileUrls.length);
    let tmpFiles = [...files, ...fileUrls];

    // Check All files sizes :
    let totalSize = 0;
    tmpFiles.forEach((curr_Obj: any) => {
      if (curr_Obj?.fileObj !== "") {
        totalSize = totalSize + parseFloat((curr_Obj?.fileObj?.size / 1000000).toFixed(2));
      } else {
        totalSize = totalSize + 0;
      }
    });
    // console.log("totalSize", totalSize);
    if (totalSize >= 25) {
      setSelectedFiles(0);
      ToasterError("Total files size should be less than 25MB.");
      return;
    }
    setFiles([...files, ...fileUrls]);
  };

  const handleDelete_UnSavedFiles = async (file_Id: any) => {
    Swal.fire({
      title: "Are you sure want to delete ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "No",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let tmpFiles = [...files];
        let newLocalFiles = tmpFiles.filter((itm) => itm.id !== file_Id);
        setFiles(newLocalFiles);
      }
    });
  };

  const handleDelete_SavedFiles = async (att_id: any) => {
    Swal.fire({
      title: "Are you sure want to delete ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "No",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let input = {
          keyID: att_id,
        };
        let res: any = await DeletePropertyAttachment(input, Token);
        if (res.data.success) {
          let tmpFiles = [...files];
          let newLocalFiles = tmpFiles.filter((itm) => itm.id !== att_id);
          setFiles(newLocalFiles);
        }
      }
    });
  };

  // File Upload Section : ---------------------- End

  return (
    <>
      <Modal
        show={modalShowActivity}
        onHide={() => {
          handleClosePopup();
        }}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="modal-50w"
        className="quickAdd-popup"
        scrollable={true}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Activity Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <Row>
              <Col xs={6} className="mb-3">
                <FloatingLabel controlId="floatingInput" label="Objective">
                  <Form.Control
                    type="text"
                    placeholder="Objective"
                    value={formInputs.activitySubject}
                    onChange={(e) => {
                      setFormInputs({
                        ...formInputs,
                        activitySubject: e.target.value ? e.target.value : "",
                      });
                    }}
                    className={`form-control ${formInputsEr.activitySubject && "is-invalid"}`}
                  />
                  {formInputsEr.activitySubject && (
                    <Form.Control.Feedback type="invalid">
                      {formInputsEr.activitySubject}
                    </Form.Control.Feedback>
                  )}
                </FloatingLabel>
              </Col>
              <Col xs={6} className="mb-3">
                <div className={`${formInputsEr.activityType_Term && "red-border-masking"}`}>
                  <FloatingLabel controlId="floatingSelectGrid" label="">
                    <Select
                      placeholder="Select Type"
                      options={activityTypes}
                      value={selectedActivityTypes}
                      defaultValue={selectedActivityTypes}
                      onChange={(itm: any) => {
                        setSelectedActivityTypes(itm);
                        setFormInputs({
                          ...formInputs,
                          activityType_Term: itm.label,
                        });
                      }}
                    />
                  </FloatingLabel>
                </div>
                <small style={{ color: "#dc3545" }}>
                  {" "}
                  {formInputsEr.activityType_Term && formInputsEr.activityType_Term}{" "}
                </small>
              </Col>
            </Row>
            <Row>
              <Col xs={6} className="mb-3">
                {/* <FloatingLabel controlId="floatingInput" label="Start Date">
                  <Form.Control
                    type="date"
                    placeholder="mm-dd-yyyy"
                    max={formInputs.endDate}
                    value={formInputs.startDate}
                    onChange={(e) => {
                      setFormInputs({
                        ...formInputs,
                        startDate: e.target.value ? e.target.value : "",
                        endDate: e.target.value ? e.target.value : "",
                      });
                    }}
                    className={`form-control ${formInputsEr.startDate && "is-invalid"}`}
                  />
                  {formInputsEr.startDate && (
                    <Form.Control.Feedback type="invalid">
                      {formInputsEr.startDate}
                    </Form.Control.Feedback>
                  )}
                </FloatingLabel> */}
                <div className="DatePicker-col">
                  <FloatingLabel controlId="floatingInput" label="Start Date">
                    <DatePicker
                      selected={formInputs.startDate && formInputs.startDate}
                      className={`form-control ${formInputsEr.startDate && "is-invalid"}`}
                      onChange={(e) => {
                        setFormInputs({
                          ...formInputs,
                          ["startDate"]: e,
                          ["endDate"]: e,
                        });
                      }}
                      // minDate={new Date("1753/01/01")}
                      minDate={new Date("2010/01/01")}
                      maxDate={new Date("9999/12/31")}
                      dateFormat={Cookies.get("SystemSettingDate")}
                      placeholder={Cookies.get("SystemSettingDate")}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                    {formInputsEr.startDate && (
                      <Form.Control.Feedback type="invalid">
                        {formInputsEr.startDate}
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>
                </div>
              </Col>
              <Col xs={6} className="mb-3">
                {/* <FloatingLabel controlId="floatingInput" label="Due Date">
                  <Form.Control
                    type="date"
                    placeholder="mm-dd-yyyy"
                    min={formInputs.startDate}
                    value={formInputs.endDate}
                    onChange={(e) => {
                      setFormInputs({
                        ...formInputs,
                        endDate: e.target.value ? e.target.value : "",
                      });
                    }}
                  />
                </FloatingLabel> */}
                <div className="DatePicker-col">
                  <FloatingLabel controlId="floatingInput" label="Due Date">
                    <DatePicker
                      className="form-control"
                      selected={formInputs.endDate && formInputs.endDate}
                      minDate={formInputs.startDate}
                      // minDate={new Date("1753/01/01")}
                      maxDate={new Date("9999/12/31")}
                      onChange={(e) => {
                        setFormInputs({
                          ...formInputs,
                          ["endDate"]: e,
                        });
                      }}
                      dateFormat={Cookies.get("SystemSettingDate")}
                      placeholder={Cookies.get("SystemSettingDate")}
                      // dateFormat="dd-MM-yyyy"
                      // placeholderText="dd-mm-yyyy"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                  </FloatingLabel>
                </div>
              </Col>
            </Row>
            {/* Time & Duration */}
            <Row>
              <Col xs={6} className="mb-3">
                <div className={`${formInputsEr.activityTime && "red-border-masking"}`}>
                  <FloatingLabel controlId="floatingInput" label="">
                    <Select
                      placeholder="Select Time"
                      options={timeDropdown}
                      value={selectedOptionTime}
                      defaultValue={selectedOptionTime}
                      onChange={(itm: any) => {
                        setSelectedOptionTime(itm);
                        setFormInputs({
                          ...formInputs,
                          activityTime: itm.value,
                        });
                      }}
                    />
                  </FloatingLabel>
                </div>
                <small style={{ color: "#dc3545" }}>
                  {" "}
                  {formInputsEr.activityTime && formInputsEr.activityTime}{" "}
                </small>
              </Col>
              <Col xs={6} className="mb-3">
                <div className={`${formInputsEr.duration && "red-border-masking"}`}>
                  <FloatingLabel controlId="floatingInput" label="">
                    <Select
                      placeholder="Select Duration"
                      options={durations}
                      defaultValue={selectedDurations}
                      value={selectedDurations}
                      onChange={(itm: any) => {
                        setSelectedDurations(itm);
                        let selectedDuration: any = itm.value;
                        let hm = selectedDuration;
                        let a = hm.split(":");
                        let minutes = parseInt(a[0]) * 60 + parseInt(a[1]);
                        setFormInputs({
                          ...formInputs,
                          duration: minutes,
                          strDuration: selectedDuration,
                        });
                      }}
                    />
                  </FloatingLabel>
                </div>
                <small style={{ color: "#dc3545" }}>
                  {" "}
                  {formInputsEr.duration && formInputsEr.duration}{" "}
                </small>
              </Col>
            </Row>

            <Row>
              <Col xs={6} className="mb-3">
                <Form.Group>
                  <div className={`${formInputsEr.hotelID && "red-border-masking"}`}>
                    <FloatingLabel controlId="floatingSelectGrid" label="">
                      <Select
                        placeholder="Select Hotel"
                        className={`multiselect ${formInputsEr.hotelID && "is-invalid"}`}
                        options={hotelOptions}
                        onChange={(item: any) => {
                          sethotelChangeLoading(true);
                          setSelectedHotelOptions(item);
                          setFormInputs({
                            ...formInputs,
                            hotelID: item.value,
                            assigntoUserName: "",
                            emailSendTo: "",
                            accountID: "",
                            contactID: "",
                          });

                          setSelectedEmails([]);
                          setSelectedAssignTo([]);
                          setSelectedAccountList([]);
                          setSelectedContactList([]);

                          if (Object.keys(item).length) {
                            getAssignedToOREmailData(item.value);
                            getAccountsDropdownsData(item.value);
                          } else {
                            setAssingnToEmailResponce([]);
                            setAccountListData([]);
                          }

                          // getAccountsDDTData(item.value);
                        }}
                        defaultValue={hotelOptions.filter(
                          (obj) =>
                            selectedHotelOptions.value &&
                            selectedHotelOptions.value.includes(obj.value)
                        )}
                        value={hotelOptions.filter(
                          (obj) =>
                            selectedHotelOptions.value &&
                            selectedHotelOptions.value.includes(obj.value)
                        )}
                        // defaultValue={selectedHotelOptions}
                        // value={selectedHotelOptions}
                        // isMulti={true}
                      />
                    </FloatingLabel>
                  </div>
                </Form.Group>
                <small style={{ color: "#dc3545" }}>
                  {" "}
                  {formInputsEr.hotelID && formInputsEr.hotelID}{" "}
                </small>
              </Col>
              <Col xs={6} className="mb-3">
                <div className={`${formInputsEr.accountID.length && "red-border-masking"}`}>
                  <FloatingLabel controlId="floatingSelectGrid" label="">
                    <Select
                      placeholder="Select Account"
                      defaultValue={selectedAccountList}
                      value={selectedAccountList}
                      // className={`form-control ${saveActivity.errors.activityTime && "is-invalid"}`}
                      options={accountListData}
                      // isMulti
                      onChange={(itm: any) => {
                        setSelectedAccountList(itm);
                        setFormInputs({
                          ...formInputs,
                          accountID: itm.value,
                          associationID: itm.value,
                          contactID: "",
                        });
                        setSelectedContactList([]);
                        fetchContactList(itm.value);
                      }}
                    />

                    {formInputsEr.accountID && (
                      <Form.Control.Feedback type="invalid">
                        {formInputsEr.accountID}
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>
                </div>
                <small style={{ color: "#dc3545" }}>
                  {" "}
                  {formInputsEr.accountID && formInputsEr.accountID}{" "}
                </small>
              </Col>
            </Row>

            <Row>
              <Col xs={6} className="mb-3">
                <FloatingLabel controlId="floatingSelectGrid" label="">
                  <Select
                    placeholder="Select Assigned To"
                    defaultValue={selectedAssignTo}
                    value={selectedAssignTo}
                    options={assingnToEmailResponce}
                    onChange={(itm: any) => {
                      setSelectedAssignTo(itm);
                      setFormInputs({
                        ...formInputs,
                        assignTo: itm.value,
                        assigntoUserName: itm.label,
                      });
                    }}
                  />
                </FloatingLabel>
              </Col>
              <Col xs={6} className="mb-3">
                <FloatingLabel controlId="floatingSelectGrid" label="">
                  <Select
                    placeholder="Select Contact"
                    // className={`form-control ${saveActivity.errors.activityTime && "is-invalid"}`}
                    defaultValue={selectedContactList}
                    value={selectedContactList}
                    options={contactsList}
                    onChange={(itm: any) => {
                      setSelectedContactList(itm);
                      setFormInputs({
                        ...formInputs,
                        contactID: itm.value,
                      });
                    }}
                  />
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col xs={6} className="mb-3">
                <FloatingLabel controlId="floatingSelectGrid" label="">
                  <Select
                    placeholder="Select Email To"
                    // className={`form-control ${saveActivity.errors.activityTime && "is-invalid"}`}
                    options={emails}
                    defaultValue={selectedEmails}
                    value={selectedEmails}
                    // isMulti
                    onChange={(itm: any) => {
                      setSelectedEmails(itm);
                      setFormInputs({
                        ...formInputs,
                        emailSendTo: itm.value,
                      });
                    }}
                  />
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col xs={6} className="mb-3">
                <label className="for-radio-header" htmlFor="Status">
                  Status
                </label>
                {["radio"].map((type: any) => (
                  <div key={`inline-${type}`} className="option-field">
                    {StatusData.length > 0 &&
                      StatusData.map((item) => (
                        <Form.Check
                          inline
                          label={item.TermCode}
                          name={item.TermCode}
                          value={item.TermCode}
                          type={type}
                          id={item.TermCode}
                          checked={formInputs.activityStatus_Term === item.TermCode ? true : false}
                          onChange={(e) =>
                            setFormInputs({
                              ...formInputs,
                              activityStatus_Term: item.TermCode,
                            })
                          }
                        />
                      ))}

                    {/* <Form.Check
                    inline
                    label="Open"
                    name="group1"
                    value="Open"
                    type={type}
                    id="Open"
                    // defaultChecked={saveActivity.activityStatus_Term === "Open" ? true : false}
                    checked={formInputs.activityStatus_Term === "Open" ? true : false}
                    onChange={(e) =>
                      setFormInputs({
                        ...formInputs,
                        activityStatus_Term: e.target.value,
                      })
                    }
                  /> */}

                    {/* <Form.Check
                    inline
                    label="Complete"
                    name="group1"
                    value="Complete"
                    // defaultChecked={saveActivity.activityStatus_Term === "Complete" ? true : false}
                    checked={formInputs.activityStatus_Term === "Complete" ? true : false}
                    onChange={(e) =>
                      setFormInputs({
                        ...formInputs,
                        activityStatus_Term: e.target.value,
                      })
                    }
                    type={type}
                    id="Not Viable"
                  /> */}
                  </div>
                ))}
              </Col>
              <Col xs={6} className="mb-3">
                <label className="for-radio-header" htmlFor="Priority">
                  Priority
                </label>
                {["radio"].map((type: any) => (
                  <div key={`inline-${type}`} className="option-field">
                    {PriorityData.length > 0 &&
                      PriorityData.map((item) => (
                        <Form.Check
                          inline
                          label={item.TermCode}
                          name={item.TermCode}
                          value={item.TermCode}
                          type={type}
                          id={item.TermCode}
                          checked={formInputs.priority_Term === item.TermCode ? true : false}
                          onChange={(e) =>
                            setFormInputs({
                              ...formInputs,
                              priority_Term: item.TermCode,
                            })
                          }
                        />
                      ))}

                    {/* <Form.Check
                      inline
                      label="Low"
                      name="group2"
                      value="Low"
                      type={type}
                      checked={formInputs.priority_Term === "Low" ? true : false}
                      onChange={(e) =>
                        setFormInputs({
                          ...formInputs,
                          priority_Term: e.target.value,
                        })
                      }
                      id="Low"
                    /> */}
                    {/* <Form.Check
                    inline
                    label="Normal"
                    name="group2"
                    value="Normal"
                    type={type}
                    id="Normal"
                    // defaultChecked={saveActivity.priority_Term === "Normal" ? true : false}
                    checked={formInputs.priority_Term === "Normal" ? true : false}
                    onChange={(e) => {
                      setFormInputs({
                        ...formInputs,
                        priority_Term: e.target.value,
                      })
                    }
                    }
                  />
                  <Form.Check
                    inline
                    label="Urgent"
                    name="group2"
                    value="Urgent"
                    type={type}
                    id="Urgent"
                    // defaultChecked={saveActivity.priority_Term === "Urgent" ? true : false}
                    checked={formInputs.priority_Term === "Urgent" ? true : false}
                    onChange={(e) => {
                      setFormInputs({
                        ...formInputs,
                        priority_Term: e.target.value,
                      })
                    }}
                  /> */}
                  </div>
                ))}
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="mb-3">
                {/* <FloatingLabel controlId="floatingInput" label="Results">
                  <Form.Control
                    as="textarea"
                    style={{ height: "100px" }}
                    placeholder="Results"
                    value={formInputs.activityDetails}
                    onChange={(e) => {
                      setFormInputs({
                        ...formInputs,
                        activityDetails: e.target.value ? e.target.value : "",
                      });
                    }}
                  />
                </FloatingLabel> */}
                <CKEditor
                  editor={Editor}
                  data={formInputs.activityDetails}
                  config={{
                    extraPlugins: [CommonService.uploadPlugin],
                  }}
                  onBlur={(event, editor) => {
                    const data = editor.getData();
                    setFormInputs({
                      ...formInputs,
                      activityDetails: data,
                    });
                  }}
                />
              </Col>
            </Row>

            {/* File Upload Section */}
            <Row>
              <Col xs={12} className="mb-3">
                <div className="transparent-inputs">
                  <div>
                    <Row>
                      {/* File Upload */}
                      <Col
                        xs={12}
                        md={12}
                        className="d-flex flex-column align-items-center p-3 m-2"
                        style={{ border: "1px solid #ccced1", width: "-webkit-fill-available" }}
                      >
                        <h6 className="text-center text-lg mb-3 mt-3" htmlFor="Image">
                          {/* {files.length ? `Selected Files: (${files.length})` : "Select Files"} */}
                          {selectedFiles !== 0
                            ? `Selected Files: (${selectedFiles})`
                            : "Select Files"}
                        </h6>

                        <div className="file-upload-container">
                          {/* Upload Icon */}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon icon-tabler icon-tabler-upload"
                            width="30"
                            height="30"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="#000000"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" />
                            <path d="M7 9l5 -5l5 5" />
                            <path d="M12 4v12" />
                          </svg>

                          {/* Label to Trigger File Input */}
                          <label
                            htmlFor="dropzone-files-Quick-Add"
                            className="custom-file-label mt-2 ms-2 cursor-pointer"
                          >
                            Choose Files
                          </label>

                          {/* Hidden File Input */}
                          <input
                            id="dropzone-files-Quick-Add"
                            type="file"
                            className="upload-file-field d-none"
                            multiple={true}
                            onChange={(e) =>
                              handleAttachmentFileQuickAdd(Array.from(e.target.files))
                            }
                          />
                        </div>
                      </Col>

                      {/* File List Display */}
                      <Col xs={12} md={12} className="mt-3">
                        <Table responsive striped bordered hover>
                          <thead>
                            <tr>
                              <th>File Name</th>
                              <th>
                                <div className="d-flex justify-content-end me-5">Action</div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {files.length > 0 ? (
                              files.map((fileEle, index) => (
                                <tr key={index}>
                                  <td>
                                    <span>{truncateFileName(fileEle.name, 60)}</span>
                                  </td>
                                  <td>
                                    <div className="d-flex justify-content-end me-5">
                                      <button
                                        className="cursor-pointer text-primary me-3"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          window.open(fileEle.url, "_blank");
                                        }}
                                      >
                                        <i className="fa fa-eye"></i>
                                      </button>
                                      <button
                                        className="cursor-pointer text-danger"
                                        onClick={(e) => {
                                          e.stopPropagation();

                                          if (fileEle?.isSaved) {
                                            handleDelete_SavedFiles(fileEle.id);
                                          } else {
                                            handleDelete_UnSavedFiles(fileEle.id);
                                          }
                                        }}
                                      >
                                        <b>✕</b>
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr className="text-center">
                                <td colSpan="2" className="text-muted">
                                  No files selected.
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosePopup}>
            <span className="me-2">
              <img src={BlackCancelIcon} alt="" />
            </span>
            Close
          </Button>
          {!submitLoading ? (
            <Button
              variant="primary"
              type="button"
              onClick={(e) => {
                handleFormSave(e);
              }}
            >
              <span className="me-2">
                <img src={WhiteSaveIcon} alt="" />
              </span>
              Save
            </Button>
          ) : (
            <Button className="btn btn-primary justify-content-center" type="button">
              <Spinner size="sm" animation="border" variant="light" />
            </Button>
          )}

          {!submitLoading ? (
            <Button variant="primary" onClick={SaveActivityFollowUp}>
              <span className="me-2">
                <img src={WhiteSaveIcon} alt="" />
              </span>
              Save and Enter Follow-Up Objective
            </Button>
          ) : (
            <Button className="btn btn-primary justify-content-center" type="button">
              <Spinner size="sm" animation="border" variant="light" />
            </Button>
          )}

          <Button
            onClick={() => {
              setFullModalShowActivity(true);
            }}
            type="button"
            variant="primary"
          >
            <span className="me-2">
              <i className="fas fa-redo" aria-hidden="true"></i>
            </span>
            Go to full form
          </Button>
        </Modal.Footer>
      </Modal>
      {hotelChangeLoading ? (
        <div className="calender-loader-main">
          <PageLoader />
        </div>
      ) : (
        ""
      )}

      {fullModalShowActivity && (
        <AddActivity
          Activity={"MainActivity"}
          show={fullModalShowActivity}
          getEditActivitytdetails={() => {
            setModalShowActivity(false);
          }}
          EditActivityId={"00000000-0000-0000-0000-000000000000"}
          onHide={() => {
            setFullModalShowActivity(false);
          }}
        ></AddActivity>
      )}
    </>
  );
}
